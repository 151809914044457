import { Divider, RiArrowRightLine, Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import {
  Capsule,
  CapsuleContent,
  CapsuleIcon,
  CapsuleLabel,
  getNCChangeIcon,
  getNCChangeIconVariant,
  NCCardSectionHeader,
  NCFactCapsule,
  printLatestDate,
  useNCCardContext,
} from '@/components';
import { useScreenSize } from '@/hooks/useScreenSize';
import { useAssessmentStatus } from '@/pages/shared/hooks/useAssessmentStatus';
import { usePlotId } from '@/pages/shared/hooks/usePlotId';
import { usePlotReportForPlot } from '@/pages/shared/hooks/usePlotReportForPlot';
import { printShortMonth } from '@/utils/formatting/date';

export const WaterHistoricalStatsTile = () => {
  const isLargeScreen = useScreenSize() === 'large';
  return (
    <Stack className='gap-8 p-6 lg:justify-center lg:gap-4 lg:p-8 lg:pr-2' data-testid='water-historical-stats-tile'>
      <WaterHoldingCapacity />

      {isLargeScreen && <Divider className='border-dashed' />}

      <SoilMoisture />

      {isLargeScreen && <Divider className='border-dashed' />}

      <Trends />
    </Stack>
  );
};

const WaterHoldingCapacity = () => {
  const { t } = useTranslation();

  const latestAnalysisTranslation = t('shared.ncaDetail.details.labels.latestAnalysis');

  const plotId = usePlotId();
  const { analysisType } = useNCCardContext();

  const { getFact } = usePlotReportForPlot({ plotId });
  const waterHoldingCapacityLatestAnalysis = getFact(
    analysisType === 'total'
      ? R1FactType.r1_water_holding_capacity_latest_analysis_total
      : R1FactType.r1_water_holding_capacity_latest_analysis_per_ha,
  );
  const waterHoldingCapacitySincePreviousAnalysis = getFact(
    analysisType === 'total' ? R1FactType.r1_whc_previous_1y_uplift_total : R1FactType.r1_whc_previous_1y_uplift_per_ha,
  );

  const { assessmentStatus, isAssessmentPending } = useAssessmentStatus();

  const lastMeasuredDate = printLatestDate([waterHoldingCapacityLatestAnalysis?.measured_at]);

  const whcSincePreviousAnalysisInitialMeasuredAt = printLatestDate([
    waterHoldingCapacitySincePreviousAnalysis?.measured_at,
  ]);
  const whcSincePreviousAnalysisPreviousMeasuredAt = printLatestDate([
    waterHoldingCapacitySincePreviousAnalysis?.previous_1y_measured_at,
  ]);

  return (
    <div className='flex flex-col gap-8 lg:gap-4'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.water.labels.waterHoldingCapacity')}
        supplementaryInfo={!isAssessmentPending && `${latestAnalysisTranslation} ${lastMeasuredDate}`}
        popover={{
          title: t('shared.ncaDetail.details.water.explainers.waterHoldingCapacity.title'),
          body: t('shared.ncaDetail.details.water.explainers.waterHoldingCapacity.body'),
        }}
      />
      <div className='flex flex-col gap-8 lg:flex-row lg:justify-between lg:gap-0'>
        <NCFactCapsule
          label={latestAnalysisTranslation}
          fact={waterHoldingCapacityLatestAnalysis}
          assessmentStatus={assessmentStatus}
          capsuleIconProps={{
            icon: getNCChangeIcon(waterHoldingCapacityLatestAnalysis),
            variant: getNCChangeIconVariant(waterHoldingCapacityLatestAnalysis),
          }}
          infoPopoverProps={{
            title: t('shared.ncaDetail.details.water.explainers.waterHoldingCapacityLatestAnalysis.title'),
            body: t('shared.ncaDetail.details.water.explainers.waterHoldingCapacityLatestAnalysis.body', {
              date: lastMeasuredDate,
            }),
          }}
        />
        {!!waterHoldingCapacitySincePreviousAnalysis?.value && (
          <NCFactCapsule
            label={t('shared.ncaDetail.details.labels.sincePreviousAnalysis')}
            fact={waterHoldingCapacitySincePreviousAnalysis}
            assessmentStatus={assessmentStatus}
            capsuleIconProps={{
              icon: getNCChangeIcon(waterHoldingCapacitySincePreviousAnalysis),
              variant: getNCChangeIconVariant(waterHoldingCapacitySincePreviousAnalysis),
            }}
            infoPopoverProps={{
              title: t('shared.ncaDetail.details.water.explainers.sincePreviousAnalysis.title'),
              body: t('shared.ncaDetail.details.water.explainers.sincePreviousAnalysis.body', {
                date: whcSincePreviousAnalysisInitialMeasuredAt,
                previousDate: whcSincePreviousAnalysisPreviousMeasuredAt,
              }),
              popoverTriggerProps: {
                'data-testid': 'whc-since-previous-analysis-tooltip',
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

const SoilMoisture = () => {
  const { t } = useTranslation();

  const latestAnalysisTranslation = t('shared.ncaDetail.details.labels.latestAnalysis');

  const plotId = usePlotId();
  const { analysisType } = useNCCardContext();

  const { getFact } = usePlotReportForPlot({ plotId });
  const soilMoistureLatestAnalysis = getFact(
    analysisType === 'total'
      ? R1FactType.r1_soil_moisture_latest_analysis_total
      : R1FactType.r1_soil_moisture_latest_analysis_per_ha,
  );

  const { assessmentStatus, isAssessmentPending } = useAssessmentStatus();

  const lastMeasuredDate = printLatestDate([soilMoistureLatestAnalysis?.measured_at]);

  return (
    <div className='flex flex-col gap-8 lg:gap-4'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.water.labels.soilMoisture')}
        supplementaryInfo={!isAssessmentPending && `${latestAnalysisTranslation} ${lastMeasuredDate}`}
        popover={{
          title: t('shared.ncaDetail.details.water.explainers.soilMoisture.title'),
          body: t('shared.ncaDetail.details.water.explainers.soilMoisture.body'),
        }}
      />
      <NCFactCapsule
        label={latestAnalysisTranslation}
        fact={soilMoistureLatestAnalysis}
        assessmentStatus={assessmentStatus}
        capsuleIconProps={{
          icon: getNCChangeIcon(soilMoistureLatestAnalysis),
          variant: getNCChangeIconVariant(soilMoistureLatestAnalysis),
        }}
        infoPopoverProps={{
          title: t('shared.ncaDetail.details.explainers.latestAnalysis.title'),
          body: t('shared.ncaDetail.details.explainers.latestAnalysis.body', {
            date: lastMeasuredDate,
          }),
        }}
      />
    </div>
  );
};

const Trends = () => {
  const { t } = useTranslation();

  const plotId = usePlotId();
  const { getFact } = usePlotReportForPlot({ plotId });

  const soilMoistureTrendChange = getFact(R1FactType.r1_soil_moisture_trend_change);
  const precipitationTrendChange = getFact(R1FactType.r1_precipitation_trend_change);

  const { isAssessmentPending } = useAssessmentStatus();

  const lastMeasuredDate = printLatestDate([
    soilMoistureTrendChange?.measured_at,
    precipitationTrendChange?.measured_at,
  ]);

  return (
    <div className='flex flex-col gap-8 lg:gap-4'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.water.labels.droughtRisk')}
        supplementaryInfo={
          !isAssessmentPending && `${t('shared.ncaDetail.details.labels.latestEstimates')} ${lastMeasuredDate}`
        }
        popover={{
          title: t('shared.ncaDetail.details.water.explainers.droughtRisk.title'),
          body: t('shared.ncaDetail.details.water.explainers.droughtRisk.body', {
            currentMonth: printShortMonth(Date.now()),
          }),
        }}
      />
      <Stack direction='row' className='flex-wrap justify-between gap-8'>
        <SoilMoistureChange />
        <PrecipitationChange />
      </Stack>
    </div>
  );
};

const SoilMoistureChange = () => {
  const { t } = useTranslation();

  const plotId = usePlotId();
  const { getFact } = usePlotReportForPlot({ plotId });

  const soilMoistureTrendChange = getFact(R1FactType.r1_soil_moisture_trend_change);

  const { assessmentStatus } = useAssessmentStatus();

  const label = t('shared.ncaDetail.details.water.labels.soilMoisture');

  const infoPopoverProps = {
    title: t('shared.ncaDetail.details.water.explainers.soilMoisturePercentageChange.title'),
    body: t('shared.ncaDetail.details.water.explainers.soilMoisturePercentageChange.body', {
      currentMonth: printShortMonth(Date.now()),
    }),
  };

  if (soilMoistureTrendChange.value === 0) {
    return (
      <Capsule
        label={<CapsuleLabel infoPopoverProps={infoPopoverProps}>{label}</CapsuleLabel>}
        content={<CapsuleContent>{t('global.analysis.noSignificantTrend')}</CapsuleContent>}
        thumbnail={<CapsuleIcon icon={<RiArrowRightLine />} variant='default' />}
      />
    );
  }

  return (
    <NCFactCapsule
      label={label}
      fact={soilMoistureTrendChange}
      assessmentStatus={assessmentStatus}
      capsuleIconProps={{
        icon: getNCChangeIcon(soilMoistureTrendChange),
        variant: getNCChangeIconVariant(soilMoistureTrendChange),
      }}
      infoPopoverProps={infoPopoverProps}
    />
  );
};

const PrecipitationChange = () => {
  const { t } = useTranslation();

  const plotId = usePlotId();
  const { getFact } = usePlotReportForPlot({ plotId });

  const precipitationTrendChange = getFact(R1FactType.r1_precipitation_trend_change);

  const { assessmentStatus } = useAssessmentStatus();

  const label = t('shared.ncaDetail.details.water.labels.precipitation');

  const infoPopoverProps = {
    title: t('shared.ncaDetail.details.water.explainers.precipitationPercentageChange.title'),
    body: t('shared.ncaDetail.details.water.explainers.precipitationPercentageChange.body', {
      currentMonth: printShortMonth(Date.now()),
    }),
  };

  if (precipitationTrendChange.value === 0) {
    return (
      <Capsule
        label={<CapsuleLabel infoPopoverProps={infoPopoverProps}>{label}</CapsuleLabel>}
        content={<CapsuleContent>{t('global.analysis.noSignificantTrend')}</CapsuleContent>}
        thumbnail={<CapsuleIcon icon={<RiArrowRightLine />} variant='default' />}
      />
    );
  }

  return (
    <NCFactCapsule
      label={label}
      fact={precipitationTrendChange}
      assessmentStatus={assessmentStatus}
      capsuleIconProps={{
        icon: getNCChangeIcon(precipitationTrendChange),
        variant: getNCChangeIconVariant(precipitationTrendChange),
      }}
      infoPopoverProps={infoPopoverProps}
    />
  );
};
