import {
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownItemLink,
  DropdownItemLinkProps,
  DropdownItemProps,
  DropdownTrigger,
  Pagination,
  RiArrowRightSLine,
  RiDeleteBinLine,
  RiEditLine,
  RiEyeLine,
  RiMore2Line,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  toast,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@landler/tw-component-library';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { Plot, PlotStatusEnum } from '@/api/rest/resources/types/plot';
import { UNANALYZABLE_PLOT_STATUS_TYPES } from '@/config/constants';
import { useMembershipType } from '@/hooks/useMembershipType';
import { Logger } from '@/lib/logs/logger';
import { MultipleLandtypesNotice } from '@/pages/shared/components/Notices';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { getProjectPermissions } from '@/utils/permissions/getProjectPermissions';

import { PAGE_SIZE } from '../constants';
import { useDestinationPath } from '../hooks/useDestinationPath';
import { usePaginatedPlotsForProject } from '../hooks/usePaginatedPlotsForProject';
import {
  AreaCell,
  BiodiversityZoneCell,
  CarbonStorageBgCell,
  NameCell,
  PlotTypeCell,
  WaterHoldingCapacityCell,
} from './Cells';
import { DeletePlotWithConfirmation } from './DeletePlotWithConfirmation';
import { ToggleSortButton } from './ToggleSortButton';

export const PlotListDesktop = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const membershipType = useMembershipType();
  const projectDetail = useProjectDetailById().data;
  const isProjectEditable = getProjectPermissions(projectDetail).includes('write');

  const [plotForDeletion, setPlotForDeletion] = useState<Plot | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const setCurrentPage = (page: number) => setSearchParams({ page: `${page}` }, { preventScrollReset: true });

  const { count: totalPlotCount, results: plots } = usePaginatedPlotsForProject(
    PAGE_SIZE,
    (currentPage - 1) * PAGE_SIZE,
    '-errors,has_complete_questionnaire,name',
  ).data;

  const confirmPlotDelete = (plot: Plot) => {
    setPlotForDeletion(plot);
  };

  const columns = useMemo<ColumnDef<Plot>[]>(
    () => [
      {
        accessorKey: 'name',
        header: ({ column }) => (
          <ToggleSortButton column={column} className='-ml-2.5'>
            {t('shared.plots.tableHeader.name')}
          </ToggleSortButton>
        ),
        cell: NameCell,
      },
      {
        accessorKey: 'area',
        header: ({ column }) => (
          <ToggleSortButton column={column} className='-ml-2.5'>
            {t('shared.plots.tableHeader.size')}
          </ToggleSortButton>
        ),
        cell: AreaCell,
      },
      {
        accessorKey: 'type',
        header: ({ column }) => (
          <ToggleSortButton column={column} className='-ml-2.5'>
            {t('shared.plots.tableHeader.type')}
          </ToggleSortButton>
        ),
        cell: PlotTypeCell,
      },
      {
        accessorKey: 'biodiversityZone',
        header: ({ column }) => (
          <ToggleSortButton column={column} className='-ml-2.5'>
            {t('global.analysis.biodiversityZone')}
          </ToggleSortButton>
        ),
        cell: BiodiversityZoneCell,
      },
      {
        accessorKey: 'carbonStorageBg',
        header: ({ column }) => (
          <ToggleSortButton column={column} className='-ml-2.5'>
            {t('global.analysis.carbonStorageBg')}
          </ToggleSortButton>
        ),
        cell: CarbonStorageBgCell,
      },
      {
        accessorKey: 'waterHoldingCapacity',
        header: ({ column }) => (
          <ToggleSortButton column={column} className='-ml-2.5'>
            {t('global.analysis.waterHoldingCapacity')}
          </ToggleSortButton>
        ),
        cell: WaterHoldingCapacityCell,
      },
      {
        accessorKey: 'actions',
        header: () => null,
        cell: ({ row }) => {
          const plot = row.original;
          const isValid = plot.status !== PlotStatusEnum.invalid;

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const editLabel = useMemo(() => {
            const exhaustivenessCheck = (status: never) => {
              Logger.error(`No edit label set for plot status ${status}`);
              return t('shared.plots.overflowMenu.editData');
            };

            switch (plot.status) {
              case PlotStatusEnum.invalid:
                return t('shared.plots.overflowMenu.reviewData');
              case PlotStatusEnum.new_plot:
                return t('shared.plots.overflowMenu.addData');
              case PlotStatusEnum.draft:
                return t('shared.plots.overflowMenu.confirmData');
              case PlotStatusEnum.ready_to_analyse:
              case PlotStatusEnum.scheduled_for_analysis:
              case PlotStatusEnum.analysed:
              case PlotStatusEnum.calculating:
                return t('shared.plots.overflowMenu.editData');
              default:
                return exhaustivenessCheck(plot.status);
            }
          }, [plot.status]);

          if (membershipType === MembershipWithOrganizationTypeEnum.land_steward) {
            return (
              <Dropdown modal={true}>
                <DropdownTrigger data-testid='plot-row-overflow-menu'>
                  <div className='shrink-0 rounded-full border border-primary-100 p-2 text-primary-100 hover:bg-neutral-black-4'>
                    <RiMore2Line size={24} />
                  </div>
                </DropdownTrigger>

                <DropdownContent side='bottom' sideOffset={10}>
                  {isValid && (
                    <DropdownItemLink
                      to={buildPath(paths.landSteward.v1_2_plot, {
                        pathParams: {
                          projectId: projectDetail.id,
                          plotId: plot.id,
                        },
                      })}
                      state={{ plotPage: `${currentPage}` }}
                      leftAdornment={<RiEyeLine />}
                    >
                      {t('shared.plots.overflowMenu.viewPlot')}
                    </DropdownItemLink>
                  )}
                  <EditPlotDropdownItemLink
                    disabled={!isProjectEditable}
                    disabledHint={t('shared.projects.plot.tooltips.disabledEditOrDeletePlot')}
                    to={buildPath(paths.landSteward.editPlot, {
                      pathParams: {
                        plotId: plot.id,
                        projectId: projectDetail.id,
                      },
                    })}
                    state={{
                      previousPath: pathname,
                    }}
                    leftAdornment={<RiEditLine />}
                  >
                    {editLabel}
                  </EditPlotDropdownItemLink>
                  <DeletePlotDropdownItem
                    disabled={!isProjectEditable}
                    disabledHint={t('shared.projects.plot.tooltips.disabledEditOrDeletePlot')}
                    onClick={() => confirmPlotDelete(plot)}
                    leftAdornment={<RiDeleteBinLine />}
                  >
                    {t('shared.plots.overflowMenu.deletePlot')}
                  </DeletePlotDropdownItem>
                </DropdownContent>
              </Dropdown>
            );
          }

          return isValid ? (
            <Link
              to={buildPath(paths.buyer.plot, {
                pathParams: { projectId: projectDetail.id, plotId: row.original.id },
              })}
              state={{ plotPage: `${currentPage}` }}
            >
              <Stack center className='h-10 w-10 rounded-full border border-primary-100 hover:bg-neutral-black-4'>
                <RiArrowRightSLine size={24} />
              </Stack>
            </Link>
          ) : null;
        },
      },
    ],
    [isProjectEditable, t, projectDetail.id, pathname, currentPage, membershipType],
  );

  const [sorting, setSorting] = useState<SortingState>([]);
  const { getDestinationPath } = useDestinationPath();

  const table = useReactTable({
    data: plots,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: PAGE_SIZE,
      },
    },
    state: {
      sorting,
    },
  });

  const pageStart = (currentPage - 1) * PAGE_SIZE;
  const pageEnd = pageStart + PAGE_SIZE;

  const containsMultipleLandtypes = projectDetail.landtypes_allowed.length > 1;

  return (
    <>
      <Stack spacing={8} data-testid='plot-list-desktop'>
        {containsMultipleLandtypes && <MultipleLandtypesNotice />}
        <Stack className='flex flex-row items-center justify-between gap-2'>
          <span className='typography-overline'>
            {t('shared.plots.pagination.titleDesktop', {
              count: totalPlotCount,
              start: Math.max(1, pageStart + 1), // start at 1 for display (pageStart is 0-indexed)
              end: Math.min(pageEnd, totalPlotCount), // show total plots if pageEnd overshoots in the end
            })}
          </span>
          <Pagination
            className='typography-overline text-text-secondary'
            currentPage={currentPage}
            totalCount={totalPlotCount}
            pageSize={PAGE_SIZE}
            onPageChange={(nextPage) => {
              setCurrentPage(nextPage);
            }}
            data-testid='pagination'
          />
        </Stack>

        <div className='rounded-3xl bg-white-100'>
          <Table data-testid='plots-table'>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableHead key={header.id}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  ))}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows.map((row) => {
                const destinationPath = getDestinationPath(row.original);
                const onRowClick = () => {
                  const plot = row.original;
                  if (plot.status === PlotStatusEnum.invalid && !isProjectEditable) {
                    toast({
                      toastId: 'project-is-locked-redirect-toast',
                      title: t('landSteward.plot.lockedToasts.redirectAddEditLink'),
                      type: 'error',
                      autoClose: 10_000,
                    });
                  }
                  return destinationPath && navigate(destinationPath, { state: { plotPage: `${currentPage}` } });
                };
                return (
                  <TableRow data-testid='plot-row' key={row.id} onClick={onRowClick}>
                    {row.getVisibleCells().map((cell) => {
                      const plot = cell.row.original;

                      /** Do not render succeeding columns so that biodiversity CTA can span over those columns
                       *  If more columns need to be spanned over, the accessoryKeys of those columns need to be added into this condition
                       */
                      if (
                        ['carbonStorageBg', 'waterHoldingCapacity'].includes(cell.column.id) &&
                        UNANALYZABLE_PLOT_STATUS_TYPES.includes(plot.status)
                      ) {
                        return null;
                      }

                      if (
                        cell.column.id === 'biodiversityZone' &&
                        UNANALYZABLE_PLOT_STATUS_TYPES.includes(plot.status)
                      ) {
                        return (
                          <TableCell key={cell.id} colSpan={3}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        );
                      }

                      return (
                        <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </Stack>

      <DeletePlotWithConfirmation
        plot={plotForDeletion}
        onClose={() => setPlotForDeletion(null)}
        onSuccess={() => window.location.reload()}
      />
    </>
  );
};

type EditPlotDropdownItemLinkProps = DropdownItemLinkProps & {
  disabledHint: string;
};

const EditPlotDropdownItemLink: FC<EditPlotDropdownItemLinkProps> = ({ children, disabledHint, to, ...delegated }) => {
  if (!delegated.disabled) {
    return (
      <DropdownItemLink to={to} {...delegated}>
        {children}
      </DropdownItemLink>
    );
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <DropdownItem {...delegated}>{children}</DropdownItem>
      </TooltipTrigger>
      <TooltipContent sideOffset={5} side='left'>
        {disabledHint}
        <TooltipArrow className='fill-white-100' />
      </TooltipContent>
    </Tooltip>
  );
};

type DeletePlotDropdownItemProps = DropdownItemProps & {
  disabledHint: string;
};

const DeletePlotDropdownItem: FC<DeletePlotDropdownItemProps> = ({ children, disabledHint, ...delegated }) => {
  if (!delegated.disabled) {
    return <DropdownItem {...delegated}>{children}</DropdownItem>;
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <DropdownItem {...delegated}>{children}</DropdownItem>
      </TooltipTrigger>
      <TooltipContent sideOffset={5} side='left'>
        {disabledHint}
        <TooltipArrow className='fill-white-100' />
      </TooltipContent>
    </Tooltip>
  );
};
