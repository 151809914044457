import { Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { CarbonUpliftPotentialExplainer } from '@/components/NCAExplainersV1_2/CarbonUpliftPotentialExplainer';
import { WhcUpliftPotentialExplainer } from '@/components/NCAExplainersV1_2/WhcUpliftPotentialExplainer';
import { NCCard, NCCard2SectionLayout, NCCardAnalysisTypeToggle, NCCardHeader } from '@/components/NCCardV1_2/NCCard';

import { CarbonGraphTile } from './components/CarbonGraphTile';
import { CarbonStatsTile } from './components/CarbonStatsTile';
import { WaterGraphTile } from './components/WaterGraphTile';
import { WaterStatsTile } from './components/WaterStatsTile';

export const ImpactProjectionsPage = () => {
  const { t } = useTranslation();

  return (
    <Stack spacing={10}>
      <NCCard explainer={<CarbonUpliftPotentialExplainer />} data-testid='carbon-impact-projections'>
        <NCCardHeader title={t('global.analysis.carbon')}>
          <NCCardAnalysisTypeToggle data-testid='carbon-toggle' />
        </NCCardHeader>
        <NCCard2SectionLayout className='md:min-h-[400px]'>
          <CarbonGraphTile />
          <CarbonStatsTile />
        </NCCard2SectionLayout>
      </NCCard>

      <NCCard explainer={<WhcUpliftPotentialExplainer />} data-testid='water-impact-projections'>
        <NCCardHeader title={t('global.analysis.water')}>
          <NCCardAnalysisTypeToggle data-testid='water-toggle' />
        </NCCardHeader>
        <NCCard2SectionLayout className='md:min-h-[400px]'>
          <WaterGraphTile />
          <WaterStatsTile />
        </NCCard2SectionLayout>
      </NCCard>
    </Stack>
  );
};
