// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ChartXTick = (props: any) => {
  const { x, y, payload, formatter } = props;

  const formattedValue = formatter ? formatter(payload.value) : payload.value;
  return (
    <g transform={`translate(${x},${y})`}>
      <circle cx={0} cy={-8} r={2} className='fill-graph-point' />
      <text x={5} y={0} dy={10} className='typography-caption text-text-secondary' textAnchor='middle'>
        {formattedValue}
      </text>
    </g>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ChartYTick = (props: any) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <circle cx={8} cy={0} r={2} className='fill-graph-point' />
      <text x={0} y={5} className='typography-caption text-text-secondary' textAnchor='end'>
        {payload.value}
      </text>
    </g>
  );
};
