import { Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { PlotList } from '../../../components/plot-list/PlotList';
import { LandMonitoringPageWrapper } from '../components/LandMonitoringPageWrapper/LandMonitoring.page-wrapper';

export const DeforestationDetailPage = () => {
  const { t } = useTranslation();

  const plotListFilters = {
    is_deforested: true,
  };

  return (
    <LandMonitoringPageWrapper title={t('shared.ncaDetail.details.deforestation.deforestation')}>
      <Stack spacing={8}>
        <PlotList
          plotFilters={plotListFilters}
          plotListTitleProps={{
            title: t('shared.ncaDetail.details.deforestation.plotsAffectedByDeforestation'),
          }}
        />
      </Stack>
    </LandMonitoringPageWrapper>
  );
};
