import { useParams } from 'react-router-dom';

import { R1Fact, R1FactType } from '@/api/rest/resources/types/fact';

import { useDashboardByProjectId } from './useDashboardByProjectId';

// TODO: MVP-3284 remove and use R1FactType directly?
export type NCDataType =
  | 'biodiversityZonePercent'
  | 'biodiversityZonePercentHistoricGraph'
  | 'biodiversityLatestAnalysis'
  | 'biodiversityBufferZonePctPrevious1yUpliftChange'
  | 'biodiversityBufferZonePctPrevious1yUpliftPctChange'
  | 'biodiversityBufferZonePerHa'
  | 'biodiversityBufferZonePerHaPrevious1yUpliftChange'
  | 'biodiversityBufferZonePerHaPrevious1yUpliftPctChange'
  | 'habitatDiversityLatestAnalysis'
  | 'deforestationInitial'
  | 'deforestationOccuredSince2020'
  | 'carbonStorageBgTotal'
  | 'carbonStorageBgPerHa'
  | 'carbonBgPrevious1yUpliftTotal'
  | 'carbonBgPrevious1yUpliftPerHa'
  | 'carbonBgPrevious1yUpliftTotalPctChange'
  | 'carbonBgPrevious1yUpliftPerHaPctChange'
  | 'carbonBgUpliftPotentialGraph'
  | 'carbonBgUpliftPotentialPerHaGraph'
  | 'soilCarbonHistoricTotalGraph'
  | 'soilCarbonHistoricPerHaGraph'
  | 'carbonBgLatestAnalysisTotal'
  | 'carbonBgLatestAnalysisPerHa'
  | 'carbonStorageBgCo2EquivalentTotal'
  | 'carbonStorageBgCo2EquivalentPerHa'
  | 'ghgEmissionsPerYearLatestEstimatesTotal'
  | 'ghgEmissionsPerYearLatestEstimatesPerHa'
  | 'carbonBg1YearUpliftPotentialTotal'
  | 'carbonBg1YearUpliftPotentialPerHa'
  | 'carbonBg10YearUpliftPotentialTotal'
  | 'carbonBg10YearUpliftPotentialPerHa'
  | 'carbonBg1yUpliftPotentialTotalChange'
  | 'carbonBg1yUpliftPotentialPerHaChange'
  | 'carbonBg1yUpliftPotentialTotalPctChange'
  | 'carbonBg1yUpliftPotentialPerHaPctChange'
  | 'carbonBg10yUpliftPotentialTotalChange'
  | 'carbonBg10yUpliftPotentialPerHaChange'
  | 'carbonBg10yUpliftPotentialTotalPctChange'
  | 'carbonBg10yUpliftPotentialPerHaPctChange'
  | 'waterHoldingCapacityTotal'
  | 'waterHoldingCapacityPerHa'
  | 'historicWaterAndSoilMoistureGraph'
  | 'historicWaterAndSoilMoisturePerHaGraph'
  | 'waterHoldingCapacityLatestAnalysisTotal'
  | 'waterHoldingCapacityLatestAnalysisPerHa'
  | 'waterHoldingCapacityIncreaseSincePrevAnalysisTotal'
  | 'waterHoldingCapacityIncreaseSincePrevAnalysisPerHa'
  | 'precipitationTrendChange'
  | 'soilMoistureLatestAnalysisTotal'
  | 'soilMoistureLatestAnalysisPerHa'
  | 'soilMoistureTrendChange'
  | 'waterRiskLatestEstimate'
  | 'waterHoldingCapacity10yearUpliftPotentialGraph'
  | 'waterHoldingCapacity10yearUpliftPotentialPerHaGraph'
  | 'waterHoldingCapacity1yearUpliftPotentialTotal'
  | 'waterHoldingCapacity1yearUpliftPotentialPerHa'
  | 'waterHoldingCapacity1yearUpliftIncreaseTotal'
  | 'waterHoldingCapacity1yearUpliftIncreasePerHa'
  | 'waterHoldingCapacity10yearUpliftPotentialTotal'
  | 'waterHoldingCapacity10yearUpliftPotentialPerHa'
  | 'waterHoldingCapacity10yearUpliftIncreaseTotal'
  | 'waterHoldingCapacity10yearUpliftIncreasePerHa'
  | 'waterWhcIncrease1yUpliftPotentialTotalChange'
  | 'waterWhcIncrease1yUpliftPotentialPerHaChange'
  | 'waterWhcIncrease1yUpliftPotentialTotalPctChange'
  | 'waterWhcIncrease1yUpliftPotentialPerHaPctChange'
  | 'waterWhcIncrease10yUpliftPotentialTotalChange'
  | 'waterWhcIncrease10yUpliftPotentialPerHaChange'
  | 'waterWhcIncrease10yUpliftPotentialTotalPctChange'
  | 'waterWhcIncrease10yUpliftPotentialPerHaPctChange'
  | 'whcPrevious1yUpliftTotal'
  | 'whcPrevious1yUpliftPerHa'
  | 'waterWhcIncreasePrevious1yUpliftTotalPctChange'
  | 'waterWhcIncreasePrevious1yUpliftPerHaPctChange';

/**
 * @param projectId Falls back to the projectId in the URL if no value is provided.
 */
export const useNCData = <T = unknown>(type: NCDataType, projectIdArg?: string) => {
  const routeParams = useParams();
  const projectId = projectIdArg ?? routeParams.projectId;

  const dashboardData = useDashboardByProjectId(projectId).data;

  const getFact = (factType: R1FactType) => {
    return dashboardData.facts.find((f) => f.name === factType) as R1Fact<T | null> | undefined;
  };

  const exhaustivenessCheck = (t: never) => {
    throw Error(`Unrecognised type received: ${t}`);
  };

  // TODO: MVP-3284 remove unused after checking with BE
  // eslint-disable-next-line sonarjs/max-switch-cases
  switch (type) {
    case 'biodiversityZonePercent':
      return getFact(R1FactType.r1_biodiversity_zone_percent);
    case 'biodiversityBufferZonePctPrevious1yUpliftChange':
      return getFact(R1FactType.r1_biodiversity_buffer_zone_pct_previous_1y_uplift_change);
    case 'biodiversityBufferZonePctPrevious1yUpliftPctChange':
      return getFact(R1FactType.r1_biodiversity_buffer_zone_pct_previous_1y_uplift_pct_change);
    case 'biodiversityBufferZonePerHa':
      return getFact(R1FactType.r1_biodiversity_buffer_zone_per_ha);
    case 'biodiversityBufferZonePerHaPrevious1yUpliftChange':
      return getFact(R1FactType.r1_biodiversity_buffer_zone_per_ha_previous_1y_uplift_change);
    case 'biodiversityBufferZonePerHaPrevious1yUpliftPctChange':
      return getFact(R1FactType.r1_biodiversity_buffer_zone_per_ha_previous_1y_uplift_pct_change);
    case 'biodiversityZonePercentHistoricGraph':
      return getFact(R1FactType.r1_biodiversity_zone_percent_historic_graph);
    case 'biodiversityLatestAnalysis':
      return getFact(R1FactType.r1_biodiversity_latest_analysis);
    case 'habitatDiversityLatestAnalysis':
      return getFact(R1FactType.r1_habitat_diversity_latest_analysis);
    case 'deforestationInitial':
      return getFact(R1FactType.r1_deforestation_occurred_since_2020);
    case 'deforestationOccuredSince2020':
      return getFact(R1FactType.r1_deforestation_occurred_since_2020);

    case 'carbonStorageBgTotal':
      return getFact(R1FactType.r1_carbon_storage_bg_total);
    case 'carbonStorageBgPerHa':
      return getFact(R1FactType.r1_carbon_storage_bg_per_ha);
    case 'carbonBg1yUpliftPotentialTotalChange':
      return getFact(R1FactType.r1_carbon_bg_1year_uplift_potential_total_change);
    case 'carbonBg1yUpliftPotentialPerHaChange':
      return getFact(R1FactType.r1_carbon_bg_1year_uplift_potential_per_ha_change);
    case 'carbonBg1yUpliftPotentialTotalPctChange':
      return getFact(R1FactType.r1_carbon_bg_1year_uplift_potential_total_pct_change);
    case 'carbonBg1yUpliftPotentialPerHaPctChange':
      return getFact(R1FactType.r1_carbon_bg_1year_uplift_potential_per_ha_pct_change);
    case 'carbonBg10yUpliftPotentialTotalChange':
      return getFact(R1FactType.r1_carbon_bg_10year_uplift_potential_total_change);
    case 'carbonBg10yUpliftPotentialPerHaChange':
      return getFact(R1FactType.r1_carbon_bg_10year_uplift_potential_per_ha_change);
    case 'carbonBg10yUpliftPotentialTotalPctChange':
      return getFact(R1FactType.r1_carbon_bg_10year_uplift_potential_total_pct_change);
    case 'carbonBg10yUpliftPotentialPerHaPctChange':
      return getFact(R1FactType.r1_carbon_bg_10year_uplift_potential_per_ha_pct_change);
    case 'carbonBgPrevious1yUpliftTotal':
      return getFact(R1FactType.r1_carbon_bg_previous_1y_uplift_total);
    case 'carbonBgPrevious1yUpliftPerHa':
      return getFact(R1FactType.r1_carbon_bg_previous_1y_uplift_per_ha);
    case 'carbonBgPrevious1yUpliftTotalPctChange':
      return getFact(R1FactType.r1_carbon_bg_previous_1y_uplift_total_pct_change);
    case 'carbonBgPrevious1yUpliftPerHaPctChange':
      return getFact(R1FactType.r1_carbon_bg_previous_1y_uplift_per_ha_pct_change);
    case 'carbonBgUpliftPotentialGraph':
      return getFact(R1FactType.r1_carbon_bg_uplift_potential_graph);
    case 'carbonBgUpliftPotentialPerHaGraph':
      return getFact(R1FactType.r1_carbon_bg_uplift_potential_per_ha_graph);
    case 'soilCarbonHistoricTotalGraph':
      return getFact(R1FactType.r1_soil_carbon_historic_total_graph);
    case 'soilCarbonHistoricPerHaGraph':
      return getFact(R1FactType.r1_soil_carbon_historic_per_ha_graph);
    case 'carbonBgLatestAnalysisTotal':
      return getFact(R1FactType.r1_carbon_bg_latest_analysis_total);
    case 'carbonBgLatestAnalysisPerHa':
      return getFact(R1FactType.r1_carbon_bg_latest_analysis_per_ha);
    case 'ghgEmissionsPerYearLatestEstimatesTotal':
      return getFact(R1FactType.r1_ghg_emissions_per_year_latest_analysis_total);
    case 'ghgEmissionsPerYearLatestEstimatesPerHa':
      return getFact(R1FactType.r1_ghg_emissions_per_year_latest_analysis_per_ha);
    case 'carbonBg1YearUpliftPotentialTotal':
      return getFact(R1FactType.r1_carbon_bg_1year_uplift_potential_total);
    case 'carbonBg1YearUpliftPotentialPerHa':
      return getFact(R1FactType.r1_carbon_bg_1year_uplift_potential_per_ha);
    case 'carbonBg10YearUpliftPotentialTotal':
      return getFact(R1FactType.r1_carbon_bg_10year_uplift_potential_total);
    case 'carbonBg10YearUpliftPotentialPerHa':
      return getFact(R1FactType.r1_carbon_bg_10year_uplift_potential_per_ha);
    case 'carbonStorageBgCo2EquivalentTotal':
      return getFact(R1FactType.r1_carbon_storage_bg_co2_equivalent_total);
    case 'carbonStorageBgCo2EquivalentPerHa':
      return getFact(R1FactType.r1_carbon_storage_bg_co2_equivalent_per_ha);

    case 'waterHoldingCapacityTotal':
      return getFact(R1FactType.r1_water_holding_capacity_total);
    case 'waterHoldingCapacityPerHa':
      return getFact(R1FactType.r1_water_holding_capacity_per_ha);
    case 'historicWaterAndSoilMoistureGraph':
      return getFact(R1FactType.r1_historic_water_and_soil_moisture_graph);
    case 'historicWaterAndSoilMoisturePerHaGraph':
      return getFact(R1FactType.r1_historic_water_and_soil_moisture_per_ha_graph);
    case 'waterWhcIncrease1yUpliftPotentialTotalChange':
      return getFact(R1FactType.r1_water_whc_increase_1y_uplift_potential_total_change);
    case 'waterWhcIncrease1yUpliftPotentialPerHaChange':
      return getFact(R1FactType.r1_water_whc_increase_1y_uplift_potential_per_ha_change);
    case 'waterWhcIncrease1yUpliftPotentialTotalPctChange':
      return getFact(R1FactType.r1_water_whc_increase_1y_uplift_potential_total_pct_change);
    case 'waterWhcIncrease1yUpliftPotentialPerHaPctChange':
      return getFact(R1FactType.r1_water_whc_increase_1y_uplift_potential_per_ha_pct_change);
    case 'waterWhcIncrease10yUpliftPotentialTotalChange':
      return getFact(R1FactType.r1_water_whc_increase_10y_uplift_potential_total_change);
    case 'waterWhcIncrease10yUpliftPotentialPerHaChange':
      return getFact(R1FactType.r1_water_whc_increase_10y_uplift_potential_per_ha_change);
    case 'waterWhcIncrease10yUpliftPotentialTotalPctChange':
      return getFact(R1FactType.r1_water_whc_increase_10y_uplift_potential_total_pct_change);
    case 'waterWhcIncrease10yUpliftPotentialPerHaPctChange':
      return getFact(R1FactType.r1_water_whc_increase_10y_uplift_potential_per_ha_pct_change);
    case 'waterHoldingCapacityLatestAnalysisTotal':
      return getFact(R1FactType.r1_water_holding_capacity_latest_analysis_total);
    case 'waterHoldingCapacityLatestAnalysisPerHa':
      return getFact(R1FactType.r1_water_holding_capacity_latest_analysis_per_ha);
    case 'waterHoldingCapacityIncreaseSincePrevAnalysisTotal':
      return getFact(R1FactType.r1_water_holding_capacity_increase_since_prev_analysis_total);
    case 'waterHoldingCapacityIncreaseSincePrevAnalysisPerHa':
      return getFact(R1FactType.r1_water_holding_capacity_increase_since_prev_analysis_per_ha);
    case 'precipitationTrendChange':
      return getFact(R1FactType.r1_precipitation_trend_change);
    case 'soilMoistureLatestAnalysisTotal':
      return getFact(R1FactType.r1_soil_moisture_latest_analysis_total);
    case 'soilMoistureLatestAnalysisPerHa':
      return getFact(R1FactType.r1_soil_moisture_latest_analysis_per_ha);
    case 'soilMoistureTrendChange':
      return getFact(R1FactType.r1_soil_moisture_trend_change);
    case 'waterRiskLatestEstimate':
      return getFact(R1FactType.r1_water_risk_latest_estimate);
    case 'waterHoldingCapacity10yearUpliftPotentialGraph':
      return getFact(R1FactType.r1_water_holding_capacity_10year_uplift_potential_graph);
    case 'waterHoldingCapacity10yearUpliftPotentialPerHaGraph':
      return getFact(R1FactType.r1_water_holding_capacity_10year_uplift_potential_per_ha_graph);
    case 'waterHoldingCapacity1yearUpliftPotentialTotal':
      return getFact(R1FactType.r1_water_holding_capacity_1year_uplift_potential_total);
    case 'waterHoldingCapacity1yearUpliftPotentialPerHa':
      return getFact(R1FactType.r1_water_holding_capacity_1year_uplift_potential_per_ha);
    case 'waterHoldingCapacity1yearUpliftIncreaseTotal':
      return getFact(R1FactType.r1_water_holding_capacity_1year_uplift_increase_total);
    case 'waterHoldingCapacity1yearUpliftIncreasePerHa':
      return getFact(R1FactType.r1_water_holding_capacity_1year_uplift_increase_per_ha);
    case 'waterHoldingCapacity10yearUpliftPotentialTotal':
      return getFact(R1FactType.r1_water_holding_capacity_10year_uplift_potential_total);
    case 'waterHoldingCapacity10yearUpliftPotentialPerHa':
      return getFact(R1FactType.r1_water_holding_capacity_10year_uplift_potential_per_ha);
    case 'waterHoldingCapacity10yearUpliftIncreaseTotal':
      return getFact(R1FactType.r1_water_holding_capacity_10year_uplift_increase_total);
    case 'waterHoldingCapacity10yearUpliftIncreasePerHa':
      return getFact(R1FactType.r1_water_holding_capacity_10year_uplift_increase_per_ha);
    case 'whcPrevious1yUpliftTotal':
      return getFact(R1FactType.r1_whc_previous_1y_uplift_total);
    case 'whcPrevious1yUpliftPerHa':
      return getFact(R1FactType.r1_whc_previous_1y_uplift_per_ha);
    case 'waterWhcIncreasePrevious1yUpliftTotalPctChange':
      return getFact(R1FactType.r1_water_whc_increase_previous_1y_uplift_total_pct_change);
    case 'waterWhcIncreasePrevious1yUpliftPerHaPctChange':
      return getFact(R1FactType.r1_water_whc_increase_previous_1y_uplift_per_ha_pct_change);

    default:
      return exhaustivenessCheck(type);
  }
};
