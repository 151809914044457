import { useTranslation } from 'react-i18next';
import { Stack } from 'tw-component-library';

import { ProjectStatus } from '@/api/rest/resources/types/project';
import { CalculationInProgress, NoChartData } from '@/components/ChartsV1_2';
import { ChartHeading } from '@/components/ChartsV1_2/components/ChartHeading';
import { WaterHoldingCapacityChart, WaterHoldingCapacityChartData } from '@/components/ChartsV1_2/WaterHoldingCapacity';
import { useNCCardContext } from '@/components/NCCardV1_2/NCCard';
import { useNCData } from '@/pages/shared/hooks/useNCData';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';

export const WaterGraphTile = () => {
  const { t } = useTranslation();
  const { analysisType } = useNCCardContext();

  const project = useProjectDetailById().data;
  const waterHoldingCapacity10yearUpliftPotential = useNCData<WaterHoldingCapacityChartData[]>(
    analysisType === 'total'
      ? 'waterHoldingCapacity10yearUpliftPotentialGraph'
      : 'waterHoldingCapacity10yearUpliftPotentialPerHaGraph',
  );

  const chartData = waterHoldingCapacity10yearUpliftPotential?.value;
  const noChartData = !chartData?.length;

  return (
    <Stack center data-testid='water-uplift-graph-tile'>
      {(() => {
        if (noChartData) {
          if ([ProjectStatus.initial, ProjectStatus.in_preparation].includes(project.status)) {
            return <NoChartData variant='analysisPending' />;
          }

          if (project.status === ProjectStatus.scheduled_for_analysis) {
            return <CalculationInProgress />;
          }

          return <NoChartData />;
        }

        return (
          <Stack spacing={2.5} className='w-full items-center justify-center p-6 lg:items-start lg:p-8 lg:pl-2'>
            <ChartHeading
              // TODO: MVP-3284 rename the key
              heading={t('shared.ncaDetail.details.water.labels.upliftGraphHeadline_v1_2')}
              popover={{
                title: t('shared.ncaDetail.details.water.explainers.upliftPotential.title'),
                body: t('shared.ncaDetail.details.water.explainers.upliftPotential.body'),
              }}
            />
            <WaterHoldingCapacityChart
              analysisType={analysisType}
              data={chartData}
              showTooltip={true}
              className='relative'
              data-testid='water-holding-capacity-chart'
            />
          </Stack>
        );
      })()}
    </Stack>
  );
};
