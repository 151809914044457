import { cn } from '@landler/tw-component-library';
import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Area, AreaChart, Label, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { UnitEnum } from '@/api/rest/resources/types/fact';
import { Logger } from '@/lib/logs/logger';
import { formatUnit } from '@/utils/formatting';
import { valueToTonne } from '@/utils/formatting/formatKgToTon';

import { NCCardAnalysisType } from '../NCCard/NCCard';
import { ChartXTick, ChartYTick } from './components/Axis';
import { ChartLegendBar, ChartLegendBarItem } from './components/LegendBar';
import { ChartTooltip, ChartTooltipDot } from './components/Tooltip';
import { minMaxAxisDomain } from './components/utils';
import { NoChartData } from './NoChartData';
import { DefaultChartProps } from './types';

const chartColor = '#FFDD5F';

export type CarbonStorageBGChartData = {
  date: string;
  name: 'carbon_storage';
  value: number;
  unit: 't';
};

type CarbonStorageBGChartProps = DefaultChartProps & {
  /** The chart data */
  data: CarbonStorageBGChartData[];
  analysisType: NCCardAnalysisType;
};

export const CarbonStorageBGChart: FC<HTMLAttributes<HTMLDivElement> & CarbonStorageBGChartProps> = ({
  analysisType,
  data,
  height = 400,
  width = '100%',
  showTooltip = true,
  className,
  children,
  ...delegated
}) => {
  const styles = cn('flex w-full flex-col', className);

  const chartData = data.map((d) => ({
    label: new Date(d.date).getFullYear(),
    value:
      d.unit === UnitEnum.kg.toString() || d.unit === UnitEnum['kg/ha'].toString()
        ? valueToTonne(d.value, d.unit)
        : d.value,
  }));
  const unit = analysisType === 'total' ? formatUnit(UnitEnum.t) : formatUnit(UnitEnum['t/ha']);

  const firstTick = chartData.at(0);
  const lastTick = chartData.at(-1);

  if (!firstTick || !lastTick) {
    Logger.error('Not enough data to render chart');
    return (
      <div className={cn(styles, 'h-full justify-center')} {...delegated}>
        <NoChartData />
      </div>
    );
  }

  /**  Science spec
  Show X axis ticks in gaps of 1 year sequentially
  */
  const xTicks = () => {
    const startYear = firstTick.label;
    const endYear = lastTick.label;
    const ticksRange = Array(endYear - startYear + 1).keys();
    return Array.from(ticksRange).map((x) => x + startYear);
  };

  return (
    <div className={styles} {...delegated}>
      <ResponsiveContainer width={width} height={height}>
        <AreaChart
          data={chartData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id='carbonStorageBG' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='0%' stopColor={chartColor} stopOpacity={1} />
              <stop offset='100%' stopColor={chartColor} stopOpacity={0.2} />
            </linearGradient>
          </defs>
          <Area
            isAnimationActive={false}
            type='monotone'
            dataKey='value'
            stroke='none'
            fill='url(#carbonStorageBG)'
            fillOpacity={1}
            activeDot={(props) => ChartTooltipDot(props)}
          />
          <XAxis
            dataKey='label'
            axisLine={false}
            tickLine={false}
            tick={<ChartXTick />}
            padding={{ right: 25 }}
            type='number'
            domain={['dataMin', 'dataMax']}
            ticks={xTicks()}
          />
          <YAxis
            dataKey='value'
            axisLine={false}
            tickLine={false}
            type='number'
            scale='sequential'
            domain={minMaxAxisDomain(0.8, 1.2)}
            tick={<ChartYTick />}
            padding={{ top: 25 }}
            width={40}
          >
            <Label className='typography-caption text-text-secondary' value={unit} position={{ x: 32, y: 10 }} />
          </YAxis>
          {showTooltip && (
            <Tooltip
              isAnimationActive={false}
              cursor={false}
              content={(content) => ChartTooltip(content, undefined, unit)}
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
      <CustomLegendBar />
      {children}
    </div>
  );
};

const CustomLegendBar = () => {
  const { t } = useTranslation();

  const legendBarItems: ChartLegendBarItem[] = [
    {
      label: t('global.analysis.carbonStorageBg'),
      color: chartColor,
    },
  ];

  return <ChartLegendBar items={legendBarItems} data-testid='carbon-storage-bg-chart-legend' />;
};
