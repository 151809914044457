import { useTranslation } from 'react-i18next';
import { Area, AreaChart, ResponsiveContainer } from 'recharts';

import { UnitEnum } from '@/api/rest/resources/types/fact';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { ProjectStatus } from '@/api/rest/resources/types/project';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useProject } from '@/pages/landsteward/hooks/useProject';
import { useNCData } from '@/pages/shared/hooks/useNCData';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { formatKilogramsToTonnes, formatUnit } from '@/utils/formatting';

import { NoData } from '../../../components/NoData';
import { Tile, TileCapsuleContent, TileCapsuleTitle, TileCta, TileHelper } from './Tile';

export const CarbonTile = () => {
  const { t } = useTranslation();
  const project = useProject().data;
  const pathSet =
    useMembershipType() === MembershipWithOrganizationTypeEnum.land_steward ? paths.landSteward : paths.buyer;

  const soilCarbonHistoricPerHaGraph = useNCData<CarbonHistoricGraphData[]>('soilCarbonHistoricPerHaGraph');
  const carbonBgLatestAnalysis = formatKilogramsToTonnes(useNCData<number>('carbonStorageBgPerHa'));

  const chartData = soilCarbonHistoricPerHaGraph?.value;

  const carbonBgLatestAnalysisDisplay = `${useDisplayNumber(carbonBgLatestAnalysis?.value ?? 0)} ${formatUnit(
    carbonBgLatestAnalysis?.unit ?? UnitEnum['t/ha'],
  )}`;

  const noChartData = !chartData?.length;

  if (noChartData) {
    return <NoData />;
  }

  return (
    <Tile
      chart={<Chart chartData={chartData} />}
      capsuleTitle={<TileCapsuleTitle>{t('global.analysis.belowGroundCarbonStorage')}</TileCapsuleTitle>}
      capsuleContent={<TileCapsuleContent>{carbonBgLatestAnalysisDisplay}</TileCapsuleContent>}
      cta={
        <TileCta to={buildPath(pathSet.v1_2_projectDetailsCarbon, { pathParams: { projectId: project.id } })}>
          {t('shared.projects.project.landMonitoring.carbon.labels.seeAllCarbonIndicators')}
        </TileCta>
      }
      helper={<TileHelper>{t('shared.projects.project.landMonitoring.carbon.helper')}</TileHelper>}
    />
  );
};

type CarbonHistoricGraphData = {
  date: string;
  name: 'carbon_storage_per_ha';
  value: number;
  unit: 't';
};

const Chart = ({ chartData }: { chartData: CarbonHistoricGraphData[] }) => {
  const project = useProject().data;
  const isOutdatedValue = project.status !== ProjectStatus.analysed;

  return (
    <ResponsiveContainer width='100%' height={80}>
      <AreaChart
        data={chartData}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <defs>
          <linearGradient id='carbonHistorical' x1='0' y1='-2.6' x2='0' y2='1'>
            <stop offset='0%' stopColor={isOutdatedValue ? '#B4B4B4' : '#FFDD5F'} stopOpacity={1} />
            <stop offset='100%' stopColor={isOutdatedValue ? '#B4B4B4' : '#FFDD5F'} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          isAnimationActive={false}
          type='monotone'
          dataKey='value'
          stroke={isOutdatedValue ? '#595959' : '#E5BF2C'}
          strokeWidth='2px'
          fill='url(#carbonHistorical)'
          fillOpacity={1}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
