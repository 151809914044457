import { Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { CapsuleTrend } from '@/components';
import { NCCardSectionHeader, printLatestDate } from '@/components/NCCardV1_2/NCCard';
import { getNCChangeIcon, getNCChangeIconVariant, NCFactCapsule } from '@/components/NCFactCapsuleV1_2/NCFactCapsule';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { useAssessmentStatus } from '@/pages/shared/hooks/useAssessmentStatus';
import { useNCData } from '@/pages/shared/hooks/useNCData';

export const BiodiversityCardStatsTile = () => {
  const { t } = useTranslation();

  const latestAnalysisTranslation = t('shared.ncaDetail.details.labels.latestAnalysis');

  const biodiversityLatestAnalysis = useNCData<number>('biodiversityLatestAnalysis');
  const biodiversity1YearTrend = useNCData<number>('biodiversityBufferZonePctPrevious1yUpliftPctChange');
  const biodiversity1yearTrendDisplay = `${useDisplayNumber(biodiversity1YearTrend?.value ?? 0, {
    signDisplay: 'always',
  })} %`;
  const biodiversity1YrGrowth = useNCData<number>('biodiversityBufferZonePctPrevious1yUpliftChange');

  const { assessmentStatus, isAssessmentPending } = useAssessmentStatus();

  const biodiversityLatestAnalysisLastMeasuredDate = printLatestDate([biodiversityLatestAnalysis?.measured_at]);
  const biodiversityIncreaseSince1yrLastMeasuredDate = printLatestDate([biodiversity1YrGrowth?.measured_at]);

  const lastMeasuredDate = printLatestDate([
    biodiversityLatestAnalysis?.measured_at,
    biodiversity1YrGrowth?.measured_at,
  ]);
  return (
    <Stack className='gap-8 p-6 lg:justify-center lg:gap-4 lg:p-8 lg:pr-2' data-testid='biodiversity-stats-tile'>
      <div className='flex flex-col gap-8 lg:gap-4'>
        <NCCardSectionHeader
          title={t('shared.ncaDetail.details.biodiversity.labels.biodiversityZone')}
          supplementaryInfo={!isAssessmentPending && `${latestAnalysisTranslation} ${lastMeasuredDate}`}
          popover={{
            title: t('shared.ncaDetail.details.biodiversity.explainers.biodiversityZone.title'),
            body: t('shared.ncaDetail.details.biodiversity.explainers.biodiversityZone.body'),
          }}
        />
        <div className='flex flex-col gap-8 lg:flex-row lg:justify-between lg:gap-0'>
          <NCFactCapsule
            label={latestAnalysisTranslation}
            capsuleIconProps={{
              icon: getNCChangeIcon(biodiversityLatestAnalysis),
              variant: getNCChangeIconVariant(biodiversityLatestAnalysis),
            }}
            trend={
              typeof biodiversity1YearTrend?.value === 'number' && (
                <CapsuleTrend variant={biodiversity1YearTrend.value < 0 ? 'negative' : 'positive'}>
                  {biodiversity1yearTrendDisplay}
                </CapsuleTrend>
              )
            }
            assessmentStatus={assessmentStatus}
            fact={biodiversityLatestAnalysis}
            infoPopoverProps={{
              title: t('shared.ncaDetail.details.explainers.latestAnalysis.title'),
              body: t('shared.ncaDetail.details.explainers.latestAnalysis.body', {
                date: biodiversityLatestAnalysisLastMeasuredDate,
              }),
            }}
          />
          <NCFactCapsule
            label={t('shared.ncaDetail.details.labels.oneYearChange')}
            capsuleIconProps={{
              icon: getNCChangeIcon(biodiversity1YrGrowth),
              variant: getNCChangeIconVariant(biodiversity1YrGrowth),
            }}
            assessmentStatus={assessmentStatus}
            fact={biodiversity1YrGrowth}
            infoPopoverProps={{
              title: t('shared.ncaDetail.details.biodiversity.explainers.oneYearChange.title'),
              body: t('shared.ncaDetail.details.biodiversity.explainers.oneYearChange.body', {
                date: biodiversityLatestAnalysisLastMeasuredDate,
                previousDate: biodiversityIncreaseSince1yrLastMeasuredDate,
              }),
            }}
          />
        </div>
      </div>
    </Stack>
  );
};
