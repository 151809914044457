import { RiPieChart2Line, Stack } from '@landler/tw-component-library';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ProjectStatus } from '@/api/rest/resources/types/project';
import { Logger } from '@/lib/logs/logger';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';

export const NoData = () => {
  const { t } = useTranslation();
  const project = useProjectDetailById().data;
  const projectStatus = project.status;

  const text = useMemo(() => {
    const exhaustivenessCheck = (v: never) => {
      Logger.error(`Invalid project status received: ${v}`);
      return t('global.analysis.noData');
    };

    switch (projectStatus) {
      case ProjectStatus.initial:
      case ProjectStatus.in_preparation:
        return t('global.analysis.noAnalysis');
      case ProjectStatus.scheduled_for_analysis:
        return t('global.analysis.calculationInProgress');
      case ProjectStatus.analysed:
      case ProjectStatus.ready_to_analyse:
      case ProjectStatus.activated:
      case ProjectStatus.invested:
      case ProjectStatus.locked:
        return t('global.analysis.noData');
      default:
        return exhaustivenessCheck(projectStatus);
    }
  }, [projectStatus, t]);

  return (
    <Stack center spacing={4} className='h-[337px] text-text-secondary'>
      <span className='rounded-full bg-neutral-black-8 p-3'>
        <RiPieChart2Line size={20} />
      </span>
      <span className='typography-h3 '>{text}</span>
    </Stack>
  );
};
