import { Stack } from '@landler/tw-component-library';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { Plot } from '@/api/rest/resources/types/plot';
import { CarbonExplainer } from '@/components/NCAExplainersV1_2/CarbonExplainer';
import { useScreenSize } from '@/hooks/useScreenSize';

import { CarbonStorageBgCell } from '../../../components/plot-list/components/Cells';
import { ToggleSortButton } from '../../../components/plot-list/components/ToggleSortButton';
import { PlotList } from '../../../components/plot-list/PlotList';
import { LandMonitoringPageWrapper } from '../components/LandMonitoringPageWrapper/LandMonitoring.page-wrapper';
import { CarbonCard } from './components/nc-breakdown/Card';

export const CarbonDetailPage = () => {
  const { t } = useTranslation();
  const isSmallScreen = useScreenSize() === 'small';

  const customPlotListColumns: ColumnDef<Plot>[] = [
    {
      id: 'carbonStorageBg',
      accessorKey: 'carbonStorageBg',
      header: ({ column }) => (
        <ToggleSortButton column={column} className='-ml-2.5'>
          {t('global.analysis.carbonStorageBg')}
        </ToggleSortButton>
      ),
      cell: CarbonStorageBgCell,
    },
  ];

  return (
    <LandMonitoringPageWrapper title={t('shared.ncaDetail.details.carbon.labels.carbon')}>
      <Stack spacing={8}>
        <CarbonCard />
        <PlotList
          customColumns={customPlotListColumns}
          plotListTitleProps={{
            title: t('shared.ncaDetail.details.carbon.labels.carbonDataPerPlot'),
            action: !isSmallScreen ? <CarbonExplainer /> : null,
          }}
        />
      </Stack>
    </LandMonitoringPageWrapper>
  );
};
