import 'mapbox-gl/dist/mapbox-gl.css';

import React from 'react';
import { Map as MapGlMap, MapProps, MapRef } from 'react-map-gl';

import { DEFAULT_MAP_STYLE, MAPBOX_TOKEN } from '@/config/constants';
import { fontFamily } from '@/theme/fontFamily';

export const Map = React.forwardRef<MapRef, MapProps>(({ style, fog, terrain, ...delegated }, ref) => (
  <MapGlMap
    ref={ref}
    mapStyle={DEFAULT_MAP_STYLE}
    mapboxAccessToken={MAPBOX_TOKEN}
    dragRotate={false}
    touchPitch={false}
    cursor='default'
    {...delegated}
    // NOTE: This is needed to convert the type T | undefined | null to T | undefined
    fog={fog ?? undefined}
    terrain={terrain ?? undefined}
    style={{ fontFamily: fontFamily.sans, ...style }}
    logoPosition='bottom-right'
    attributionControl={false}
  />
));
Map.displayName = 'Map';

export * from './hooks/useJustifyMap';
