import { SELF_SIGNUP_ENABLED } from '@/config/constants';

enum Access {
  ONLY_SIGNED_OUT = 'ONLY_SIGNED_OUT', // can be accessed by signed out users only
  ONLY_SIGNED_IN = 'ONLY_SIGNED_IN', // can be accessed by signed in users only
  PUBLIC = 'PUBLIC', // can be accessed by anyone
}

const landStewardPaths = {
  signUp: [Access.ONLY_SIGNED_OUT, SELF_SIGNUP_ENABLED ? '/landsteward/sign-up' : '/landsteward/sign-up-0e4edc79'],
  completeAccount: [Access.ONLY_SIGNED_IN, '/landsteward/complete-account'],

  account: [Access.ONLY_SIGNED_IN, '/landsteward/account'],
  accountEditEmail: [Access.ONLY_SIGNED_IN, '/landsteward/account/edit-email'],
  accountEditName: [Access.ONLY_SIGNED_IN, '/landsteward/account/edit-name'],
  accountEditPassword: [Access.ONLY_SIGNED_IN, '/landsteward/account/edit-password'],
  accountLegal: [Access.ONLY_SIGNED_IN, '/landsteward/account/legal'],
  accountTeam: [Access.ONLY_SIGNED_IN, '/landsteward/account/team'],
  accountTeamEditMember: [Access.ONLY_SIGNED_IN, '/landsteward/account/team/edit/:memberId'],
  accountTeamInvite: [Access.ONLY_SIGNED_IN, '/landsteward/account/team/invite'],

  projects: [Access.ONLY_SIGNED_IN, '/landsteward/projects'],
  projectsComplianceFrameworks: [Access.ONLY_SIGNED_IN, '/landsteward/projects/compliance-frameworks'],
  newProject: [Access.ONLY_SIGNED_IN, '/landsteward/projects/new'],
  projectDetails: [Access.ONLY_SIGNED_IN, '/landsteward/projects/:projectId'],
  v1_2_nca: [Access.ONLY_SIGNED_IN, '/landsteward/projects/v1_2/:projectId/nca'],
  v1_2_landMonitoring: [Access.ONLY_SIGNED_IN, '/landsteward/projects/v1_2/:projectId'],
  v1_2_projectDetailsBiodiversity: [Access.ONLY_SIGNED_IN, '/landsteward/projects/v1_2/:projectId/biodiversity'],
  v1_2_projectDetailsCarbon: [Access.ONLY_SIGNED_IN, '/landsteward/projects/v1_2/:projectId/carbon'],
  v1_2_projectDetailsDeforestation: [Access.ONLY_SIGNED_IN, '/landsteward/projects/v1_2/:projectId/deforestation'],

  v1_2_projectDetailsWater: [Access.ONLY_SIGNED_IN, '/landsteward/projects/v1_2/:projectId/water'],

  v1_2_impactProjections: [Access.ONLY_SIGNED_IN, '/landsteward/projects/v1_2/:projectId/impactProjections'],

  plots: [Access.ONLY_SIGNED_IN, '/landsteward/projects/:projectId/plots'],
  v1_2_plots: [Access.ONLY_SIGNED_IN, '/landsteward/projects/v1_2/:projectId/plots'],
  plot: [Access.ONLY_SIGNED_IN, '/landsteward/projects/:projectId/plots/:plotId'],
  v1_2_plot: [Access.ONLY_SIGNED_IN, '/landsteward/projects/v1_2/:projectId/plots/:plotId'],

  newPlot: [Access.ONLY_SIGNED_IN, '/landsteward/projects/:projectId/plot/new'],
  newPlotDrawBoundary: [Access.ONLY_SIGNED_IN, '/landsteward/projects/:projectId/plot/new/draw-boundary'],
  newPlotUpload: [Access.ONLY_SIGNED_IN, '/landsteward/projects/:projectId/plot/new/upload'],
  newPlotReviewUpload: [Access.ONLY_SIGNED_IN, '/landsteward/projects/:projectId/plot/new/review'],
  newPlotQuestionnaire: [Access.ONLY_SIGNED_IN, '/landsteward/projects/:projectId/plot/new/questionnaire'],
  newPlotSuccess: [Access.ONLY_SIGNED_IN, '/landsteward/projects/:projectId/plot/new/success'],
  editPlot: [Access.ONLY_SIGNED_IN, '/landsteward/projects/:projectId/plot/:plotId/edit'],
  editPlotBoundary: [Access.ONLY_SIGNED_IN, '/landsteward/projects/:projectId/plot/:plotId/edit/boundary'],
  editPlotSuccess: [Access.ONLY_SIGNED_IN, '/landsteward/projects/:projectId/plot/:plotId/edit/success'],

  investments: [Access.ONLY_SIGNED_IN, '/landsteward/investments'],

  portfolio: [Access.ONLY_SIGNED_IN, '/landsteward/portfolio'],
} as const;

const buyerPaths = {
  completeAccount: [Access.ONLY_SIGNED_OUT, '/buyer/complete-account'],

  account: [Access.ONLY_SIGNED_IN, '/buyer/account'],
  accountEditEmail: [Access.ONLY_SIGNED_IN, '/buyer/account/edit-email'],
  accountEditName: [Access.ONLY_SIGNED_IN, '/buyer/account/edit-name'],
  accountEditPassword: [Access.ONLY_SIGNED_IN, '/buyer/account/edit-password'],
  accountLegal: [Access.ONLY_SIGNED_IN, '/buyer/account/legal'],
  accountTeam: [Access.ONLY_SIGNED_IN, '/buyer/account/team'],
  accountTeamEditMember: [Access.ONLY_SIGNED_IN, '/buyer/account/team/edit/:memberId'],
  accountTeamInvite: [Access.ONLY_SIGNED_IN, '/buyer/account/team/invite'],

  projects: [Access.ONLY_SIGNED_IN, '/buyer/projects'],
  projectsComplianceFrameworks: [Access.ONLY_SIGNED_IN, '/buyer/projects/compliance-frameworks'],
  projectDetails: [Access.ONLY_SIGNED_IN, '/buyer/projects/:projectId'],
  v1_2_nca: [Access.ONLY_SIGNED_IN, '/buyer/projects/v1_2/:projectId/nca'],
  v1_2_landMonitoring: [Access.ONLY_SIGNED_IN, '/buyer/projects/v1_2/:projectId'],
  v1_2_projectDetailsBiodiversity: [Access.ONLY_SIGNED_IN, '/buyer/projects/v1_2/:projectId/biodiversity'],
  v1_2_projectDetailsCarbon: [Access.ONLY_SIGNED_IN, '/buyer/projects/v1_2/:projectId/carbon'],
  v1_2_projectDetailsDeforestation: [Access.ONLY_SIGNED_IN, '/buyer/projects/v1_2/:projectId/deforestation'],

  v1_2_projectDetailsWater: [Access.ONLY_SIGNED_IN, '/buyer/projects/v1_2/:projectId/water'],

  v1_2_impactProjections: [Access.ONLY_SIGNED_IN, '/buyer/projects/v1_2/:projectId/impactProjections'],

  plots: [Access.ONLY_SIGNED_IN, '/buyer/projects/:projectId/plots'],
  v1_2_plots: [Access.ONLY_SIGNED_IN, '/buyer/projects/v1_2/:projectId/plots'],
  plot: [Access.ONLY_SIGNED_IN, '/buyer/projects/:projectId/plots/:plotId'],
  v1_2_plot: [Access.ONLY_SIGNED_IN, '/buyer/projects/v1_2/:projectId/plots/:plotId'],

  investments: [Access.ONLY_SIGNED_IN, '/buyer/investments'],

  portfolio: [Access.ONLY_SIGNED_IN, '/buyer/portfolio'],
} as const;

const publicPaths = {
  activateAccount: [Access.PUBLIC, '/activate-account'],

  confirmMailChange: [Access.ONLY_SIGNED_IN, '/confirm-mail-change'],

  login: [Access.ONLY_SIGNED_OUT, '/login'],
  loginImpersonate: [Access.PUBLIC, '/login/impersonate'],

  newPassword: [Access.ONLY_SIGNED_OUT, '/reset-password/new-password'],
  resetPassword: [Access.ONLY_SIGNED_OUT, '/reset-password'],

  root: [Access.PUBLIC, '/'],
} as const;

const extractKeyRoute = <T extends string>(routes: Record<T, readonly [Access, string]>): Record<T, string> => {
  return Object.entries<readonly [Access, string]>(routes).reduce((acc, [key, value]) => {
    const route = value[1];
    acc[key as T] = route;
    return acc;
  }, {} as Record<T, string>);
};

type LandStewardKeys = keyof typeof landStewardPaths;
type BuyerKeys = keyof typeof buyerPaths;
type PublicKeys = keyof typeof publicPaths;

type PathsType = Record<PublicKeys, string> & {
  landSteward: Record<LandStewardKeys, string>;
} & {
  buyer: Record<BuyerKeys, string>;
};

export const paths: PathsType = {
  ...extractKeyRoute<PublicKeys>(publicPaths),
  landSteward: extractKeyRoute<LandStewardKeys>(landStewardPaths),
  buyer: extractKeyRoute<BuyerKeys>(buyerPaths),
};

const extractRoutesByAccess = (routes: Record<string, readonly [Access, string]>, access: Access) => {
  return [
    ...Object.values(routes)
      .filter((route) => route[0] === access)
      .map((route) => route[1]),
  ];
};

const getRoutesByAccess = (access: Access): string[] => {
  return [
    ...extractRoutesByAccess(publicPaths, access),
    ...extractRoutesByAccess(landStewardPaths, access),
    ...extractRoutesByAccess(buyerPaths, access),
  ];
};

export const onlySignedOutRoutes = getRoutesByAccess(Access.ONLY_SIGNED_OUT);
export const onlySignedInRoutes = getRoutesByAccess(Access.ONLY_SIGNED_IN);
export const publicRoutes = getRoutesByAccess(Access.PUBLIC);
