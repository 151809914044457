import { useSuspenseQuery } from '@tanstack/react-query';

import { getRequest } from '@/api/rest/hooks/requests/get';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import {
  GET_BUYER_PROJECT_BY_ID_PATH,
  GET_PROJECT_BY_ID_PATH,
  GetBuyerProjectByIdSuccessDto,
  GetProjectByIdSuccessDto,
  ProjectBuyerList,
  ProjectList,
} from '@/api/rest/resources/types/project';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useProjects } from '@/pages/shared/hooks/useProjects';

export type ProjectListEnhancedLS = GetProjectByIdSuccessDto & ProjectList;
export type ProjectListEnhancedBuyer = GetBuyerProjectByIdSuccessDto & ProjectBuyerList;

export type ProjectListEnhanced = ProjectListEnhancedLS | ProjectListEnhancedBuyer;

export type ProjectListEnhancedWithCentroid = ProjectListEnhanced & { centroid: { lat: number; lon: number } };

export const useProjectsList = () => {
  const membershipType = useMembershipType();

  const projects = useProjects().data.results;

  const queryFn = async () => {
    return Promise.all(
      projects.map(async (project) => {
        const response = await getRequest<ProjectListEnhanced>(
          membershipType === MembershipWithOrganizationTypeEnum.land_steward
            ? GET_PROJECT_BY_ID_PATH
            : GET_BUYER_PROJECT_BY_ID_PATH,
          { id: project.id },
        );

        return { ...response, ...project } as ProjectListEnhanced;
      }),
    );
  };

  return useSuspenseQuery<ProjectListEnhanced[]>({
    queryKey: [membershipType, 'project', 'projectsList', projects],
    queryFn,
    staleTime: 1000 * 60 * 5,
  });
};
