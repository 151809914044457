import 'react-toastify/dist/ReactToastify.css';

import {
  cssTransition,
  toast as toastifyToast,
  ToastContainer as ToastifyContainer,
  ToastOptions,
} from 'react-toastify';

import { RiCheckFill, RiCloseFill, RiErrorWarningLine, RiInformationLine } from '../Icon/Icon';
import { cn } from '../utils';

const styles = {
  error: { bg: 'bg-error', text: 'text-white-100' },
  default: { bg: 'bg-info-light', text: 'text-info-dark' },
  success: { bg: 'bg-success', text: 'text-white-100' },
} as const;

type AvailableToastTypes = keyof typeof styles;

const transition = cssTransition({
  enter: 'animate-in fade-in slide-in-from-bottom-4',
  exit: 'animate-out',
  collapse: false,
});

export const ToastContainer = () => {
  return (
    <div>
      <ToastifyContainer
        // Weird library typings not worth fighting
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        toastClassName={({ type }: any) =>
          cn(
            styles[(type as keyof typeof styles) || 'default'].bg,
            styles[(type as keyof typeof styles) || 'default'].text,
            'relative flex px-4 py-2 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer',
          )
        }
        bodyClassName='block p-3'
        style={{ width: 'calc(100vw - 64px)', maxWidth: '500px' }}
        position='bottom-center'
        autoClose={5000}
        limit={2}
        hideProgressBar
        transition={transition}
        icon={false}
        closeButton={
          <>
            <RiCloseFill size={24} className='m-2 shrink-0' />
          </>
        }
      />
    </div>
  );
};

export const toast = ({
  title,
  description,
  icon,
  type,
  ...delegated
}: ToastOptions & {
  title: string;
  description?: string;
  type?: AvailableToastTypes;
}) => {
  let finalIcon = icon;
  if (!finalIcon) {
    const exhaustivenessCheck = (t: never) => {
      throw Error(`Invalid toast type: ${t}`);
    };
    switch (type) {
      case 'error':
        finalIcon = <RiErrorWarningLine size={24} className='flex-1' />;
        break;

      case 'success':
        finalIcon = <RiCheckFill size={24} className='flex-1' />;
        break;

      case 'default':
      case undefined:
        finalIcon = <RiInformationLine size={24} className='flex-1' />;
        break;

      default:
        exhaustivenessCheck(type);
    }
  }

  return toastifyToast(
    <>
      <span className='font-semibold' data-cy='toast-message'>
        {title}
      </span>
      {description && <span className='mt-1 block'>{description}</span>}
    </>,
    { type, icon: finalIcon, ...delegated },
  );
};

export { toastifyToast };
