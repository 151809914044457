import {
  Button,
  ButtonLink,
  ButtonLinkProps,
  cn,
  Divider,
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownItemLink,
  DropdownItemLinkProps,
  DropdownTrigger,
  IconButton,
  InfoPopover,
  Pill,
  RiEditLine,
  RiLoaderFill,
  RiMore2Fill,
  Stack,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
} from '@landler/tw-component-library';
import React, { FC, HTMLAttributes, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { PlotStatusEnum, PlotType } from '@/api/rest/resources/types/plot';
import { Card, CardContent, CardHeader, PlotTypeIcon } from '@/components';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useScreenSize } from '@/hooks/useScreenSize';
import { usePlot } from '@/pages/shared/hooks/usePlot';
import { usePlotReportForPlot } from '@/pages/shared/hooks/usePlotReportForPlot';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { getProjectPermissions } from '@/utils/permissions/getProjectPermissions';

const maxDisplayCropCount = 10;

export const PlotOverview: React.FC = () => {
  const { t } = useTranslation();
  const isLargeScreen = useScreenSize() === 'large';
  const pathname = useLocation();
  const membershipType = useMembershipType();

  const projectDetail = useProjectDetailById().data;
  const plot = usePlot().data;

  const { getFact } = usePlotReportForPlot({ plotId: plot.id });

  const deforestedPlotCount = getFact<number>(R1FactType.r1_deforestation_occurred_since_2020);

  const hasWritePermission = getProjectPermissions(projectDetail).includes('write');
  const editDeleteDisabledHint = t('shared.projects.plot.tooltips.disabledEditOrDeletePlot');

  const plotType = plot.type ?? null;

  const crops =
    plotType === PlotType.CROPLAND && plot.crops && plot.crops.length > 0
      ? plot.crops.map((crop) => t(`global.crops.${crop}`)).sort()
      : undefined;

  const deforestationActivityText = useMemo(() => {
    if (deforestedPlotCount?.value == null) {
      if ([PlotStatusEnum.calculating, PlotStatusEnum.scheduled_for_analysis].includes(plot.status)) {
        return (
          <DataStackContent className='text-text-secondary'>
            {t('global.analysis.calculationInProgress')}
          </DataStackContent>
        );
      }
      return <DataStackContent className='text-text-secondary'>{t('global.analysis.noAnalysis')}</DataStackContent>;
    }
    const isOutdatedValue = deforestedPlotCount?.value !== null && plot.status !== PlotStatusEnum.analysed;
    const textColor = isOutdatedValue ? 'text-text-secondary' : 'text-text-primary';

    if (deforestedPlotCount?.value === 0) {
      return (
        <DataStackContent className={textColor}>
          {t('shared.ncaDetail.details.deforestation.noDeforestationActivityDetected')}
        </DataStackContent>
      );
    }
    if (deforestedPlotCount?.value === 1) {
      return (
        <DataStackContent className={textColor}>
          {t('shared.ncaDetail.details.deforestation.deforestationActivityDetected')}
        </DataStackContent>
      );
    }
    return <DataStackContent className='text-text-secondary'>{t('global.analysis.noAnalysis')}</DataStackContent>;
  }, [t, deforestedPlotCount, plot]);

  return (
    <>
      <Stack spacing={6} data-testid='plot-overview'>
        <span className='typography-overline block'>{t('shared.ncaDetail.details.labels.plotOverview')}</span>
        <Card>
          <CardHeader>
            <Stack direction='row' spacing={4} className='justify-between'>
              <Stack direction='col' spacing={2} className='items-start'>
                <span className='typography-overline'>{t('global.plot.mapPopup.labels.plotName')}</span>
                <span className='typography-h3 mr-2 whitespace-normal break-all'>{plot.name}</span>
              </Stack>
              {membershipType === MembershipWithOrganizationTypeEnum.land_steward &&
                (isLargeScreen ? (
                  <EditPlotButton
                    className='h-fit'
                    disabled={!hasWritePermission}
                    disabledHint={editDeleteDisabledHint}
                    to={buildPath(paths.landSteward.editPlot, {
                      pathParams: { plotId: plot.id, projectId: projectDetail.id },
                    })}
                    state={{
                      previousPath: pathname,
                    }}
                  >
                    {t('shared.plots.overflowMenu.editData')}
                  </EditPlotButton>
                ) : (
                  <Dropdown>
                    <DropdownTrigger asChild>
                      <IconButton
                        className='h-fit border border-primary-100 text-primary-100'
                        data-testid='overview-more-button'
                      >
                        <RiMore2Fill size={24} />
                      </IconButton>
                    </DropdownTrigger>
                    <DropdownContent align='end'>
                      <MenuDropdownItemLink
                        disabled={!hasWritePermission}
                        disabledHint={editDeleteDisabledHint}
                        to={buildPath(paths.landSteward.editPlot, {
                          pathParams: { plotId: plot.id, projectId: projectDetail.id },
                        })}
                        state={{
                          previousPath: pathname,
                        }}
                        leftAdornment={<RiEditLine />}
                      >
                        {t('shared.plots.overflowMenu.editData')}
                      </MenuDropdownItemLink>
                    </DropdownContent>
                  </Dropdown>
                ))}
            </Stack>
          </CardHeader>
          <CardContent className='grid grid-cols-1 gap-x-20 gap-y-6 md:grid-cols-[max-content_max-content_auto]'>
            <div
              className={cn(
                'flex flex-col gap-x-20 gap-y-6',
                'md:grid md:grid-flow-col md:grid-cols-1',
                'md:grid-cols-[max-content_max-content_auto] md:grid-rows-2',
                'md:[grid-column-end:_-1] md:[grid-column-start:_1]',
              )}
            >
              {plot.status && (
                <DataStack>
                  <DataStackLabel>{t('global.plot.plotStatus')}</DataStackLabel>
                  <Pill
                    data-testid='plot-status-badge'
                    size='small'
                    className={cn(
                      'typography-overline whitespace-nowrap px-4 py-2',
                      'bg-neutral-black-8 text-text-primary',
                      {
                        'bg-neutral-black-8 text-text-primary': plot.status === PlotStatusEnum.draft,
                        'bg-warning text-warning-dark': plot.status === PlotStatusEnum.ready_to_analyse,
                        'bg-success text-white-100': plot.status === PlotStatusEnum.analysed,
                        'bg-new-plot-light text-new-plot-dark': plot.status === PlotStatusEnum.new_plot,
                        'bg-warning-light text-black-100': plot.status === PlotStatusEnum.calculating,
                      },
                    )}
                  >
                    {plot.status === PlotStatusEnum.calculating && <RiLoaderFill size={12} />}
                    {t(`global.plotStatus.${plot.status}`)}
                  </Pill>
                </DataStack>
              )}
              <DataStack>
                <DataStackLabel>{t('shared.ncaDetail.details.labels.location')}</DataStackLabel>
                <DataStackContent>
                  {projectDetail.location_description || t('global.plot.mapPopup.unknownLocation')}
                </DataStackContent>
              </DataStack>
              {plotType && (
                <DataStack>
                  <DataStackLabel>{t('global.plot.plotLandType')}</DataStackLabel>
                  <DataStackContent>
                    <Stack direction='row' spacing={1}>
                      <PlotTypeIcon type={plotType} size={20} />
                      <span>{t(`global.plotTypes.${plotType}`)}</span>
                    </Stack>
                  </DataStackContent>
                </DataStack>
              )}
              {crops && (
                <DataStack className='col-start-3 row-[span_2] md:max-w-lg'>
                  <DataStackLabel>{t('shared.ncaDetail.details.labels.crops')}</DataStackLabel>
                  <Stack className='flex flex-row flex-wrap gap-2'>
                    {crops.slice(0, maxDisplayCropCount).map((crop, index) => (
                      <Pill key={index} size='small' className='whitespace-nowrap bg-warning-light'>
                        {crop}
                      </Pill>
                    ))}
                    {crops.length > maxDisplayCropCount && (
                      <Pill key='...' size='small' className='bg-white whitespace-nowrap'>
                        {t('shared.ncaDetail.details.labels.cropsMoreCount', {
                          count: crops.length - maxDisplayCropCount,
                        })}
                      </Pill>
                    )}
                  </Stack>
                </DataStack>
              )}
            </div>
            <Divider style={{ gridColumnStart: 1, gridColumnEnd: -1 }} />
            <DataStack>
              <DataStackLabel>
                <Stack direction='row' spacing={1} className='items-center'>
                  {t('shared.ncaDetail.details.deforestation.deforestationActivity')}
                  <InfoPopover position='top' body={t('shared.ncaDetail.details.deforestation.popoverBody')} />
                </Stack>
              </DataStackLabel>
              {deforestationActivityText}
            </DataStack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
};

const DataStack = Stack;

const DataStackLabel: FC<HTMLAttributes<HTMLSpanElement>> = ({ className, ...delegated }) => (
  <span className={cn('typography-overline mb-2', className)} {...delegated} />
);
const DataStackContent: FC<HTMLAttributes<HTMLSpanElement>> = ({ className, ...delegated }) => (
  <span className={cn('typography-h4', className)} {...delegated} />
);

type EditPlotButtonProps = ButtonLinkProps & {
  disabledHint: string;
};

const EditPlotButton: FC<EditPlotButtonProps> = ({ children, disabledHint, to, ...delegated }) => {
  if (!delegated.disabled) {
    return (
      <ButtonLink variant='outline' type='button' to={to} {...delegated}>
        {children}
      </ButtonLink>
    );
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button {...delegated}>{children}</Button>
      </TooltipTrigger>
      <TooltipContent sideOffset={5} side='left'>
        {disabledHint}
        <TooltipArrow />
      </TooltipContent>
    </Tooltip>
  );
};

type MenuDropdownItemLinkProps = DropdownItemLinkProps & {
  disabledHint: string;
};

const MenuDropdownItemLink: FC<MenuDropdownItemLinkProps> = ({ children, disabledHint, to, ...delegated }) => {
  if (!delegated.disabled) {
    return (
      <DropdownItemLink to={to} {...delegated}>
        {children}
      </DropdownItemLink>
    );
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <DropdownItem {...delegated}>{children}</DropdownItem>
      </TooltipTrigger>
      <TooltipContent sideOffset={5} side='left'>
        {disabledHint}
        <TooltipArrow />
      </TooltipContent>
    </Tooltip>
  );
};
