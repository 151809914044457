import classNames from 'classnames';
import tailwindConfig from 'tailwind.config';
import { extendTailwindMerge } from 'tailwind-merge';
// eslint-disable-next-line import/no-extraneous-dependencies
import resolveConfig from 'tailwindcss/resolveConfig';

export const customTwMerge = extendTailwindMerge({
  classGroups: {
    elevation: [{ elevation: ['0', '1', '2', '3', '4', '5'] }],
  },
});

export const cn = (...args: classNames.ArgumentArray) => customTwMerge(classNames(...args));

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const theme = resolveConfig(tailwindConfig).theme!;
