import { useTranslation } from 'react-i18next';

import { UnitEnum } from '@/api/rest/resources/types/fact';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { MapOverviewHud } from '@/components';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useNCData } from '@/pages/shared/hooks/useNCData';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';
import { formatUnit, unitToTonne, valueToTonne } from '@/utils/formatting';
import { squareMetersToHectares } from '@/utils/plot';

export const ProjectMapHud = () => {
  const { t } = useTranslation();

  const membershipType = useMembershipType();
  const projectDetail = useProjectDetailById().data;

  const biodiversityZonePercent = useNCData<number>('biodiversityZonePercent');
  const carbonStorageBg = useNCData<number>('carbonStorageBgTotal');
  const waterHoldingCapacity = useNCData<number>('waterHoldingCapacityTotal');

  return (
    <MapOverviewHud
      items={[
        {
          label:
            membershipType === MembershipWithOrganizationTypeEnum.land_steward
              ? t('global.analysis.area')
              : t('global.analysis.totalSponsoredArea'),
          value: squareMetersToHectares(projectDetail.area),
          unit: formatUnit(UnitEnum.ha),
        },
        {
          label: t('global.analysis.biodiversityZone'),
          value: biodiversityZonePercent?.value,
          unit: formatUnit(biodiversityZonePercent?.unit ?? UnitEnum['%']),
        },
        {
          label: t('global.analysis.carbonStorageBg'),
          value: valueToTonne(carbonStorageBg?.value, carbonStorageBg?.unit ?? UnitEnum.t),
          unit: formatUnit(unitToTonne(carbonStorageBg?.unit ?? UnitEnum.t)),
        },
        {
          label: t('global.analysis.waterHoldingCapacity'),
          value: waterHoldingCapacity?.value,
          unit: formatUnit(waterHoldingCapacity?.unit ?? UnitEnum['m^3']),
        },
      ]}
    />
  );
};
