import { ReactComponent as BiodiversityIcon } from '@assets/images/icons/biodiversity.svg';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { NCCard, NCCard2SectionLayout, NCCardComplianceFooter, NCCardHeader } from '@/components';
import { BiodiversityExplainer } from '@/components/NCAExplainers/BiodiversityExplainer';
import { COMPLIANCE_LOGOS_BIODIVERSITY } from '@/config/constants';

import { BiodiversityCardGraphTile } from './GraphTile';
import { BiodiversityCardStatsTile } from './StatsTile';

export const BiodiversityCard = () => {
  const { projectId } = useParams();

  if (!projectId) {
    throw new Error('projectId param is required to render this component');
  }

  const { t } = useTranslation();

  return (
    <NCCard explainer={<BiodiversityExplainer />}>
      <NCCardHeader
        icon={<BiodiversityIcon className='text-natural-capital-biodiversity-dark' />}
        title={t('global.analysis.biodiversity')}
      />
      <NCCard2SectionLayout>
        <BiodiversityCardGraphTile />
        <BiodiversityCardStatsTile />
      </NCCard2SectionLayout>
      <NCCardComplianceFooter logos={COMPLIANCE_LOGOS_BIODIVERSITY} projectId={projectId} />
    </NCCard>
  );
};
