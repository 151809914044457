import { Divider, Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { CapsuleTrend } from '@/components';
import { NCCardSectionHeader, printLatestDate, useNCCardContext } from '@/components/NCCardV1_2/NCCard';
import { getNCChangeIcon, getNCChangeIconVariant, NCFactCapsule } from '@/components/NCFactCapsuleV1_2/NCFactCapsule';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { useScreenSize } from '@/hooks/useScreenSize';
import { useAssessmentStatus } from '@/pages/shared/hooks/useAssessmentStatus';
import { usePlotId } from '@/pages/shared/hooks/usePlotId';
import { usePlotReportForPlot } from '@/pages/shared/hooks/usePlotReportForPlot';
import { printShortMonth } from '@/utils/formatting/date';

export const WaterHistoricalStatsTile = () => {
  const isLargeScreen = useScreenSize() === 'large';
  return (
    <Stack className='gap-8 p-6 lg:justify-center lg:gap-4 lg:p-8 lg:pr-2' data-testid='water-historical-stats-tile'>
      <WaterHoldingCapacity />

      {isLargeScreen && <Divider className='border-dashed' />}

      <SoilMoisture />

      {isLargeScreen && <Divider className='border-dashed' />}

      <MoistureTrendChange />
    </Stack>
  );
};

const WaterHoldingCapacity = () => {
  const { t } = useTranslation();

  const latestAnalysisTranslation = t('shared.ncaDetail.details.labels.latestAnalysis');

  const plotId = usePlotId();
  const { analysisType } = useNCCardContext();

  const { getFact } = usePlotReportForPlot({ plotId });
  const waterHoldingCapacityLatestAnalysis = getFact<number>(
    analysisType === 'total' ? R1FactType.r1_water_holding_capacity_total : R1FactType.r1_water_holding_capacity_per_ha,
  );
  const whc1yrTrend = getFact<number>(
    analysisType === 'total'
      ? R1FactType.r1_water_whc_increase_previous_1y_uplift_total_pct_change
      : R1FactType.r1_water_whc_increase_previous_1y_uplift_per_ha_pct_change,
  );
  const whc1yrTrendDisplay = `${useDisplayNumber(whc1yrTrend?.value ?? 0, {
    signDisplay: 'always',
  })} %`;

  const waterHoldingCapacity1yChange = getFact<number>(
    analysisType === 'total' ? R1FactType.r1_whc_previous_1y_uplift_total : R1FactType.r1_whc_previous_1y_uplift_per_ha,
  );

  const { assessmentStatus, isAssessmentPending } = useAssessmentStatus();

  const lastMeasuredDate = printLatestDate([waterHoldingCapacityLatestAnalysis?.measured_at]);

  const whc1yChangeInitialMeasuredAt = lastMeasuredDate;
  const whc1yChangePreviousMeasuredAt = printLatestDate([waterHoldingCapacity1yChange?.measured_at]);

  return (
    <div className='flex flex-col gap-8 lg:gap-4'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.water.labels.waterHoldingCapacity')}
        supplementaryInfo={!isAssessmentPending && `${latestAnalysisTranslation} ${lastMeasuredDate}`}
        popover={{
          title: t('shared.ncaDetail.details.water.explainers.waterHoldingCapacity.title'),
          body: t('shared.ncaDetail.details.water.explainers.waterHoldingCapacity.body'),
        }}
      />
      <Stack direction='row' className='flex-wrap gap-16'>
        <NCFactCapsule
          label={latestAnalysisTranslation}
          fact={waterHoldingCapacityLatestAnalysis}
          trend={
            typeof whc1yrTrend?.value === 'number' && (
              <CapsuleTrend variant={whc1yrTrend.value < 0 ? 'negative' : 'positive'}>
                {whc1yrTrendDisplay}
              </CapsuleTrend>
            )
          }
          assessmentStatus={assessmentStatus}
          capsuleIconProps={{
            icon: getNCChangeIcon(waterHoldingCapacityLatestAnalysis),
            variant: getNCChangeIconVariant(waterHoldingCapacityLatestAnalysis),
          }}
          infoPopoverProps={{
            title: t('shared.ncaDetail.details.water.explainers.waterHoldingCapacityLatestAnalysis.title'),
            body: t('shared.ncaDetail.details.water.explainers.waterHoldingCapacityLatestAnalysis.body', {
              date: lastMeasuredDate,
            }),
          }}
        />
        {!!waterHoldingCapacity1yChange?.value && (
          <NCFactCapsule
            label={t('shared.ncaDetail.details.labels.oneYearChange')}
            fact={waterHoldingCapacity1yChange}
            assessmentStatus={assessmentStatus}
            capsuleIconProps={{
              icon: getNCChangeIcon(waterHoldingCapacity1yChange),
              variant: getNCChangeIconVariant(waterHoldingCapacity1yChange),
            }}
            infoPopoverProps={{
              title: t('shared.ncaDetail.details.water.explainers.oneYearChange.title'),
              body: t('shared.ncaDetail.details.water.explainers.oneYearChange.body', {
                date: whc1yChangeInitialMeasuredAt,
                previousDate: whc1yChangePreviousMeasuredAt,
              }),
              popoverTriggerProps: {
                'data-testid': 'whc-since-previous-analysis-tooltip',
              },
            }}
          />
        )}
      </Stack>
    </div>
  );
};

const SoilMoisture = () => {
  const { t } = useTranslation();

  const latestAnalysisTranslation = t('shared.ncaDetail.details.labels.latestAnalysis');

  const plotId = usePlotId();
  const { analysisType } = useNCCardContext();

  const { getFact } = usePlotReportForPlot({ plotId });
  const soilMoistureLatestAnalysis = getFact(
    analysisType === 'total'
      ? R1FactType.r1_soil_moisture_latest_analysis_total
      : R1FactType.r1_soil_moisture_latest_analysis_per_ha,
  );

  const { assessmentStatus, isAssessmentPending } = useAssessmentStatus();

  const lastMeasuredDate = printLatestDate([soilMoistureLatestAnalysis?.measured_at]);

  return (
    <div className='flex flex-col gap-8 lg:gap-4'>
      <NCCardSectionHeader
        title={t('shared.ncaDetail.details.water.labels.soilMoisture')}
        supplementaryInfo={!isAssessmentPending && `${latestAnalysisTranslation} ${lastMeasuredDate}`}
        popover={{
          title: t('shared.ncaDetail.details.water.explainers.soilMoisture.title'),
          body: t('shared.ncaDetail.details.water.explainers.soilMoisture.body'),
        }}
      />
      <NCFactCapsule
        label={latestAnalysisTranslation}
        fact={soilMoistureLatestAnalysis}
        assessmentStatus={assessmentStatus}
        capsuleIconProps={{
          icon: getNCChangeIcon(soilMoistureLatestAnalysis),
          variant: getNCChangeIconVariant(soilMoistureLatestAnalysis),
        }}
        infoPopoverProps={{
          title: t('shared.ncaDetail.details.explainers.latestAnalysis.title'),
          body: t('shared.ncaDetail.details.explainers.latestAnalysis.body', {
            date: lastMeasuredDate,
          }),
        }}
      />
    </div>
  );
};

const MoistureTrendChange = () => {
  const { t } = useTranslation();

  const plotId = usePlotId();
  const { getFact } = usePlotReportForPlot({ plotId });

  const soilMoistureTrendChange = getFact(R1FactType.r1_soil_moisture_trend_change);
  const precipitationTrendChange = getFact(R1FactType.r1_precipitation_trend_change);

  const { assessmentStatus, isAssessmentPending } = useAssessmentStatus();

  const lastMeasuredDate = printLatestDate([
    soilMoistureTrendChange?.measured_at,
    precipitationTrendChange?.measured_at,
  ]);

  return (
    <div className='flex flex-col gap-8 lg:gap-4'>
      <NCCardSectionHeader
        // TODO: MVP-3284 delete shared.ncaDetail.details.water.labels.droughtRisk
        title={t('shared.ncaDetail.details.water.labels.changeInPrecipitationAndSoilMoisture')}
        supplementaryInfo={
          !isAssessmentPending && `${t('shared.ncaDetail.details.labels.latestEstimates')} ${lastMeasuredDate}`
        }
        popover={{
          title: t('shared.ncaDetail.details.water.explainers.droughtRisk.title'),
          body: t('shared.ncaDetail.details.water.explainers.droughtRisk.body', {
            currentMonth: printShortMonth(Date.now()),
          }),
        }}
      />
      <Stack direction='row' spacing={20} className='flex-wrap'>
        <NCFactCapsule
          label={t('shared.ncaDetail.details.water.labels.soilMoisture')}
          fact={soilMoistureTrendChange}
          assessmentStatus={assessmentStatus}
          capsuleIconProps={{
            icon: getNCChangeIcon(soilMoistureTrendChange),
            variant: getNCChangeIconVariant(soilMoistureTrendChange),
          }}
          infoPopoverProps={{
            title: t('shared.ncaDetail.details.water.explainers.soilMoisturePercentageChange.title'),
            body: t('shared.ncaDetail.details.water.explainers.soilMoisturePercentageChange.body', {
              currentMonth: printShortMonth(Date.now()),
            }),
          }}
        />
        <NCFactCapsule
          label={t('shared.ncaDetail.details.water.labels.precipitation')}
          fact={precipitationTrendChange}
          assessmentStatus={assessmentStatus}
          capsuleIconProps={{
            icon: getNCChangeIcon(precipitationTrendChange),
            variant: getNCChangeIconVariant(precipitationTrendChange),
          }}
          infoPopoverProps={{
            title: t('shared.ncaDetail.details.water.explainers.precipitationPercentageChange.title'),
            body: t('shared.ncaDetail.details.water.explainers.precipitationPercentageChange.body', {
              currentMonth: printShortMonth(Date.now()),
            }),
          }}
        />
      </Stack>
    </div>
  );
};
