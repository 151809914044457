import { useTranslation } from 'react-i18next';
import { Stack } from 'tw-component-library';

import { ProjectStatus } from '@/api/rest/resources/types/project';
import {
  BiodiversityZoneChart,
  BiodiversityZoneChartData,
  CalculationInProgress,
  NoChartData,
} from '@/components/ChartsV1_2';
import { ChartHeading } from '@/components/ChartsV1_2/components/ChartHeading';
import { useNCData } from '@/pages/shared/hooks/useNCData';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';

export const BiodiversityCardGraphTile = () => {
  const { t } = useTranslation();
  const project = useProjectDetailById().data;
  const biodiversityZonePercentHistoricGraph = useNCData<BiodiversityZoneChartData[]>(
    'biodiversityZonePercentHistoricGraph',
  )?.value;

  const noChartData = !biodiversityZonePercentHistoricGraph?.length;

  return (
    <Stack center data-testid='biodiversity-graph-tile'>
      {(() => {
        if (noChartData) {
          if ([ProjectStatus.initial, ProjectStatus.in_preparation].includes(project.status)) {
            return <NoChartData variant='analysisPending' />;
          }

          if (project.status === ProjectStatus.scheduled_for_analysis) {
            return <CalculationInProgress />;
          }

          return <NoChartData />;
        }

        return (
          <Stack spacing={2.5} className='w-full items-center justify-center p-6 lg:items-start lg:p-8 lg:pl-2'>
            <ChartHeading
              heading={t('shared.ncaDetail.details.biodiversity.labels.historicalGraphHeadline')}
              popover={{
                title: t('shared.ncaDetail.details.biodiversity.historicalData.title'),
                body: t('shared.ncaDetail.details.biodiversity.historicalData.body'),
              }}
            />
            <BiodiversityZoneChart
              data={biodiversityZonePercentHistoricGraph}
              height={233}
              showTooltip={true}
              className='relative'
              data-testid='biodiversity-zone-chart'
            />
          </Stack>
        );
      })()}
    </Stack>
  );
};
