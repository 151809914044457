// TODO: (MVP-1132): these types should be generated automatically based on the API's Swagger documentation

export enum FactType {
  area = 'area',
  temperature = 'temperature',
  temperature_timeseries = 'temperature_timeseries',
  precipitation = 'precipitation',
  average_slope = 'average_slope',
  prevailing_climate = 'prevailing_climate',
  prevailing_landtype = 'prevailing_landtype',
  average_soil_ph = 'average_soil_ph',
  soil_drainage = 'soil_drainage',
  soil_organic_carbon = 'soil_organic_carbon',
  soil_organic_matter = 'soil_organic_matter',
  soil_texture = 'soil_texture',
  stored_carbon = 'stored_carbon',
  total_exported_biomass = 'total_exported_biomass',
  clay = 'clay',
  sand = 'sand',
  silt = 'silt',
  erosion = 'erosion',
  humus_built = 'humus_built',
  humus_stored = 'humus_stored',
  soil_eroded = 'soil_eroded',
  soil_net_change = 'soil_net_change',
  country_name = 'country_name',
  country_iso2 = 'country_iso2',
  country_iso3 = 'country_iso3',
  fc = 'fc',
  awc = 'awc',
  pwp = 'pwp',
  fc_area = 'fc_area',
  awc_area = 'awc_area',
  pwp_area = 'pwp_area',
  soil_moisture_decade = 'soil_moisture_decade',
  average_soil_moisture = 'average_soil_moisture',
  below_wilting_point = 'below_wilting_point',
  water_storing_capacity = 'water_storing_capacity',
  water_net_change = 'water_net_change',
  grassland_type = 'grassland_type',
  grassland_converted_to_permanent_in_last_20y = 'grassland_converted_to_permanent_in_last_20y',
  grassland_num_of_mechanical_events_per_year = 'grassland_num_of_mechanical_events_per_year',
  grassland_organic_fertilizer_type = 'grassland_organic_fertilizer_type',
  carbon_captured = 'carbon_captured',
  carbon_change = 'carbon_change',
  co2_emitted = 'co2_emitted',
  permanent_soil_carbon = 'permanent_soil_carbon',
  carbon_net_change = 'carbon_net_change',
  biodiversity_trend = 'biodiversity_trend',
  biodiversity_tbd = 'biodiversity_tbd',
  habitat_score = 'habitat_score',
  biodiversity_net_change = 'biodiversity_net_change',
}

// TODO: MVP-3284 clean out unused after checking with BE
export enum R1FactType {
  r1_biodiversity_zone_percent = 'r1_biodiversity_zone_percent',
  r1_biodiversity_zone_percent_historic_graph = 'r1_biodiversity_zone_percent_historic_graph',
  r1_biodiversity_latest_analysis = 'r1_biodiversity_latest_analysis',
  r1_deforestation_occurred_since_2020 = 'r1_deforestation_occurred_since_2020',
  r1_habitat_diversity_latest_analysis = 'r1_habitat_diversity_latest_analysis',
  r1_biodiversity_buffer_zone_pct_previous_1y_uplift_change = 'r1_biodiversity_buffer_zone_pct_previous_1y_uplift_change',
  r1_biodiversity_buffer_zone_pct_previous_1y_uplift_pct_change = 'r1_biodiversity_buffer_zone_pct_previous_1y_uplift_pct_change',
  r1_biodiversity_buffer_zone_per_ha = 'r1_biodiversity_buffer_zone_per_ha',
  r1_biodiversity_buffer_zone_per_ha_previous_1y_uplift_change = 'r1_biodiversity_buffer_zone_per_ha_previous_1y_uplift_change',
  r1_biodiversity_buffer_zone_per_ha_previous_1y_uplift_pct_change = 'r1_biodiversity_buffer_zone_per_ha_previous_1y_uplift_pct_change',

  r1_carbon_storage_bg_total = 'r1_carbon_storage_bg_total',
  r1_carbon_storage_bg_per_ha = 'r1_carbon_storage_bg_per_ha',
  r1_carbon_bg_previous_1y_uplift_total = 'r1_carbon_bg_previous_1y_uplift_total',
  r1_carbon_bg_previous_1y_uplift_per_ha = 'r1_carbon_bg_previous_1y_uplift_per_ha',
  r1_carbon_bg_previous_1y_uplift_total_pct_change = 'r1_carbon_bg_previous_1y_uplift_total_pct_change',
  r1_carbon_bg_previous_1y_uplift_per_ha_pct_change = 'r1_carbon_bg_previous_1y_uplift_per_ha_pct_change',
  r1_carbon_bg_uplift_potential_graph = 'r1_carbon_bg_uplift_potential_graph',
  r1_carbon_bg_uplift_potential_per_ha_graph = 'r1_carbon_bg_uplift_potential_per_ha_graph',
  r1_soil_carbon_historic_total_graph = 'r1_soil_carbon_historic_total_graph',
  r1_soil_carbon_historic_per_ha_graph = 'r1_soil_carbon_historic_per_ha_graph',
  r1_carbon_bg_latest_analysis_total = 'r1_carbon_bg_latest_analysis_total',
  r1_carbon_bg_latest_analysis_per_ha = 'r1_carbon_bg_latest_analysis_per_ha',
  r1_ghg_emissions_per_year_latest_analysis_total = 'r1_ghg_emissions_per_year_latest_analysis_total',
  r1_ghg_emissions_per_year_latest_analysis_per_ha = 'r1_ghg_emissions_per_year_latest_analysis_per_ha',
  r1_carbon_bg_1year_uplift_potential_total = 'r1_carbon_bg_1year_uplift_potential_total',
  r1_carbon_bg_1year_uplift_potential_per_ha = 'r1_carbon_bg_1year_uplift_potential_per_ha',
  r1_carbon_bg_1year_uplift_increase_total = 'r1_carbon_bg_1year_uplift_increase_total',
  r1_carbon_bg_1year_uplift_increase_per_ha = 'r1_carbon_bg_1year_uplift_increase_per_ha',
  r1_carbon_bg_10year_uplift_potential_total = 'r1_carbon_bg_10year_uplift_potential_total',
  r1_carbon_bg_10year_uplift_potential_per_ha = 'r1_carbon_bg_10year_uplift_potential_per_ha',
  r1_carbon_bg_10year_uplift_increase_total = 'r1_carbon_bg_10year_uplift_increase_total',
  r1_carbon_bg_10year_uplift_increase_per_ha = 'r1_carbon_bg_10year_uplift_increase_per_ha',
  r1_carbon_storage_bg_co2_equivalent_total = 'r1_carbon_storage_bg_co2_equivalent_total',
  r1_carbon_storage_bg_co2_equivalent_per_ha = 'r1_carbon_storage_bg_co2_equivalent_per_ha',
  r1_carbon_bg_1year_uplift_potential_total_change = 'r1_carbon_bg_1year_uplift_potential_total_change',
  r1_carbon_bg_1year_uplift_potential_per_ha_change = 'r1_carbon_bg_1year_uplift_potential_per_ha_change',
  r1_carbon_bg_1year_uplift_potential_total_pct_change = 'r1_carbon_bg_1year_uplift_potential_total_pct_change',
  r1_carbon_bg_1year_uplift_potential_per_ha_pct_change = 'r1_carbon_bg_1year_uplift_potential_per_ha_pct_change',
  r1_carbon_bg_10year_uplift_potential_total_change = 'r1_carbon_bg_10year_uplift_potential_total_change',
  r1_carbon_bg_10year_uplift_potential_per_ha_change = 'r1_carbon_bg_10year_uplift_potential_per_ha_change',
  r1_carbon_bg_10year_uplift_potential_total_pct_change = 'r1_carbon_bg_10year_uplift_potential_total_pct_change',
  r1_carbon_bg_10year_uplift_potential_per_ha_pct_change = 'r1_carbon_bg_10year_uplift_potential_per_ha_pct_change',

  r1_water_holding_capacity_total = 'r1_water_holding_capacity_total',
  r1_water_holding_capacity_per_ha = 'r1_water_holding_capacity_per_ha',
  r1_historic_water_and_soil_moisture_graph = 'r1_historic_water_and_soil_moisture_graph',
  r1_historic_water_and_soil_moisture_per_ha_graph = 'r1_historic_water_and_soil_moisture_per_ha_graph',
  r1_water_holding_capacity_latest_analysis_total = 'r1_water_holding_capacity_latest_analysis_total',
  r1_water_holding_capacity_latest_analysis_per_ha = 'r1_water_holding_capacity_latest_analysis_per_ha',
  r1_water_holding_capacity_increase_since_prev_analysis_total = 'r1_water_holding_capacity_increase_since_prev_analysis_total',
  r1_water_holding_capacity_increase_since_prev_analysis_per_ha = 'r1_water_holding_capacity_increase_since_prev_analysis_per_ha',
  r1_precipitation_trend_change = 'r1_precipitation_trend_change',
  r1_soil_moisture_latest_analysis_total = 'r1_soil_moisture_latest_analysis_total',
  r1_soil_moisture_latest_analysis_per_ha = 'r1_soil_moisture_latest_analysis_per_ha',
  r1_soil_moisture_trend_change = 'r1_soil_moisture_trend_change',
  r1_water_risk_latest_estimate = 'r1_water_risk_latest_estimate',
  r1_water_holding_capacity_10year_uplift_potential_graph = 'r1_water_holding_capacity_10year_uplift_potential_graph',
  r1_water_holding_capacity_10year_uplift_potential_per_ha_graph = 'r1_water_holding_capacity_10year_uplift_potential_per_ha_graph',
  r1_water_holding_capacity_1year_uplift_potential_total = 'r1_water_holding_capacity_1year_uplift_potential_total',
  r1_water_holding_capacity_1year_uplift_potential_per_ha = 'r1_water_holding_capacity_1year_uplift_potential_per_ha',
  r1_water_holding_capacity_1year_uplift_increase_total = 'r1_water_holding_capacity_1year_uplift_increase_total',
  r1_water_holding_capacity_1year_uplift_increase_per_ha = 'r1_water_holding_capacity_1year_uplift_increase_per_ha',
  r1_water_holding_capacity_10year_uplift_potential_total = 'r1_water_holding_capacity_10year_uplift_potential_total',
  r1_water_holding_capacity_10year_uplift_potential_per_ha = 'r1_water_holding_capacity_10year_uplift_potential_per_ha',
  r1_water_holding_capacity_10year_uplift_increase_total = 'r1_water_holding_capacity_10year_uplift_increase_total',
  r1_water_holding_capacity_10year_uplift_increase_per_ha = 'r1_water_holding_capacity_10year_uplift_increase_per_ha',
  r1_water_whc_increase_1y_uplift_potential_total_change = 'r1_water_whc_increase_1y_uplift_potential_total_change',
  r1_water_whc_increase_1y_uplift_potential_per_ha_change = 'r1_water_whc_increase_1y_uplift_potential_per_ha_change',
  r1_water_whc_increase_1y_uplift_potential_total_pct_change = 'r1_water_whc_increase_1y_uplift_potential_total_pct_change',
  r1_water_whc_increase_1y_uplift_potential_per_ha_pct_change = 'r1_water_whc_increase_1y_uplift_potential_per_ha_pct_change',
  r1_water_whc_increase_10y_uplift_potential_total_change = 'r1_water_whc_increase_10y_uplift_potential_total_change',
  r1_water_whc_increase_10y_uplift_potential_per_ha_change = 'r1_water_whc_increase_10y_uplift_potential_per_ha_change',
  r1_water_whc_increase_10y_uplift_potential_total_pct_change = 'r1_water_whc_increase_10y_uplift_potential_total_pct_change',
  r1_water_whc_increase_10y_uplift_potential_per_ha_pct_change = 'r1_water_whc_increase_10y_uplift_potential_per_ha_pct_change',
  r1_whc_previous_1y_uplift_total = 'r1_whc_previous_1y_uplift_total',
  r1_whc_previous_1y_uplift_per_ha = 'r1_whc_previous_1y_uplift_per_ha',
  r1_water_whc_increase_previous_1y_uplift_total_pct_change = 'r1_water_whc_increase_previous_1y_uplift_total_pct_change',
  r1_water_whc_increase_previous_1y_uplift_per_ha_pct_change = 'r1_water_whc_increase_previous_1y_uplift_per_ha_pct_change',
}

export const POTENTIAL_FACT_TYPES = [
  R1FactType.r1_carbon_bg_1year_uplift_potential_total,
  R1FactType.r1_carbon_bg_1year_uplift_potential_per_ha,
  R1FactType.r1_carbon_bg_1year_uplift_potential_total_change,
  R1FactType.r1_carbon_bg_1year_uplift_potential_per_ha_change,
  R1FactType.r1_carbon_bg_10year_uplift_potential_total,
  R1FactType.r1_carbon_bg_10year_uplift_potential_per_ha,
  R1FactType.r1_carbon_bg_10year_uplift_potential_total_change,
  R1FactType.r1_carbon_bg_10year_uplift_potential_per_ha_change,

  R1FactType.r1_water_holding_capacity_1year_uplift_potential_total,
  R1FactType.r1_water_holding_capacity_1year_uplift_potential_per_ha,
  R1FactType.r1_water_whc_increase_1y_uplift_potential_total_change,
  R1FactType.r1_water_whc_increase_1y_uplift_potential_per_ha_change,
  R1FactType.r1_water_holding_capacity_10year_uplift_potential_total,
  R1FactType.r1_water_holding_capacity_10year_uplift_potential_per_ha,
  R1FactType.r1_water_whc_increase_10y_uplift_potential_total_change,
  R1FactType.r1_water_whc_increase_10y_uplift_potential_per_ha_change,
];

export const SIGNED_FACT_TYPES = [
  R1FactType.r1_biodiversity_buffer_zone_pct_previous_1y_uplift_change,
  R1FactType.r1_biodiversity_buffer_zone_pct_previous_1y_uplift_pct_change,
  R1FactType.r1_biodiversity_buffer_zone_per_ha_previous_1y_uplift_change,
  R1FactType.r1_biodiversity_buffer_zone_per_ha_previous_1y_uplift_pct_change,

  R1FactType.r1_carbon_bg_previous_1y_uplift_total,
  R1FactType.r1_carbon_bg_previous_1y_uplift_per_ha,
  R1FactType.r1_carbon_bg_previous_1y_uplift_total_pct_change,
  R1FactType.r1_carbon_bg_previous_1y_uplift_per_ha_pct_change,
  R1FactType.r1_carbon_bg_1year_uplift_potential_total_change,
  R1FactType.r1_carbon_bg_1year_uplift_potential_per_ha_change,
  R1FactType.r1_carbon_bg_1year_uplift_potential_total_pct_change,
  R1FactType.r1_carbon_bg_1year_uplift_potential_per_ha_pct_change,
  R1FactType.r1_carbon_bg_10year_uplift_potential_total_change,
  R1FactType.r1_carbon_bg_10year_uplift_potential_per_ha_change,
  R1FactType.r1_carbon_bg_10year_uplift_potential_total_pct_change,
  R1FactType.r1_carbon_bg_10year_uplift_potential_per_ha_pct_change,

  R1FactType.r1_soil_moisture_trend_change,
  R1FactType.r1_precipitation_trend_change,
  R1FactType.r1_whc_previous_1y_uplift_total,
  R1FactType.r1_whc_previous_1y_uplift_per_ha,
  R1FactType.r1_water_holding_capacity_increase_since_prev_analysis_total,
  R1FactType.r1_water_holding_capacity_increase_since_prev_analysis_per_ha,
  R1FactType.r1_water_whc_increase_previous_1y_uplift_total_pct_change,
  R1FactType.r1_water_whc_increase_previous_1y_uplift_per_ha_pct_change,
  R1FactType.r1_water_whc_increase_1y_uplift_potential_total_change,
  R1FactType.r1_water_whc_increase_1y_uplift_potential_per_ha_change,
  R1FactType.r1_water_whc_increase_1y_uplift_potential_total_pct_change,
  R1FactType.r1_water_whc_increase_1y_uplift_potential_per_ha_pct_change,
  R1FactType.r1_water_whc_increase_10y_uplift_potential_total_change,
  R1FactType.r1_water_whc_increase_10y_uplift_potential_per_ha_change,
  R1FactType.r1_water_whc_increase_10y_uplift_potential_total_pct_change,
  R1FactType.r1_water_whc_increase_10y_uplift_potential_per_ha_pct_change,
];

export enum FactValidationStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  UNVALIDATED = 'UNVALIDATED',
  VALIDATED = 'VALIDATED',
  MODEL_LIMITATION = 'MODEL_LIMITATION',
}

// TODO: MVP-3284 remove and import from units.ts instead
export enum UnitEnum {
  none = 'none',
  unknown = 'unknown',
  '%' = '%',
  g = 'g',
  kg = 'kg',
  t = 't',
  mm = 'mm',
  m = 'm',
  km = 'km',
  'm^2' = 'm^2',
  ha = 'ha',
  'm^3' = 'm^3',
  l = 'l',
  pH = 'pH',
  'year^-1' = 'year^-1',
  'g/kg' = 'g/kg',
  'kg/ha' = 'kg/ha',
  't/ha' = 't/ha',
  'm^3/ha' = 'm^3/ha',
  '100*m^3/m^3' = '100*m^3/m^3',
  '°C' = '°C',
  'm^3/m^3/a' = 'm^3/m^3/a',
  habitat_score = 'habitat_score',
  bool = 'bool',
  enum = 'enum',
  decade = 'decade',
  geojson = 'geojson',
  timeseries = 'timeseries',
}

export type Fact = {
  id: string;
  assessment: string;
  name: FactType;
  value: number | string | null;
  unit: UnitEnum;
  status: FactValidationStatus;
  created_at: string;
};

export type FactDashboard = {
  name: FactType;
  value: number | string | null;
  unit: UnitEnum;
  status: FactValidationStatus;
};

export type FactNested = {
  id: string;
  name: FactType;
  value: number | string | null;
  unit: UnitEnum;
  status: FactValidationStatus;
  created_at: string;
};

export type R1Fact<T = number | string | null | unknown> = {
  name: R1FactType;
  value: T;
  unit: UnitEnum;
  status: FactValidationStatus;
  measured_at: string | null;
  previous_1y_measured_at: string | null;
};
