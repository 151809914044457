import { useQueryClient } from '@tanstack/react-query';

import { usePatchPlotQuestionnaire, usePostPlot } from '@/api/rest/resources/plot';
import { PlotStatusEnum, PlotType, PostPlotRequestBodyData } from '@/api/rest/resources/types/plot';
import { useProject } from '@/pages/landsteward/hooks/useProject';
import { UnexpectedMissingDataError } from '@/utils/errors/UnexpectedMissingDataError';

import { buildQuestionnaireBody } from '../../../buildQuestionnaireBody';
import { PlotInputs } from '../../../types';
import { useDefaultPlotName } from './useDefaultPlotName';

export const useCreatePlot = () => {
  const queryClient = useQueryClient();

  const { data: project } = useProject();

  const postPlotMutation = usePostPlot();
  const patchPlotQuestionnaireMutation = usePatchPlotQuestionnaire();

  const { setDefaultPlotNameCounter } = useDefaultPlotName();

  const submit = async (inputs: PlotInputs, options?: { draft?: boolean }) => {
    const { plotType, name, polygon } = inputs;

    if (!plotType || !name || !polygon || !project) {
      throw new UnexpectedMissingDataError({ dataLabels: { plotType, name, polygon, project } });
    }

    if (plotType === PlotType.CROPLAND && !inputs.crops) {
      throw new UnexpectedMissingDataError({ dataLabels: { crops: inputs.crops } });
    }

    const questionnaireBody = buildQuestionnaireBody(inputs);

    let plotId = postPlotMutation.data?.id;

    /**
     * In case postPlotMutation succeeds but patchPlotQuestionnaireMutation
     * fails, we make sure that any subsequent submit attempt only triggers
     * patchPlotQuestionnaireMutation with the existing plot ID.
     */
    if (!plotId) {
      const bodyData = {
        name,
        type: plotType,
        polygon,
        project: project?.id,
        organization: project.organization_id,
        status: options?.draft ? PlotStatusEnum.draft : PlotStatusEnum.ready_to_analyse,
      } as PostPlotRequestBodyData;

      if (plotType === PlotType.CROPLAND && inputs.crops) {
        bodyData.crops = inputs.crops;
      }

      const { id } = await postPlotMutation.mutateAsync({ bodyData });

      plotId = id;
    }

    await patchPlotQuestionnaireMutation.mutateAsync({
      pathVariables: { id: plotId },
      bodyData: { json: questionnaireBody },
    });

    queryClient.resetQueries({ queryKey: ['land_steward'] });
    setDefaultPlotNameCounter((c) => c + 1);
  };

  const isSubmitting = postPlotMutation.isPending || patchPlotQuestionnaireMutation.isPending;

  return { submit, isSubmitting };
};
