import { useQueryClient } from '@tanstack/react-query';

import { usePostProject } from '@/api/rest/resources/project';
import { PostProjectRequestBodyData } from '@/api/rest/resources/types/project';
import { useUser } from '@/lib/auth';

export type CreateProjectInputs = Pick<
  PostProjectRequestBodyData,
  'name' | 'location_description' | 'landtypes_allowed'
>;

export const useCreateProject = () => {
  const user = useUser();

  const queryClient = useQueryClient();
  const postProjectMutation = usePostProject();

  const submit = async (inputs: CreateProjectInputs) => {
    const res = await postProjectMutation.mutateAsync({
      bodyData: {
        ...inputs,
        organization_id: user.membership.organization.id,
      },
    });

    queryClient.resetQueries({ queryKey: ['land_steward', 'project'] });

    return res;
  };

  const isSubmitting = postProjectMutation.isPending;

  return { submit, isSubmitting };
};
