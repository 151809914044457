import { useTranslation } from 'react-i18next';
import { Stack } from 'tw-component-library';

import { ProjectStatus } from '@/api/rest/resources/types/project';
import { CalculationInProgress, NoChartData, useNCCardContext } from '@/components';
import { CarbonStorageBGChart, CarbonStorageBGChartData } from '@/components/Charts/CarbonStorageBG';
import { useNCData } from '@/pages/shared/hooks/useNCData';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';

export const CarbonCardGraphTile = () => {
  const { t } = useTranslation();
  const { analysisType } = useNCCardContext();

  const project = useProjectDetailById().data;
  const carbonBgUpliftPotential = useNCData<CarbonStorageBGChartData[]>(
    analysisType === 'total' ? 'carbonBgUpliftPotentialGraph' : 'carbonBgUpliftPotentialPerHaGraph',
  );

  const chartData = carbonBgUpliftPotential?.value;
  const noChartData = !chartData?.length;

  return (
    <Stack center data-testid='carbon-graph-tile'>
      {(() => {
        if (noChartData) {
          if ([ProjectStatus.initial, ProjectStatus.in_preparation].includes(project.status)) {
            return <NoChartData variant='analysisPending' />;
          }

          if (project.status === ProjectStatus.scheduled_for_analysis) {
            return <CalculationInProgress />;
          }

          return <NoChartData />;
        }

        return (
          <Stack spacing={2.5} className='w-full items-center justify-center p-6 lg:items-start lg:p-8 lg:pl-2'>
            <Stack direction='row' spacing={2} className='items-center'>
              <span className='typography-body1'>
                {t('shared.ncaDetail.details.carbon.labels.upliftGraphHeadline')}
              </span>
            </Stack>
            <CarbonStorageBGChart
              analysisType={analysisType}
              data={chartData}
              showTooltip={true}
              color='#CCC'
              className='relative'
              data-testid='carbon-storage-bg-chart'
            />
          </Stack>
        );
      })()}
    </Stack>
  );
};
