import { cn, theme } from '@landler/tw-component-library';
import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Area, AreaChart, Label, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { UnitEnum } from '@/api/rest/resources/types/fact';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { Logger } from '@/lib/logs/logger';
import { formatUnit } from '@/utils/formatting';
import { printMonthYear } from '@/utils/formatting/date';

import { ChartXTick, ChartYTick } from './components/Axis';
import { ChartLegendBar, ChartLegendBarItem } from './components/LegendBar';
import {
  ChartTooltip,
  ChartTooltipDot,
  ChartTooltipTextPrimary,
  ChartTooltipTextSecondary,
} from './components/Tooltip';
import { NoChartData } from './NoChartData';
import { DefaultChartProps } from './types';

const chartColors = {
  gradient: ['#5D9900', '#558707'],
  stroke: '#76AC23',
} as const;

const axisStrokeColor = (theme.colors as Record<string, string> | undefined)?.['neutral-black-12'];

export type BiodiversityZoneChartData = {
  date: string;
  name: 'biodiversity_percent';
  value: number;
  unit: '%';
};

type BiodiversityZoneChartProps = DefaultChartProps & {
  /** The chart data */
  data: BiodiversityZoneChartData[];
};

const unit = formatUnit(UnitEnum['%']);

export const BiodiversityZoneChart: FC<HTMLAttributes<HTMLDivElement> & BiodiversityZoneChartProps> = ({
  data,
  height = 400,
  width = '100%',
  showTooltip = true,
  className,
  children,
  ...delegated
}) => {
  const styles = cn('flex w-full flex-col', className);

  const chartData = data.map((d) => ({
    label: new Date(d.date).getTime(),
    value: d.value,
    projectArea: 100, // adding this to have the chart background filled using a non-interactive <Area />
  }));

  const firstTick = chartData.at(0);
  const lastTick = chartData.at(-1);

  if (!firstTick || !lastTick) {
    Logger.error('Not enough data to render chart');
    return (
      <div className={cn(styles, 'h-full justify-center')} {...delegated}>
        <NoChartData />
      </div>
    );
  }

  return (
    <div className={styles} {...delegated}>
      <ResponsiveContainer width={width} height={height}>
        <AreaChart
          data={chartData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id='biodiversityZone' x1='0' y1='1' x2='0' y2='0'>
              <stop offset='4.32%' stopColor={chartColors.gradient[0]} stopOpacity={0} />
              <stop offset='128.14%' stopColor={chartColors.gradient[1]} stopOpacity={0.3} />
            </linearGradient>
          </defs>
          <Area
            isAnimationActive={false}
            type='monotone'
            dataKey='value'
            stroke={chartColors.stroke}
            strokeWidth={1}
            fill='url(#biodiversityZone)'
            fillOpacity={1}
            activeDot={(props) => ChartTooltipDot(props)}
          />
          <XAxis
            dataKey='label'
            stroke={axisStrokeColor}
            tickLine={false}
            tick={<ChartXTick formatter={printMonthYear} />}
            padding={{ right: 31 }}
            scale='time'
            type='number'
            domain={['dataMin', 'dataMax']}
            tickFormatter={printMonthYear}
          />
          <YAxis
            dataKey='value'
            stroke={axisStrokeColor}
            tickLine={false}
            type='number'
            scale='sequential'
            domain={[0, 100]}
            tick={<ChartYTick />}
            padding={{ top: 25 }}
            width={40}
          >
            <Label
              className='typography-caption text-text-secondary'
              value={formatUnit(UnitEnum['%'])}
              position={{ x: 32, y: 10 }}
            />
          </YAxis>
          {showTooltip && (
            <Tooltip isAnimationActive={false} cursor={false} content={(content) => <CustomTooltip {...content} />} />
          )}
        </AreaChart>
      </ResponsiveContainer>
      <CustomLegendBar />
      {children}
    </div>
  );
};

const CustomLegendBar = () => {
  const { t } = useTranslation();

  const legendBarItems: ChartLegendBarItem[] = [
    {
      label: t('global.analysis.biodiversityZone'),
      color: chartColors.gradient[0],
    },
  ];

  return (
    <ChartLegendBar items={legendBarItems} className='ml-[28px] mt-8' data-testid='biodiversity-zone-chart-legend' />
  );
};

const CustomTooltip = ({ active, payload, label }: TooltipProps<ValueType, NameType>) => {
  const displayValue = useDisplayNumber((payload?.[0]?.value as string) ?? 0);
  if (!active) return null;

  return (
    <ChartTooltip>
      <ChartTooltipTextPrimary>{`${displayValue}${unit}`}</ChartTooltipTextPrimary>
      <ChartTooltipTextSecondary>{printMonthYear(label)}</ChartTooltipTextSecondary>
    </ChartTooltip>
  );
};
