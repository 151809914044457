import { cn } from '@landler/tw-component-library';
import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Area, AreaChart, Label, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { UnitEnum } from '@/api/rest/resources/types/fact';
import { Logger } from '@/lib/logs/logger';
import { formatUnit } from '@/utils/formatting';
import { printMonthYear } from '@/utils/formatting/date';

import { ChartXTick, ChartYTick } from './components/Axis';
import { ChartLegendBar, ChartLegendBarItem } from './components/LegendBar';
import { ChartTooltip, ChartTooltipDot } from './components/Tooltip';
import { NoChartData } from './NoChartData';
import { DefaultChartProps } from './types';

const chartColors = {
  projectArea: ['#CBD4CB', '#F2FFDC'],
  biodiversityZone: ['#456E06'],
} as const;

export type BiodiversityZoneChartData = {
  date: string;
  name: 'biodiversity_percent';
  value: number;
  unit: '%';
};

type BiodiversityZoneChartProps = DefaultChartProps & {
  /** The chart data */
  data: BiodiversityZoneChartData[];
};

export const BiodiversityZoneChart: FC<HTMLAttributes<HTMLDivElement> & BiodiversityZoneChartProps> = ({
  data,
  height = 400,
  width = '100%',
  showTooltip = true,
  className,
  children,
  ...delegated
}) => {
  const styles = cn('flex w-full flex-col', className);

  const chartData = data.map((d) => ({
    label: new Date(d.date).getTime(),
    value: d.value,
    projectArea: 100, // adding this to have the chart background filled using a non-interactive <Area />
  }));

  const firstTick = chartData.at(0);
  const lastTick = chartData.at(-1);

  if (!firstTick || !lastTick) {
    Logger.error('Not enough data to render chart');
    return (
      <div className={cn(styles, 'h-full justify-center')} {...delegated}>
        <NoChartData />
      </div>
    );
  }

  return (
    <div className={styles} {...delegated}>
      <ResponsiveContainer width={width} height={height}>
        <AreaChart
          data={chartData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id='biodiversityZoneProjectArea' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='0%' stopColor={chartColors.projectArea[0]} stopOpacity={1} />
              <stop offset='100%' stopColor={chartColors.projectArea[1]} stopOpacity={0.8} />
            </linearGradient>
            <linearGradient id='biodiversityZone' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='0%' stopColor={chartColors.biodiversityZone[0]} stopOpacity={0.6} />
              <stop offset='100%' stopColor={chartColors.biodiversityZone[0]} stopOpacity={0.3} />
            </linearGradient>
          </defs>
          <Area
            isAnimationActive={false}
            type='monotone'
            dataKey='projectArea'
            stroke='none'
            fill='url(#biodiversityZoneProjectArea)'
            fillOpacity={1}
            activeDot={false}
          />
          <Area
            isAnimationActive={false}
            type='monotone'
            dataKey='value'
            stroke='none'
            fill='url(#biodiversityZone)'
            fillOpacity={1}
            activeDot={(props) => ChartTooltipDot(props)}
          />
          <XAxis
            dataKey='label'
            axisLine={false}
            tickLine={false}
            tick={<ChartXTick formatter={printMonthYear} />}
            padding={{ right: 31 }}
            scale='time'
            type='number'
            domain={['dataMin', 'dataMax']}
            tickFormatter={printMonthYear}
          />
          <YAxis
            dataKey='value'
            axisLine={false}
            tickLine={false}
            type='number'
            scale='sequential'
            domain={[0, 100]}
            tick={<ChartYTick />}
            padding={{ top: 25 }}
            width={40}
          >
            <Label
              className='typography-caption text-text-secondary'
              value={formatUnit(UnitEnum['%'])}
              position={{ x: 32, y: 10 }}
            />
          </YAxis>
          {showTooltip && (
            <Tooltip
              isAnimationActive={false}
              cursor={false}
              content={(content) => {
                // remove artificial projectArea value from the tooltip, as we are only using it to display the background color of the chart
                content.payload?.forEach((p) => {
                  // eslint-disable-next-line no-param-reassign
                  delete p.payload?.projectArea;
                });
                return ChartTooltip(content, undefined, formatUnit(UnitEnum['%']), printMonthYear);
              }}
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
      <CustomLegendBar />
      {children}
    </div>
  );
};

const CustomLegendBar = () => {
  const { t } = useTranslation();

  const legendBarItems: ChartLegendBarItem[] = [
    {
      label: t('global.analysis.biodiversityZone'),
      color: chartColors.biodiversityZone[0],
    },
    {
      label: t('global.analysis.projectArea'),
      color: chartColors.projectArea[0],
    },
  ];

  return <ChartLegendBar items={legendBarItems} className='mt-4' data-testid='biodiversity-zone-chart-legend' />;
};
