import { Stack } from '@landler/tw-component-library';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { NCABalance } from '@/api/rest/resources/types/ncaBalance';
import { CapsuleTrend } from '@/components';
import { printLatestDate } from '@/components/NCCardV1_2/NCCard';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { useAssessmentStatus } from '@/pages/shared/hooks/useAssessmentStatus';
import { NCABalanceCapsule } from '@/pages/shared/projects/pages/v1_2/project/pages/nca/components/NCABalanceCapsule/NCABalanceCapsule';

import { NoData } from '../../../components/NoData';

export type NCABalanceTileProps = {
  openingValue?: NCABalance;
  latestValue?: NCABalance;
  latestValueTrend?: NCABalance;
  oneYearChange?: NCABalance;
};

export const NCABalanceTile: FC<NCABalanceTileProps> = ({
  openingValue,
  latestValue,
  latestValueTrend,
  oneYearChange,
}) => {
  const { t } = useTranslation();
  const openingValueMeasuredAt = printLatestDate([openingValue?.reference_date]);

  const lastestValueMeasuredAt = printLatestDate([latestValue?.reference_date]);
  const latestValueTrendDisplay = `${useDisplayNumber((latestValueTrend?.value as number) ?? 0, {
    signDisplay: 'always',
  })} %`;

  const oneYearChangeMeasuredAt = lastestValueMeasuredAt;
  const oneYearChangePreviouslyMeasuredAt = printLatestDate([oneYearChange?.reference_date]);

  const { assessmentStatus } = useAssessmentStatus();

  if (latestValue?.value === undefined && oneYearChange?.value === undefined && openingValue?.value === undefined) {
    return (
      <div className='rounded-3xl bg-neutral-black-4'>
        <NoData />
      </div>
    );
  }

  return (
    <Stack direction='col' className='flex-wrap gap-6' data-testid='nca-balance-tiles'>
      <div className='rounded-xl bg-neutral-black-4 p-6'>
        <NCABalanceCapsule
          label={t('shared.ncaDetail.details.labels.latestValue')}
          balance={latestValue}
          trend={
            latestValueTrend?.value != null && (
              <CapsuleTrend variant={(latestValueTrend.value as number) < 0 ? 'negative' : 'positive'}>
                {latestValueTrendDisplay}
              </CapsuleTrend>
            )
          }
          assessmentStatus={assessmentStatus}
          infoPopoverProps={{
            title: t('shared.ncaDetail.details.explainers.latestValue.title'),
            body: t('shared.ncaDetail.details.explainers.latestValue.body', {
              date: lastestValueMeasuredAt,
            }),
            popoverTriggerProps: {
              'data-testid': 'latest-value-tooltip',
            },
          }}
        />
      </div>
      <div className='flex flex-col gap-6 pl-6'>
        <NCABalanceCapsule
          label={t('shared.ncaDetail.details.labels.oneYearChange')}
          balance={oneYearChange}
          assessmentStatus={assessmentStatus}
          infoPopoverProps={{
            title: t('shared.ncaDetail.details.explainers.oneYearChange.title'),
            body: t('shared.ncaDetail.details.explainers.oneYearChange.body', {
              latestAnalysisDate: oneYearChangeMeasuredAt,
              previousAnalysisDate: oneYearChangePreviouslyMeasuredAt,
            }),
            popoverTriggerProps: {
              'data-testid': 'one-year-change-tooltip',
            },
          }}
        />
        <NCABalanceCapsule
          label={t('shared.ncaDetail.details.labels.openingValue')}
          balance={openingValue}
          assessmentStatus={assessmentStatus}
          infoPopoverProps={{
            title: t('shared.ncaDetail.details.explainers.openingValue.title'),
            body: t('shared.ncaDetail.details.explainers.openingValue.body', {
              date: openingValueMeasuredAt,
            }),
            popoverTriggerProps: {
              'data-testid': 'opening-value-tooltip',
            },
          }}
        />
      </div>
    </Stack>
  );
};
