import {
  cn,
  InfoPopoverProps,
  RiArrowRightDownLine,
  RiArrowRightLine,
  RiArrowRightUpLine,
  RiCompass4Line,
  RiFlashlightLine,
} from '@landler/tw-component-library';
import React, { HTMLAttributes, useMemo } from 'react';

import {
  FactValidationStatus,
  POTENTIAL_FACT_TYPES,
  R1Fact,
  R1FactType,
  SIGNED_FACT_TYPES,
} from '@/api/rest/resources/types/fact';
import { AssessmentStatus } from '@/api/rest/resources/types/project';
import { useFact } from '@/hooks/useFact';
import { useScreenSize } from '@/hooks/useScreenSize';

import {
  Capsule,
  CapsuleContent,
  CapsuleContentProps,
  CapsuleIcon,
  CapsuleIconProps,
  CapsuleLabel,
} from '../Capsule/Capsule';

export type NCFactCapsuleProps = HTMLAttributes<HTMLDivElement> & {
  capsuleIconProps?: CapsuleIconProps;
  label: React.ReactNode;
  assessmentStatus?: AssessmentStatus;
  fact?: R1Fact;
  infoPopoverProps?: InfoPopoverProps | null;
  trend?: CapsuleContentProps['trend'];
};

export const NCFactCapsule: React.FC<NCFactCapsuleProps> = ({
  capsuleIconProps,
  label,
  fact,
  trend,
  infoPopoverProps,
  assessmentStatus,
  className,
}) => {
  const isSmallScreen = useScreenSize() === 'small';
  const isReady = fact?.status === FactValidationStatus.VALIDATED || assessmentStatus !== AssessmentStatus.pending;

  const infoPopoverPropsDelegated = useMemo(() => {
    if (isSmallScreen) return null;

    return infoPopoverProps as InfoPopoverProps;
  }, [isSmallScreen, infoPopoverProps]);

  return (
    <Capsule
      label={<CapsuleLabel infoPopoverProps={infoPopoverPropsDelegated}>{label}</CapsuleLabel>}
      content={
        !isReady ? (
          <CapsuleContent className={cn('text-text-disabled', className)}>-</CapsuleContent>
        ) : (
          <ValueFromFact fact={fact} trend={trend} className={className} />
        )
      }
      thumbnail={<CapsuleIcon {...capsuleIconProps} />}
    />
  );
};

type ValueFromFactProps = HTMLAttributes<HTMLDivElement> & {
  fact?: R1Fact;
  trend?: CapsuleContentProps['trend'];
};

const ValueFromFact: React.FC<ValueFromFactProps> = ({ fact, trend, className }) => {
  const { value, display } = useFact(fact);

  if (value == null) {
    return <CapsuleContent className={cn('text-text-disabled', className)}>-</CapsuleContent>;
  }

  return (
    <CapsuleContent className={className} trend={trend}>
      {display}
    </CapsuleContent>
  );
};

/**
 * value      | change  |  signed fact
 * null           +            y         = right straight arrow
 * null           -            y         = right straight arrow
 * null          +/-           n         = compass
 *
 * exists         +            y         = right up arrow
 * exists         -            y         = right down arrow
 * exists        +/-           n         = compass
 *
 * GHG null      +/-          y/n        = thunderbolt
 * GHG exists    +/-          y/n        = thunderbolt
 *
 * @param fact
 * @returns Icon
 */
export const getNCChangeIcon = (fact: R1Fact | undefined) => {
  if (!fact) {
    return <RiCompass4Line />;
  }
  if (
    [
      R1FactType.r1_ghg_emissions_per_year_latest_analysis_total,
      R1FactType.r1_ghg_emissions_per_year_latest_analysis_per_ha,
    ].includes(fact.name)
  ) {
    return <RiFlashlightLine />;
  }

  if (!fact.value) {
    if ([...SIGNED_FACT_TYPES, ...POTENTIAL_FACT_TYPES].includes(fact.name)) return <RiArrowRightLine />;
    return <RiCompass4Line />;
  }

  if ([...SIGNED_FACT_TYPES, ...POTENTIAL_FACT_TYPES].includes(fact.name)) {
    if ((fact.value as number) === 0) return <RiArrowRightLine />;
    if ((fact.value as number) > 0) return <RiArrowRightUpLine />;
    return <RiArrowRightDownLine />;
  }

  return <RiCompass4Line />;
};

/**
 * @param fact
 * @returns variant?: 'default' | 'contained' | 'disabled';
 */
export const getNCChangeIconVariant = (fact: R1Fact | undefined) => {
  if (!fact || !fact.value) {
    return 'disabled';
  }

  if (POTENTIAL_FACT_TYPES.includes(fact.name)) {
    return 'contained';
  }

  return 'default';
};
