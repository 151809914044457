// TODO: MVP-3284 update path after fact units and balances unit are unified
import { UnitEnum } from '@/api/rest/resources/types/fact';

export const formatUnit = (unit?: UnitEnum) => {
  switch (unit) {
    case UnitEnum['m^3']:
      return 'm³';
    case UnitEnum['m^3/ha']:
      return 'm³/ha';
    case UnitEnum['m^2']:
      return 'm²';
    case UnitEnum['m^3/m^3/a']:
      return '';
    case UnitEnum[UnitEnum.none]:
      return '';
    default:
      return unit;
  }
};
