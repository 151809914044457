import { Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { R1FactType } from '@/api/rest/resources/types/fact';
import { CapsuleTrend } from '@/components';
import { NCCardSectionHeader, printLatestDate, useNCCardContext } from '@/components/NCCardV1_2/NCCard';
import { getNCChangeIcon, getNCChangeIconVariant, NCFactCapsule } from '@/components/NCFactCapsuleV1_2/NCFactCapsule';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { useAssessmentStatus } from '@/pages/shared/hooks/useAssessmentStatus';
import { usePlotId } from '@/pages/shared/hooks/usePlotId';
import { usePlotReportForPlot } from '@/pages/shared/hooks/usePlotReportForPlot';
import { formatKilogramsToTonnes } from '@/utils/formatting';

export const CarbonStatsTile = () => {
  const { t } = useTranslation();
  const plotId = usePlotId();
  const { analysisType } = useNCCardContext();

  const { assessmentStatus } = useAssessmentStatus();

  const { getFact } = usePlotReportForPlot({ plotId });

  const carbonBgLatestAnalysis = formatKilogramsToTonnes(
    getFact<number>(
      analysisType === 'total' ? R1FactType.r1_carbon_storage_bg_total : R1FactType.r1_carbon_storage_bg_per_ha,
    ),
  );

  const carbonBg1yChange = formatKilogramsToTonnes(
    getFact<number>(
      analysisType === 'total'
        ? R1FactType.r1_carbon_bg_previous_1y_uplift_total
        : R1FactType.r1_carbon_bg_previous_1y_uplift_per_ha,
    ),
  );

  const carbonStorageBgTrend = getFact<number>(
    analysisType === 'total'
      ? R1FactType.r1_carbon_bg_previous_1y_uplift_total_pct_change
      : R1FactType.r1_carbon_bg_previous_1y_uplift_per_ha_pct_change,
  );
  const carbonStorageBgTrendDisplay = `${useDisplayNumber(carbonStorageBgTrend?.value ?? 0, {
    signDisplay: 'always',
  })} %`;

  const lastMeasuredDate = printLatestDate([carbonBgLatestAnalysis?.measured_at]);
  const carbonBg1yChangeInitialMeasuredAt = lastMeasuredDate;
  const carbonBg1yChangePreviousMeasuredAt = printLatestDate([carbonBg1yChange?.measured_at]);

  return (
    <Stack className='justify-center gap-8 p-6 lg:gap-4 lg:p-8 lg:pr-2' data-testid='carbon-stats-tile'>
      <div className='flex flex-col gap-8 lg:gap-4'>
        <NCCardSectionHeader
          title={t('shared.ncaDetail.details.carbon.labels.belowTheGroundCarbonStorage')}
          supplementaryInfo={`${t('shared.ncaDetail.details.labels.updated')} ${lastMeasuredDate}`}
          popover={{
            title: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.title'),
            body: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.body'),
          }}
        />
        <Stack direction='row' className='flex-wrap gap-16'>
          <NCFactCapsule
            label={t('shared.ncaDetail.details.labels.latestAnalysis')}
            fact={carbonBgLatestAnalysis}
            trend={
              typeof carbonStorageBgTrend?.value === 'number' && (
                <CapsuleTrend variant={carbonStorageBgTrend.value < 0 ? 'negative' : 'positive'}>
                  {carbonStorageBgTrendDisplay}
                </CapsuleTrend>
              )
            }
            assessmentStatus={assessmentStatus}
            capsuleIconProps={{
              icon: getNCChangeIcon(carbonBgLatestAnalysis),
              variant: getNCChangeIconVariant(carbonBgLatestAnalysis),
            }}
            infoPopoverProps={{
              title: t('shared.ncaDetail.details.explainers.latestAnalysis.title'),
              body: t('shared.ncaDetail.details.explainers.latestAnalysis.body', { date: lastMeasuredDate }),
              popoverTriggerProps: {
                className: 'text-xs',
              },
            }}
          />
          {!!carbonBg1yChange?.value && (
            <NCFactCapsule
              label={t('shared.ncaDetail.details.labels.oneYearChange')}
              fact={carbonBg1yChange}
              assessmentStatus={assessmentStatus}
              capsuleIconProps={{
                icon: getNCChangeIcon(carbonBg1yChange),
                variant: getNCChangeIconVariant(carbonBg1yChange),
              }}
              infoPopoverProps={{
                // TODO: MVP-3284 delete shared.ncaDetail.details.carbon.explainers.sincePreviousAnalysis if unused
                title: t('shared.ncaDetail.details.carbon.explainers.oneYearChange.title'),
                body: t('shared.ncaDetail.details.carbon.explainers.oneYearChange.body', {
                  date: carbonBg1yChangeInitialMeasuredAt,
                  previousDate: carbonBg1yChangePreviousMeasuredAt,
                }),
                popoverTriggerProps: {
                  'data-testid': 'cbg-since-previous-analysis-tooltip',
                },
              }}
            />
          )}
        </Stack>
      </div>
    </Stack>
  );
};
