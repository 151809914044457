import {
  BiodiversityIcon,
  Button,
  ButtonLink,
  ButtonLinkProps,
  Co2Icon,
  Container,
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownItemLink,
  DropdownItemLinkProps,
  DropdownItemProps,
  DropdownTrigger,
  IconButton,
  RiDeleteBinLine,
  RiEditLine,
  RiMore2Fill,
  Stack,
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipTrigger,
  WaterIcon,
} from '@landler/tw-component-library';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { Plot, PlotStatusEnum } from '@/api/rest/resources/types/plot';
import {
  NCCard,
  NCCard2SectionLayout,
  NCCardAnalysisTypeToggle,
  NCCardComplianceFooter,
  NCCardHeader,
  SecondaryNav,
  withSuspenseBoundary,
} from '@/components';
import { BiodiversityExplainer } from '@/components/NCAExplainers/BiodiversityExplainer';
import { CarbonExplainer } from '@/components/NCAExplainers/CarbonExplainer';
import { WaterExplainer } from '@/components/NCAExplainers/WaterExplainer';
import { COMPLIANCE_LOGOS_BIODIVERSITY, COMPLIANCE_LOGOS_CARBON, COMPLIANCE_LOGOS_WATER } from '@/config/constants';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useScreenSize } from '@/hooks/useScreenSize';
import { SignedInPageLayout } from '@/layout/signed-in-page';
import { useMenuItems } from '@/pages/shared/hooks/useMenuItems';
import { usePlot } from '@/pages/shared/hooks/usePlot';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';
import { DeletePlotWithConfirmation } from '@/pages/shared/plots/components/DeletePlotWithConfirmation';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { getProjectPermissions } from '@/utils/permissions/getProjectPermissions';

import { BiodiversityGraphTile } from './components/biodiversity/GraphTile';
import { BiodiversityStatsTile } from './components/biodiversity/StatsTile';
import { CarbonGraphTile } from './components/carbon/GraphTile';
import { CarbonStatsTile } from './components/carbon/StatsTile';
import { withErrorBoundary } from './components/ErrorBoundary';
import { PlotMap } from './components/PlotMap';
import { PlotOverview } from './components/PlotOverview';
import { WaterHistoricalGraphTile } from './components/water/HistoricalGraphTile';
import { WaterHistoricalStatsTile } from './components/water/HistoricalStatsTile';
import { WaterUpliftGraphTile } from './components/water/UpliftGraphTile';
import { WaterUpliftStatsTile } from './components/water/UpliftStatsTile';

export const PlotPage = withErrorBoundary(
  withSuspenseBoundary(() => {
    const { t } = useTranslation();
    const { state } = useLocation();

    const membershipType = useMembershipType();

    const plot = usePlot().data;

    const projectDetail = useProjectDetailById().data;
    const projectId = projectDetail.id;

    const menuItems = useMenuItems();

    /**
     * Buyers cannot see details for invalid plots.
     */
    if (membershipType === MembershipWithOrganizationTypeEnum.buyer && plot.status === PlotStatusEnum.invalid) {
      return <Navigate to={buildPath(paths.buyer.plots, { pathParams: { projectId } })} replace />;
    }

    return (
      <SignedInPageLayout items={menuItems} withCustomContentContainer>
        <SecondaryNav
          title={plot.name}
          backPath={
            membershipType === MembershipWithOrganizationTypeEnum.land_steward
              ? buildPath(paths.landSteward.plots, {
                  pathParams: { projectId },
                  queryParams: { page: state?.plotPage },
                })
              : buildPath(paths.buyer.plots, {
                  pathParams: { projectId },
                  queryParams: { page: state?.plotPage },
                })
          }
          action={membershipType === MembershipWithOrganizationTypeEnum.land_steward && <PlotActions />}
        />

        <PlotMap />

        <Container className='my-10'>
          <Stack spacing={10} data-testid='biophysical-analysis'>
            <PlotOverview />
            <span className='typography-overline'>{t('shared.projects.plot.biophysicalAnalysis')}</span>
            <NCCard explainer={<CarbonExplainer />} data-testid='carbon-nca'>
              <NCCardHeader icon={<Co2Icon className='text-primary-100' />} title={t('global.analysis.carbon')}>
                <NCCardAnalysisTypeToggle data-testid='carbon-toggle' />
              </NCCardHeader>
              <NCCard2SectionLayout>
                <CarbonGraphTile />
                <CarbonStatsTile />
              </NCCard2SectionLayout>
              <NCCardComplianceFooter logos={COMPLIANCE_LOGOS_CARBON} projectId={projectId} />
            </NCCard>
            <NCCard explainer={<WaterExplainer />} data-testid='water-nca'>
              <NCCardHeader
                icon={<WaterIcon className='text-natural-capital-water-dark' />}
                title={t('global.analysis.water')}
              >
                <NCCardAnalysisTypeToggle data-testid='water-toggle' />
              </NCCardHeader>
              <NCCard2SectionLayout className='border-b border-divider'>
                <WaterHistoricalGraphTile />
                <WaterHistoricalStatsTile />
              </NCCard2SectionLayout>
              <NCCard2SectionLayout>
                <WaterUpliftGraphTile />
                <WaterUpliftStatsTile />
              </NCCard2SectionLayout>
              <NCCardComplianceFooter logos={COMPLIANCE_LOGOS_WATER} projectId={projectId} />
            </NCCard>
            <NCCard explainer={<BiodiversityExplainer />} data-testid='biodiversity-nca'>
              <NCCardHeader
                icon={<BiodiversityIcon className='text-natural-capital-biodiversity-dark' />}
                title={t('global.analysis.biodiversity')}
              />
              <NCCard2SectionLayout>
                <BiodiversityGraphTile />
                <BiodiversityStatsTile />
              </NCCard2SectionLayout>
              <NCCardComplianceFooter logos={COMPLIANCE_LOGOS_BIODIVERSITY} projectId={projectId} />
            </NCCard>
          </Stack>
        </Container>
      </SignedInPageLayout>
    );
  }),
);

const PlotActions = () => {
  const { t } = useTranslation();
  const isLargeScreen = useScreenSize() === 'large';
  const { pathname } = useLocation();

  const [plotForDeletion, setPlotForDeletion] = useState<Plot | null>(null);

  const plot = usePlot().data;
  const projectDetail = useProjectDetailById().data;

  const isProjectEditable = getProjectPermissions(projectDetail).includes('write');
  const editDeleteDisabledHint = t('shared.projects.plot.tooltips.disabledEditOrDeletePlot');

  const projectId = projectDetail.id;

  return (
    <>
      <Stack spacing={6} direction='row'>
        {isLargeScreen && (
          <EditPlotButton
            disabled={!isProjectEditable}
            disabledHint={editDeleteDisabledHint}
            to={buildPath(paths.landSteward.editPlot, { pathParams: { plotId: plot.id, projectId } })}
            state={{
              previousPath: pathname,
            }}
          >
            {t('shared.plots.overflowMenu.editData')}
          </EditPlotButton>
        )}
        <Dropdown>
          <DropdownTrigger asChild>
            <IconButton className='border border-primary-100 text-primary-100' data-testid='nav-overview-more-button'>
              <RiMore2Fill size={24} />
            </IconButton>
          </DropdownTrigger>
          <DropdownContent align='end'>
            {!isLargeScreen && (
              <MenuDropdownItemLink
                disabled={!isProjectEditable}
                disabledHint={editDeleteDisabledHint}
                to={buildPath(paths.landSteward.editPlot, { pathParams: { plotId: plot.id, projectId } })}
                state={{
                  previousPath: pathname,
                }}
                leftAdornment={<RiEditLine />}
              >
                {t('shared.plots.overflowMenu.editData')}
              </MenuDropdownItemLink>
            )}
            <MenuDropdownItem
              disabled={!isProjectEditable}
              disabledHint={editDeleteDisabledHint}
              onClick={() => setPlotForDeletion(plot)}
              leftAdornment={<RiDeleteBinLine />}
            >
              {t('shared.plots.overflowMenu.deletePlot')}
            </MenuDropdownItem>
          </DropdownContent>
        </Dropdown>
      </Stack>
      <DeletePlotWithConfirmation
        plot={plotForDeletion}
        onClose={() => setPlotForDeletion(null)}
        onSuccess={() => {
          /**
           * using href instead of navigate to ensure a reload is triggered automatically
           * using navigate + invalidating queries creates a race condition
           */
          window.location.href = buildPath(paths.landSteward.plots, { pathParams: { projectId } });
        }}
      />
    </>
  );
};

type EditPlotButtonProps = ButtonLinkProps & {
  disabledHint: string;
};

const EditPlotButton: FC<EditPlotButtonProps> = ({ children, disabledHint, to, ...delegated }) => {
  if (!delegated.disabled) {
    return (
      <ButtonLink type='button' to={to} {...delegated}>
        {children}
      </ButtonLink>
    );
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button {...delegated}>{children}</Button>
      </TooltipTrigger>
      <TooltipContent sideOffset={5} side='left'>
        {disabledHint}
        <TooltipArrow />
      </TooltipContent>
    </Tooltip>
  );
};

type MenuDropdownItemLinkProps = DropdownItemLinkProps & {
  disabledHint: string;
};

const MenuDropdownItemLink: FC<MenuDropdownItemLinkProps> = ({ children, disabledHint, to, ...delegated }) => {
  if (!delegated.disabled) {
    return (
      <DropdownItemLink to={to} {...delegated}>
        {children}
      </DropdownItemLink>
    );
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <DropdownItem {...delegated}>{children}</DropdownItem>
      </TooltipTrigger>
      <TooltipContent sideOffset={5} side='left'>
        {disabledHint}
        <TooltipArrow />
      </TooltipContent>
    </Tooltip>
  );
};

type MenuDropdownItemProps = DropdownItemProps & {
  disabledHint: string;
};

const MenuDropdownItem: FC<MenuDropdownItemProps> = ({ children, disabledHint, ...delegated }) => {
  if (!delegated.disabled) {
    return <DropdownItem {...delegated}>{children}</DropdownItem>;
  }

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <DropdownItem {...delegated}>{children}</DropdownItem>
      </TooltipTrigger>
      <TooltipContent sideOffset={5} side='left'>
        {disabledHint}
        <TooltipArrow />
      </TooltipContent>
    </Tooltip>
  );
};
