import { AssessmentStatus } from '@/api/rest/resources/types/project';

import { useProjectDetailById } from './useProjectDetailById';
import { useProjectId } from './useProjectId';

export const useAssessmentStatus = () => {
  const projectId = useProjectId();

  const { data: projectDetail } = useProjectDetailById(projectId);

  const assessmentStatus = projectDetail.calculation_status;
  const isAssessmentPending = assessmentStatus === AssessmentStatus.pending;

  return { assessmentStatus, isAssessmentPending };
};
