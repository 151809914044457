import {
  Alert,
  AlertAction,
  AlertBody,
  AlertIcon,
  AlertTitle,
  ButtonLink,
  RiAlertLine,
  RiArrowRightLine,
  RiLoader2Line,
  Stack,
} from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { AssessmentStatus } from '@/api/rest/resources/types/project';
import { useContactFormLink } from '@/hooks/useContactFormLink';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useScreenSize } from '@/hooks/useScreenSize';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';

export const Notices = () => {
  const projectId = useProjectId();
  const projectDetail = useProjectDetailById(projectId).data;

  const containsMultipleLandtypes = projectDetail.landtypes_allowed.length > 1;
  const isCalculationNoticeShown =
    projectDetail.calculation_status === AssessmentStatus.running ||
    projectDetail.calculation_status === AssessmentStatus.pending;

  return (
    <Stack spacing={2} data-testid='notices'>
      {containsMultipleLandtypes && <MultipleLandtypesNotice />}
      {isCalculationNoticeShown && <CalculationInProgressNotice />}
    </Stack>
  );
};

export const CalculationInProgressNotice = () => {
  const { t } = useTranslation();

  return (
    <Alert>
      <AlertIcon>
        <RiLoader2Line className='h-6 w-6' />
      </AlertIcon>
      <AlertTitle data-cy='analysis-alert-title'>{t('shared.ncaDetail.calculationInProgressNotice.title')}</AlertTitle>
      <AlertBody data-cy='analysis-alert-desc'>{t('shared.ncaDetail.calculationInProgressNotice.body')}</AlertBody>
    </Alert>
  );
};

export const MultipleLandtypesNotice = () => {
  const { t } = useTranslation();
  const isSmallScreen = useScreenSize() === 'small';
  const membershipType = useMembershipType();
  const contactFormLink = useContactFormLink();
  const bodyLabel =
    membershipType === MembershipWithOrganizationTypeEnum.land_steward
      ? t('shared.ncaDetail.multipleLandtypesNotice.info.landSteward')
      : t('shared.ncaDetail.multipleLandtypesNotice.info.buyer');

  return (
    <Alert type='warning' data-testid='multiple-landtypes-notice'>
      <AlertIcon>
        <RiAlertLine className='h-6 w-6' />
      </AlertIcon>
      <AlertTitle>{t('shared.ncaDetail.multipleLandtypesNotice.title')}</AlertTitle>
      {isSmallScreen ? (
        <AlertBody className='flex flex-col gap-4'>
          <span>{bodyLabel}</span>
          <ButtonLink
            to={contactFormLink}
            target='__blank'
            variant='text'
            className='text-warning-dark hover:bg-warning-dark/10'
            rightAdornment={<RiArrowRightLine size={18} />}
          >
            {t('shared.ncaDetail.multipleLandtypesNotice.button')}
          </ButtonLink>
        </AlertBody>
      ) : (
        <>
          <AlertBody className='flex flex-col gap-4'>{bodyLabel}</AlertBody>
          <AlertAction>
            <ButtonLink
              to={contactFormLink}
              target='__blank'
              variant='text'
              className='text-warning-dark hover:bg-warning-dark/10'
              rightAdornment={<RiArrowRightLine size={18} />}
            >
              {t('shared.ncaDetail.multipleLandtypesNotice.button')}
            </ButtonLink>
          </AlertAction>
        </>
      )}
    </Alert>
  );
};
