import { useTranslation } from 'react-i18next';
import { Stack } from 'tw-component-library';

import { ProjectStatus } from '@/api/rest/resources/types/project';
import {
  CalculationInProgress,
  CarbonStorageBGHistoricalChart,
  CarbonStorageBGHistoricalChartData,
  NoChartData,
} from '@/components/ChartsV1_2';
import { ChartHeading } from '@/components/ChartsV1_2/components/ChartHeading';
import { useNCCardContext } from '@/components/NCCardV1_2/NCCard';
import { useNCData } from '@/pages/shared/hooks/useNCData';
import { useProjectDetailById } from '@/pages/shared/hooks/useProjectDetailById';

export const CarbonCardGraphTile = () => {
  const { t } = useTranslation();
  const { analysisType } = useNCCardContext();

  const project = useProjectDetailById().data;
  const carbonBgHistoric = useNCData<CarbonStorageBGHistoricalChartData[]>(
    analysisType === 'total' ? 'soilCarbonHistoricTotalGraph' : 'soilCarbonHistoricPerHaGraph',
  );

  const chartData = carbonBgHistoric?.value;
  const noChartData = !chartData?.length;

  return (
    <Stack center data-testid='carbon-graph-tile'>
      {(() => {
        if (noChartData) {
          if ([ProjectStatus.initial, ProjectStatus.in_preparation].includes(project.status)) {
            return <NoChartData variant='analysisPending' />;
          }

          if (project.status === ProjectStatus.scheduled_for_analysis) {
            return <CalculationInProgress />;
          }

          return <NoChartData />;
        }

        return (
          <Stack spacing={2.5} className='w-full items-center justify-center p-6 lg:items-start lg:p-8 lg:pl-2'>
            <ChartHeading
              heading={t('shared.ncaDetail.details.carbon.labels.historicalGraphHeadline')}
              popover={{
                // TODO: MVP-3284 Confirm if the popover copy is correct
                title: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.title'),
                body: t('shared.ncaDetail.details.carbon.explainers.belowTheGroundCarbonStorage.body'),
              }}
            />
            <CarbonStorageBGHistoricalChart
              analysisType={analysisType}
              data={chartData}
              showTooltip={true}
              color='#CCC'
              className='relative'
              data-testid='carbon-storage-bg-historic-chart'
            />
          </Stack>
        );
      })()}
    </Stack>
  );
};
