/* eslint-disable security/detect-object-injection */
import { cn } from '@landler/tw-component-library';
import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Area, ComposedChart, Label, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { UnitEnum } from '@/api/rest/resources/types/fact';
import { Logger } from '@/lib/logs/logger';
import { formatUnit } from '@/utils/formatting';
import { printMonthYear } from '@/utils/formatting/date';

import { NCCardAnalysisType } from '../NCCard/NCCard';
import { ChartXTick, ChartYTick } from './components/Axis';
import { ChartLegendBar, ChartLegendBarItem } from './components/LegendBar';
import { ChartTooltip, ChartTooltipDot } from './components/Tooltip';
import { minMaxAxisDomain } from './components/utils';
import { NoChartData } from './NoChartData';
import { DefaultChartProps } from './types';

const chartColors = {
  soilMoisture: '#6ACDE1',
} as const;

export type WaterHistoricalChartData = {
  date: string;
  name: 'soil_moisture' | 'water_holding_capacity' | 'water_risk';
  value: number;
  unit: string;
};

type WaterHistoricalChartProps = DefaultChartProps & {
  /** The chart data */
  data: WaterHistoricalChartData[];
  analysisType: NCCardAnalysisType;
};

export const WaterHistoricalChart: FC<HTMLAttributes<HTMLDivElement> & WaterHistoricalChartProps> = ({
  analysisType,
  data,
  height = 400,
  width = '100%',
  showTooltip = true,
  className,
  children,
  ...delegated
}) => {
  const styles = cn('flex w-full flex-col', className);
  const unit = analysisType === 'total' ? formatUnit(UnitEnum['m^3']) : formatUnit(UnitEnum['m^3/ha']);
  const dateDataMap = data.reduce((acc, curr) => {
    const { date, name, value } = curr;

    if (['soil_moisture', 'soil_moisture_per_ha'].includes(name)) {
      acc[date] = { sm: value };
    }

    return acc;
  }, {} as Record<string, { sm: number }>);

  const chartData = Object.entries(dateDataMap)
    // @ts-ignore typescript does not allow arithmetic operations between date objects
    .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
    .map(([date, currentData]) => ({
      label: new Date(date).getTime(),
      ...currentData,
    }));

  const firstTick = chartData.at(0);
  const lastTick = chartData.at(-1);

  if (!firstTick || !lastTick) {
    Logger.error('Not enough data to render chart');
    return (
      <div className={cn(styles, 'h-full justify-center')} {...delegated}>
        <NoChartData />
      </div>
    );
  }

  return (
    <div className={styles} {...delegated}>
      <ResponsiveContainer width={width} height={height}>
        <ComposedChart
          data={chartData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id='soilMoisture' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='0%' stopColor={chartColors.soilMoisture} stopOpacity={1} />
              <stop offset='100%' stopColor={chartColors.soilMoisture} stopOpacity={0.2} />
            </linearGradient>
          </defs>
          <Area
            isAnimationActive={false}
            type='monotone'
            dataKey='sm'
            stroke='none'
            fill='url(#soilMoisture)'
            fillOpacity={1}
            activeDot={(props) => ChartTooltipDot(props)}
          />
          <XAxis
            dataKey='label'
            axisLine={false}
            tickLine={false}
            tick={<ChartXTick formatter={printMonthYear} />}
            padding={{ right: 25 }}
            scale='time'
            type='number'
            tickFormatter={printMonthYear}
            domain={['dataMin', 'dataMax']}
          />
          <YAxis
            dataKey='sm'
            axisLine={false}
            tickLine={false}
            type='number'
            scale='sequential'
            domain={minMaxAxisDomain(0, 1.2)}
            tick={<ChartYTick />}
            padding={{ top: 25 }}
            width={40}
          >
            <Label className='typography-caption text-text-secondary' value={unit} position={{ x: 32, y: 10 }} />
          </YAxis>
          {showTooltip && (
            <Tooltip
              isAnimationActive={false}
              cursor={false}
              content={(content) => ChartTooltip(content, undefined, unit, printMonthYear)}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
      <CustomLegendBar />
      {children}
    </div>
  );
};

const CustomLegendBar = () => {
  const { t } = useTranslation();

  const legendBarItems: ChartLegendBarItem[] = [
    {
      label: t('global.analysis.soilMoisture'),
      color: chartColors.soilMoisture,
    },
  ];

  return <ChartLegendBar items={legendBarItems} data-testid='water-historical-chart-legend' />;
};
