import { toastifyToast } from '@landler/tw-component-library';
import { useQueryClient } from '@tanstack/react-query';

import { usePatchPlot, usePatchPlotQuestionnaire } from '@/api/rest/resources/plot';
import { PlotStatusEnum } from '@/api/rest/resources/types/plot';

import { usePlotId } from '../../../../../../shared/hooks/usePlotId';
import { buildQuestionnaireBody } from '../../../buildQuestionnaireBody';
import { PlotInputs } from '../../../types';

export const useEditPlot = () => {
  const plotId = usePlotId();
  const patchPlotMutation = usePatchPlot();
  const patchPlotQuestionnaireMutation = usePatchPlotQuestionnaire();

  const queryClient = useQueryClient();

  const submit = async (inputs: PlotInputs, options?: { draft?: boolean }) => {
    const { plotType, name, polygon, crops } = inputs;

    if (!plotType) {
      throw Error('No selectedPlotType received');
    }

    const questionnaireBody = buildQuestionnaireBody(inputs);

    toastifyToast.clearWaitingQueue();
    toastifyToast.dismiss();

    await patchPlotQuestionnaireMutation.mutateAsync({
      pathVariables: { id: plotId },
      bodyData: { json: questionnaireBody },
    });

    await patchPlotMutation.mutateAsync({
      pathVariables: { id: plotId },
      bodyData: {
        name,
        type: plotType,
        polygon,
        crops,
        status: options?.draft ? PlotStatusEnum.draft : PlotStatusEnum.ready_to_analyse,
      },
    });

    queryClient.resetQueries({ queryKey: ['land_steward'] });
  };

  const isSubmitting = patchPlotMutation.isPending || patchPlotQuestionnaireMutation.isPending;

  return { submit, isSubmitting };
};
