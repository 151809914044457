import complianceEudrSrc from '@assets/images/compliance-eudr.png';
import complianceGreenhouseSrc from '@assets/images/compliance-greenhouse.png';
import complianceSaiSrc from '@assets/images/compliance-sai.png';
import complianceSbtiSrc from '@assets/images/compliance-sbti.png';
import { Stack } from '@landler/tw-component-library';
import { FC, ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { SecondaryNav } from '@/components';

import {
  ComplianceFrameworkCard,
  ComplianceFrameworkCardContent,
  ComplianceFrameworkCardHeader,
  ComplianceFrameworkCardLI,
  ComplianceFrameworkCardUL,
} from './components/ComplianceFrameworkCard';
import { RequestDialog } from './components/RequestDialog';
import { useComplianceFrameworksBackPath } from './hooks/useComplianceFrameworksBackPath';

const TransComponents: Record<string, ReactElement> = {
  li: <ComplianceFrameworkCardLI />,
  ul: <ComplianceFrameworkCardUL />,
};

export const ComplianceFrameworksPage: FC = () => {
  const { t } = useTranslation();
  const backPath = useComplianceFrameworksBackPath();

  return (
    <>
      <SecondaryNav backPath={backPath} title={t('shared.complianceFrameworks.title')} action={<RequestDialog />} />
      <Stack className='gap-4 p-4 md:gap-10 md:p-10'>
        <ComplianceFrameworkCard>
          <ComplianceFrameworkCardHeader
            logoSrc={complianceEudrSrc}
            logoAlt='EUDR'
            title={t('shared.complianceFrameworks.frameworks.eudr.title')}
          />
          <ComplianceFrameworkCardContent>
            {t('shared.complianceFrameworks.frameworks.eudr.copy')}
          </ComplianceFrameworkCardContent>
        </ComplianceFrameworkCard>
        <ComplianceFrameworkCard>
          <ComplianceFrameworkCardHeader
            logoSrc={complianceSaiSrc}
            logoAlt='SAI'
            title={t('shared.complianceFrameworks.frameworks.sai.title')}
          />
          <ComplianceFrameworkCardContent>
            <Trans i18nKey='shared.complianceFrameworks.frameworks.sai.copy' components={TransComponents} />
          </ComplianceFrameworkCardContent>
        </ComplianceFrameworkCard>
        <ComplianceFrameworkCard>
          <ComplianceFrameworkCardHeader
            logoSrc={complianceSbtiSrc}
            logoAlt='SBTi'
            title={t('shared.complianceFrameworks.frameworks.sbti.title')}
          />
          <ComplianceFrameworkCardContent>
            <Trans i18nKey='shared.complianceFrameworks.frameworks.sbti.copy' components={TransComponents} />
          </ComplianceFrameworkCardContent>
        </ComplianceFrameworkCard>
        <ComplianceFrameworkCard>
          <ComplianceFrameworkCardHeader
            logoSrc={complianceGreenhouseSrc}
            logoAlt='Greenhouse Gas Protocol'
            title={t('shared.complianceFrameworks.frameworks.greenhouse.title')}
          />
          <ComplianceFrameworkCardContent>
            <Trans i18nKey='shared.complianceFrameworks.frameworks.greenhouse.copy' components={TransComponents} />
          </ComplianceFrameworkCardContent>
        </ComplianceFrameworkCard>
      </Stack>
    </>
  );
};
