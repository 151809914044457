import { useSuspenseQuery } from '@tanstack/react-query';
import { geometry } from '@turf/helpers';
import { LngLatLike } from 'mapbox-gl';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MapRef } from 'react-map-gl';

import { R1Fact, UnitEnum } from '@/api/rest/resources/types/fact';
import { Map, MapOverviewHud, MapOverviewHudItem } from '@/components';
import { useBoundingBox } from '@/components/MapOverview/hooks/useBoundingBox';
import { MAP_MAX_ZOOM, MAP_OVERVIEW_PADDING } from '@/config/constants';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useScreenSize } from '@/hooks/useScreenSize';
import { getPlotsForProject } from '@/pages/shared/hooks/usePlotsForProject';
import { getGeometriesForPlots } from '@/utils/bounds';
import { formatUnit, unitToTonne, valueToTonne } from '@/utils/formatting';
import { isTestEnv } from '@/utils/isTestEnv';
import { squareMetersToHectares } from '@/utils/plot';

import { useProjectAggregate } from '../../../hooks/useProjectAggregate';
import { ProjectListEnhanced, ProjectListEnhancedWithCentroid, useProjectsList } from '../../../hooks/useProjectsList';
import { Markers } from './components/Markers';

export const ProjectsMap = () => {
  const { t } = useTranslation();
  const isLargeScreen = useScreenSize() === 'large';

  const projects = useProjectsList().data;
  const projectAggregate = useProjectAggregate().data;

  /**
   * The Map's onLoad callback is not triggered in the test environment, and so
   * the map remains hidden. We set the default value of isMapReady to true in
   * the test environment.
   */
  const [isMapReady, setIsMapReady] = useState(isTestEnv);

  const geometries = useGeometriesForProjects(projects);

  const mapRef = useRef<MapRef | null>(null);
  const { bounds } = useBoundingBox({ geometries, padding: MAP_OVERVIEW_PADDING, mapRef: mapRef.current });

  const handleMarkerClick = useCallback(
    (lngLat: LngLatLike) => mapRef.current?.flyTo({ center: lngLat, duration: 500 }),
    [mapRef],
  );

  const factMap = projectAggregate.facts.reduce(
    (acc, curr) => ({ ...acc, [curr.name]: curr }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    {} as Record<string, R1Fact<any>>,
  );

  const hudItems = useMemo((): MapOverviewHudItem[] => {
    if (!factMap) {
      return [];
    }

    return [
      {
        'data-cy': 'area',
        label: t('shared.projects.map.labels.totalSponsoredArea'),
        value: typeof factMap.area?.value === 'number' ? squareMetersToHectares(factMap.area.value) : null,
        unit: formatUnit(UnitEnum.ha),
      },
      {
        'data-cy': 'biodiversity',
        label: t('shared.projects.map.labels.biodiversity'),
        value: factMap.r1_biodiversity_zone_percent?.value,
        unit: formatUnit(factMap.r1_biodiversity_zone_percent?.unit ?? UnitEnum['%']),
      },
      {
        'data-cy': 'carbon',
        label: t('shared.projects.map.labels.carbonStorage'),
        value: valueToTonne(
          factMap.r1_carbon_storage_bg_total?.value,
          factMap.r1_carbon_storage_bg_total?.unit ?? UnitEnum.t,
        ),
        unit: formatUnit(unitToTonne(factMap.r1_carbon_storage_bg_total?.unit ?? UnitEnum.t)),
      },
      {
        'data-cy': 'water',
        label: t('shared.projects.map.labels.waterHolding'),
        value: factMap.r1_water_holding_capacity_total?.value,
        unit: formatUnit(factMap.r1_water_holding_capacity_total?.unit ?? UnitEnum['m^3']),
      },
    ];
  }, [factMap, t]);

  return (
    <div className='relative h-full w-full'>
      <div className='relative h-full w-full bg-primary-50' data-testid='projects-map'>
        <Map
          ref={mapRef}
          cooperativeGestures
          data-testid='project-map-gl'
          initialViewState={{
            bounds,
            fitBoundsOptions: { padding: MAP_OVERVIEW_PADDING, maxZoom: MAP_MAX_ZOOM },
          }}
          onLoad={() => setIsMapReady(true)}
          style={{ opacity: isMapReady ? 1 : 0, transition: 'opacity 0.2s' }}
        >
          {isLargeScreen && <MapOverviewHud items={hudItems} />}
          <Markers projects={projects} onMarkerClick={handleMarkerClick} />
          {/* Bottom decoration */}
          <div className='pointer-events-none absolute bottom-0 h-32 w-full bg-gradient-to-t from-neutral-black-60 to-transparent' />
        </Map>
      </div>
    </div>
  );
};

const useGeometriesForProjects = (projects: ProjectListEnhanced[]) => {
  const membershipType = useMembershipType();
  const firstProject = projects[0];

  const plotsForProjectQuery = useSuspenseQuery({
    queryKey: [membershipType, 'plot', firstProject?.id, 'plotsForProject', 'singleProject'],
    queryFn: () =>
      projects.length === 1 ? getPlotsForProject({ membershipType, projectId: firstProject?.id ?? '' }) : null,
  });

  return useMemo(() => {
    if (projects.length > 1) {
      const projectsWithCentroid = projects.filter((project) => project.centroid) as ProjectListEnhancedWithCentroid[];
      return projectsWithCentroid.map(({ centroid }) => geometry('Point', [centroid.lon, centroid.lat]));
    }

    if (!plotsForProjectQuery.data?.results) throw Error(`Did not receive plots for project: ${firstProject?.id}`);

    return getGeometriesForPlots(plotsForProjectQuery.data.results);
  }, [firstProject?.id, plotsForProjectQuery.data?.results, projects]);
};
