import { useTranslation } from 'react-i18next';

import { CarbonExplainer } from '@/components/NCAExplainersV1_2/CarbonExplainer';
import {
  NCCard,
  NCCard2SectionLayout,
  NCCardAnalysisTypeToggle,
  NCCardComplianceFooter,
  NCCardHeader,
} from '@/components/NCCardV1_2/NCCard';
import { COMPLIANCE_LOGOS_CARBON } from '@/config/constants';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';

import { CarbonCardGraphTile } from './GraphTile';
import { CarbonCardStatsTile } from './StatsTile';

export const CarbonCard = () => {
  const projectId = useProjectId();
  const { t } = useTranslation();

  return (
    <NCCard explainer={<CarbonExplainer />}>
      <NCCardHeader title={t('shared.ncaDetail.details.carbon.labels.carbonStorageBG')}>
        <NCCardAnalysisTypeToggle data-testid='carbon-toggle' />
      </NCCardHeader>
      <NCCard2SectionLayout>
        <CarbonCardGraphTile />
        <CarbonCardStatsTile />
      </NCCard2SectionLayout>
      <NCCardComplianceFooter logos={COMPLIANCE_LOGOS_CARBON} projectId={projectId} />
    </NCCard>
  );
};
