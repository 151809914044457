import * as DialogPrimitive from '@radix-ui/react-dialog';
import { ComponentPropsWithoutRef, ElementRef, forwardRef, HTMLAttributes } from 'react';

import { RiCloseLine } from '../Icon/Icon';
import { cn } from '../utils';

export type DialogProps = DialogPrimitive.DialogProps;

export const Dialog = DialogPrimitive.Root;

export type DialogTriggerProps = DialogPrimitive.DialogTriggerProps;

export const DialogTrigger = DialogPrimitive.Trigger;

export type DialogContentProps = {
  withXButton?: boolean;
};

export const DialogContent = forwardRef<
  ElementRef<typeof DialogPrimitive.Content>,
  ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & DialogContentProps
>(({ className, children, withXButton = true, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay />
    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        'fixed left-[50%] top-[50%] z-dialog grid w-[calc(100%_-_32px)] max-w-lg translate-x-[-50%] translate-y-[-50%] rounded-2xl bg-white-100 px-12 py-10 shadow-md',
        'duration-200 data-[state=open]:animate-in data-[state=open]:fade-in-0 data-[state=open]:zoom-in-95 data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]',
        'flex max-h-full flex-col',
        className,
      )}
      {...props}
    >
      {children}
      {withXButton && (
        <DialogClose className='absolute right-4 top-4 aspect-square rounded-full p-2' data-testid='dialog-close-icon'>
          <RiCloseLine className='h-8 w-8' />
        </DialogClose>
      )}
    </DialogPrimitive.Content>
  </DialogPortal>
));
DialogContent.displayName = 'DialogContent';

export type DialogBodyProps = HTMLAttributes<HTMLDivElement>;

export const DialogBody = forwardRef<HTMLDivElement, DialogBodyProps>(({ className, ...delegated }, ref) => {
  return <div ref={ref} className={cn('flex-shrink overflow-y-auto', className)} {...delegated} />;
});
DialogBody.displayName = 'DialogBody';

export type DialogHeaderProps = HTMLAttributes<HTMLDivElement>;

export const DialogHeader = forwardRef<HTMLDivElement, DialogHeaderProps>(({ className, ...delegated }, ref) => (
  <div ref={ref} className={cn('mb-8 flex flex-col gap-4 text-center sm:text-left', className)} {...delegated} />
));
DialogHeader.displayName = 'DialogHeader';

export type DialogFooterProps = HTMLAttributes<HTMLDivElement>;

export const DialogFooter = forwardRef<HTMLDivElement, DialogFooterProps>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('flex flex-col items-center justify-between gap-2 md:flex-row', className)} {...props} />
));
DialogFooter.displayName = 'DialogFooter';

export type DialogTitleProps = DialogPrimitive.DialogTitleProps;

export const DialogTitle = forwardRef<ElementRef<typeof DialogPrimitive.Title>, DialogTitleProps>(
  ({ className, ...props }, ref) => (
    <DialogPrimitive.Title ref={ref} className={cn('typography-display3 text-center', className)} {...props} />
  ),
);
DialogTitle.displayName = DialogPrimitive.Title.displayName;

export type DialogDescriptionProps = DialogPrimitive.DialogDescriptionProps;

export const DialogDescription = forwardRef<ElementRef<typeof DialogPrimitive.Description>, DialogDescriptionProps>(
  ({ className, ...props }, ref) => (
    <DialogPrimitive.Description ref={ref} className={cn('typography-body1 text-center', className)} {...props} />
  ),
);
DialogDescription.displayName = 'DialogDescription';

export type DialogCloseProps = DialogPrimitive.DialogCloseProps;

export const DialogClose = DialogPrimitive.Close;

export type DialogPortalProps = DialogPrimitive.PortalProps;

export const DialogPortal = ({ className, ...props }: DialogPrimitive.DialogPortalProps) => (
  <DialogPrimitive.Portal className={cn(className)} {...props} />
);
DialogPortal.displayName = 'DialogPortal';

export type DialogOverlayProps = DialogPrimitive.DialogOverlayProps;

export const DialogOverlay = forwardRef<ElementRef<typeof DialogPrimitive.Overlay>, DialogOverlayProps>(
  ({ className, ...props }, ref) => (
    <DialogPrimitive.Overlay
      ref={ref}
      className={cn(
        'fixed inset-0 z-dialog bg-neutral-black-30 transition-all duration-100',
        'animate-in fade-in',
        className,
      )}
      {...props}
    />
  ),
);
DialogOverlay.displayName = 'DialogOverlay';
