import { cn } from '@landler/tw-component-library';
import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Area, AreaChart, Label, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { UnitEnum } from '@/api/rest/resources/types/fact';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { Logger } from '@/lib/logs/logger';
import { formatUnit } from '@/utils/formatting';
import { printYear } from '@/utils/formatting/date';

import { NCCardAnalysisType } from '../NCCard/NCCard';
import { ChartXTick, ChartYTick } from './components/Axis';
import { ChartLegendBar, ChartLegendBarItem } from './components/LegendBar';
import {
  ChartTooltip,
  ChartTooltipDot,
  ChartTooltipTextPrimary,
  ChartTooltipTextSecondary,
} from './components/Tooltip';
import { minMaxAxisDomain } from './components/utils';
import { NoChartData } from './NoChartData';
import { DefaultChartProps } from './types';

const strokeColor = '#3EC1DC';
const gradientColor = '#85DAFF';

export type WaterHoldingCapacityChartData = {
  date: string;
  name: 'water_holding_capacity_potential';
  value: number;
  unit: 'm^3/m^3';
};

type WaterHoldingCapacityChartProps = DefaultChartProps & {
  /** The chart data */
  data: WaterHoldingCapacityChartData[];
  analysisType: NCCardAnalysisType;
};

export const WaterHoldingCapacityChart: FC<HTMLAttributes<HTMLDivElement> & WaterHoldingCapacityChartProps> = ({
  analysisType,
  data,
  height = 400,
  width = '100%',
  showTooltip = true,
  className,
  children,
  ...delegated
}) => {
  const styles = cn('flex w-full flex-col', className);
  const unit = analysisType === 'total' ? formatUnit(UnitEnum['m^3']) : formatUnit(UnitEnum['m^3/ha']);

  const chartData = data.map((d) => ({ label: new Date(d.date).getFullYear(), value: d.value }));

  const firstTick = chartData.at(0);
  const lastTick = chartData.at(-1);

  if (!firstTick || !lastTick) {
    Logger.error('Not enough data to render chart');
    return (
      <div className={cn(styles, 'h-full justify-center')} {...delegated}>
        <NoChartData />
      </div>
    );
  }

  /**  Science spec
  Show X axis ticks in gaps of 1 year sequentially
  */
  const xTicks = () => {
    const startYear = firstTick.label;
    const endYear = lastTick.label;
    const ticksRange = Array(endYear - startYear + 1).keys();
    return Array.from(ticksRange).map((x) => x + startYear);
  };

  const currentYearValue = firstTick.value;

  return (
    <div className={styles} {...delegated}>
      <ResponsiveContainer width={width} height={height}>
        <AreaChart
          data={chartData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id='waterHoldingCapacity' x1='0' y1='-0.86' x2='0' y2='1'>
              <stop offset='0%' stopColor={gradientColor} stopOpacity={1} />
              <stop offset='100%' stopColor={gradientColor} stopOpacity={0} />
            </linearGradient>
          </defs>
          <Area
            isAnimationActive={false}
            type='monotone'
            dataKey='value'
            stroke={strokeColor}
            strokeWidth={3}
            fill='url(#waterHoldingCapacity)'
            fillOpacity={1}
            activeDot={(props) => ChartTooltipDot(props)}
          />
          <XAxis
            dataKey='label'
            axisLine={false}
            tickLine={false}
            tick={<ChartXTick />}
            padding={{ right: 25 }}
            type='number'
            domain={['dataMin', 'dataMax']}
            ticks={xTicks()}
          />
          <YAxis
            dataKey='value'
            axisLine={false}
            tickLine={false}
            type='number'
            scale='sequential'
            domain={([dataMin, dataMax]) =>
              /**  Science spec
            Set Y axis limits to (97% of currentDayValue, 103% of currentDayValue)
            Fallback to (97% of dataMin, 103% of dataMax) if current year value is lesser/greater than min/max
            */
              minMaxAxisDomain(0.97, 1.03)([Math.min(dataMin, currentYearValue), Math.max(currentYearValue, dataMax)])
            }
            tick={<ChartYTick />}
            padding={{ top: 25 }}
            width={40}
          >
            <Label className='typography-caption text-text-secondary' value={unit} position={{ x: 32, y: 10 }} />
          </YAxis>
          {showTooltip && (
            <Tooltip
              isAnimationActive={false}
              cursor={false}
              content={(content) => <CustomTooltip {...content} unit={unit as UnitEnum} />}
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
      <CustomLegendBar />
      {children}
    </div>
  );
};

const CustomLegendBar = () => {
  const { t } = useTranslation();

  const legendBarItems: ChartLegendBarItem[] = [
    {
      label: t('global.analysis.waterHoldingCapacity'),
      color: gradientColor,
    },
  ];

  return <ChartLegendBar items={legendBarItems} data-testid='water-holding-capacity-chart-legend' />;
};

const CustomTooltip = ({ active, payload, label, unit }: TooltipProps<ValueType, NameType> & { unit: UnitEnum }) => {
  const displayValue = useDisplayNumber((payload?.[0]?.value as string) ?? 0);
  if (!active) return null;

  return (
    <ChartTooltip>
      <ChartTooltipTextPrimary>{`${displayValue} ${unit}`}</ChartTooltipTextPrimary>
      <ChartTooltipTextSecondary>{printYear(`${label}`)}</ChartTooltipTextSecondary>
    </ChartTooltip>
  );
};
