import { useTranslation } from 'react-i18next';
import { Area, AreaChart, ResponsiveContainer } from 'recharts';

import { UnitEnum } from '@/api/rest/resources/types/fact';
import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { ProjectStatus } from '@/api/rest/resources/types/project';
import { useDisplayNumber } from '@/hooks/useDisplayNumber';
import { useMembershipType } from '@/hooks/useMembershipType';
import { useProject } from '@/pages/landsteward/hooks/useProject';
import { useNCData } from '@/pages/shared/hooks/useNCData';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { formatUnit } from '@/utils/formatting';

import { NoData } from '../../../components/NoData';
import { Tile, TileCapsuleContent, TileCapsuleTitle, TileCta, TileHelper } from './Tile';

export const WaterTile = () => {
  const { t } = useTranslation();
  const pathSet =
    useMembershipType() === MembershipWithOrganizationTypeEnum.land_steward ? paths.landSteward : paths.buyer;

  const project = useProject().data;
  const historicWaterAndSoilMoisturePerHaGraph = useNCData<HistoricWaterAndSoilMoistureGraphData[]>(
    'historicWaterAndSoilMoisturePerHaGraph',
  );
  const whcLatestAnalysis = useNCData<number>('waterHoldingCapacityLatestAnalysisPerHa');

  const chartData = historicWaterAndSoilMoisturePerHaGraph?.value;

  const whcLatestAnalysisDisplay = `${useDisplayNumber(whcLatestAnalysis?.value ?? 0)} ${formatUnit(
    UnitEnum['m^3/ha'],
  )}`;

  const noChartData = !chartData?.length;

  if (noChartData) {
    return <NoData />;
  }

  return (
    <Tile
      chart={<Chart chartData={chartData} />}
      capsuleTitle={<TileCapsuleTitle>{t('global.analysis.waterHoldingCapacity')}</TileCapsuleTitle>}
      capsuleContent={<TileCapsuleContent>{whcLatestAnalysisDisplay}</TileCapsuleContent>}
      cta={
        <TileCta to={buildPath(pathSet.v1_2_projectDetailsWater, { pathParams: { projectId: project.id } })}>
          {t('shared.projects.project.landMonitoring.water.labels.seeAllWaterIndicators')}
        </TileCta>
      }
      helper={<TileHelper>{t('shared.projects.project.landMonitoring.water.helper')}</TileHelper>}
    />
  );
};

type HistoricWaterAndSoilMoistureGraphData = {
  date: string;
  name: 'water_holding_capacity_per_ha';
  value: number;
  unit: string;
};

const Chart = ({ chartData }: { chartData: HistoricWaterAndSoilMoistureGraphData[] }) => {
  const project = useProject().data;
  const isOutdatedValue = project.status !== ProjectStatus.analysed;

  return (
    <ResponsiveContainer width='100%' height={80}>
      <AreaChart
        data={chartData}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <defs>
          <linearGradient id='waterHoldingCapacity' x1='0' y1='-0.85' x2='0' y2='1'>
            <stop offset='0%' stopColor={isOutdatedValue ? '#B4B4B4' : '#6ACDE1'} stopOpacity={1} />
            <stop offset='100%' stopColor={isOutdatedValue ? '#B4B4B4' : '#6ACDE1'} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          isAnimationActive={false}
          type='monotone'
          dataKey='value'
          stroke={isOutdatedValue ? '#595959' : '#71A7B2'}
          strokeWidth={2}
          fill='url(#waterHoldingCapacity)'
          fillOpacity={1}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
