import { cn, Stack } from '@landler/tw-component-library';
import { useTranslation } from 'react-i18next';
import { Link, LinkProps, Outlet, useLocation } from 'react-router-dom';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { useMembershipType } from '@/hooks/useMembershipType';
import { SignedInPageLayoutContentContainer } from '@/layout/signed-in-page/components';
import { usePlotAggregate } from '@/pages/shared/hooks/usePlotAggregate';
import { usePlotsForProject } from '@/pages/shared/hooks/usePlotsForProject';
import { useProjectId } from '@/pages/shared/hooks/useProjectId';
import { StackedPageLayout } from '@/pages/shared/layout/StackedPage.layout';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';

import { withErrorBoundary } from '../../components/ErrorBoundary';
import { NoPlotsHero } from './components/NoPlotsHero';
import { ProjectMap } from './components/ProjectMap';
import { ProjectSecondaryNav } from './components/ProjectSecondaryNav';

export const ProjectPageLayout = withErrorBoundary(() => {
  const membershipType = useMembershipType();
  const plots = usePlotsForProject().data.results;

  return (
    <StackedPageLayout withCustomContentContainer>
      <ProjectSecondaryNav />
      {plots.length === 0 && membershipType === MembershipWithOrganizationTypeEnum.land_steward ? (
        <NoPlotsHero />
      ) : (
        <>
          <div className='h-[65vh] animate-in fade-in full-bleed-x'>
            <ProjectMap />
          </div>
          <SignedInPageLayoutContentContainer>
            <Stack spacing={10} className='animate-in fade-in slide-in-from-bottom-4' data-testid='tabs'>
              <Tabs />
              <Outlet />
            </Stack>
          </SignedInPageLayoutContentContainer>
        </>
      )}
    </StackedPageLayout>
  );
});

const Tabs = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const membershipType = useMembershipType();

  const projectId = useProjectId();
  const { plots_requiring_attention: invalidPlotsCount } = usePlotAggregate().data;

  const projectPath = buildPath(
    membershipType === MembershipWithOrganizationTypeEnum.land_steward
      ? paths.landSteward.projectDetails
      : paths.buyer.projectDetails,
    { pathParams: { projectId } },
  );
  const plotsPath = buildPath(
    membershipType === MembershipWithOrganizationTypeEnum.land_steward ? paths.landSteward.plots : paths.buyer.plots,
    { pathParams: { projectId } },
  );

  return (
    <Stack direction='row' spacing={4} center>
      <Tab to={projectPath} active={location.pathname === projectPath} data-cy='pnc-tab'>
        {t('shared.projects.projectNaturalCapital')}
      </Tab>
      <Tab to={plotsPath} active={location.pathname === plotsPath}>
        <Stack direction='row' spacing={2} center data-cy='plots-tab'>
          {t('shared.plots.plots')}
          {invalidPlotsCount > 0 && (
            <span className='typography-body2Semibold flex aspect-auto items-center justify-center rounded-full bg-warning px-2 text-primary-100'>
              {invalidPlotsCount}
            </span>
          )}
        </Stack>
      </Tab>
    </Stack>
  );
};

const Tab = ({ className, active, children, ...delegated }: LinkProps & { active: boolean }) => {
  return (
    <Link preventScrollReset {...delegated}>
      <span
        className={cn(
          'typography-button1 inline-block border-b-2 border-transparent p-4 text-text-secondary',
          active && 'border-secondary-100 text-primary-100',
          className,
        )}
      >
        {children}
      </span>
    </Link>
  );
};
