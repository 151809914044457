/* eslint-disable security/detect-object-injection */
import { cn, theme } from '@landler/tw-component-library';
import { FC, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { Area, ComposedChart, Label, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { UnitEnum } from '@/api/rest/resources/types/fact';
import { getDisplayNumber } from '@/hooks/useDisplayNumber';
import { Logger } from '@/lib/logs/logger';
import { formatUnit } from '@/utils/formatting';
import { printMonthYear } from '@/utils/formatting/date';

import { NCCardAnalysisType } from '../NCCardV1_2/NCCard';
import { ChartXTick, ChartYTick } from './components/Axis';
import { ChartLegendBar, ChartLegendBarItem } from './components/LegendBar';
import {
  ChartTooltip,
  ChartTooltipDot,
  ChartTooltipTextPrimary,
  ChartTooltipTextSecondary,
} from './components/Tooltip';
import { minMaxAxisDomain } from './components/utils';
import { NoChartData } from './NoChartData';
import { DefaultChartProps } from './types';

const chartColors = {
  whc: { stroke: '#3EC1DC', gradient: '#6ACDE1' },
  sm: { gradient: { start: '#1B6E80', stop: '#85DAFF' } },
} as const;

const axisStrokeColor = (theme.colors as Record<string, string> | undefined)?.['neutral-black-12'];

export type WaterHistoricalChartData = {
  date: string;
  name: 'soil_moisture' | 'water_holding_capacity' | 'water_risk';
  value: number;
  unit: string;
};

type WaterHistoricalChartProps = DefaultChartProps & {
  /** The chart data */
  data: WaterHistoricalChartData[];
  analysisType: NCCardAnalysisType;
};

export const WaterHistoricalChart: FC<HTMLAttributes<HTMLDivElement> & WaterHistoricalChartProps> = ({
  analysisType,
  data,
  height = 400,
  width = '100%',
  showTooltip = true,
  className,
  children,
  ...delegated
}) => {
  const styles = cn('flex w-full flex-col', className);
  const unit = analysisType === 'total' ? formatUnit(UnitEnum['m^3']) : formatUnit(UnitEnum['m^3/ha']);
  const dateDataMap = data.reduce((acc, curr) => {
    const { date, name, value } = curr;
    /** Since we receive time series that may not overlap on all timestamps (either sm/whc may be undefined for the same date)
     *  in such cases, we default to null (not 0, since 0 is a valid value) for that particular date
     *  The area graph will then connect these null values along the other non-null values to create a continuous graph
     *  If these points are not handled, the graph breaks since we need valid Y values along the shared X axis
     */
    const smValue = ['soil_moisture', 'soil_moisture_per_ha'].includes(name) ? value : acc[date]?.sm;
    const whcValue = ['water_holding_capacity', 'water_holding_capacity_per_ha'].includes(name)
      ? value
      : acc[date]?.whc;
    acc[date] = { sm: smValue ?? null, whc: whcValue ?? null };

    return acc;
  }, {} as Record<string, { sm: number | null; whc: number | null }>);

  const chartData = Object.entries(dateDataMap)
    // @ts-ignore typescript does not allow arithmetic operations between date objects
    .sort(([dateA], [dateB]) => new Date(dateA) - new Date(dateB))
    .map(([date, currentData]) => ({
      label: new Date(date).getTime(),
      ...currentData,
    }));

  const firstTick = chartData.at(0);
  const lastTick = chartData.at(-1);

  if (!firstTick || !lastTick) {
    Logger.error('Not enough data to render chart');
    return (
      <div className={cn(styles, 'h-full justify-center')} {...delegated}>
        <NoChartData />
      </div>
    );
  }

  return (
    <div className={styles} {...delegated}>
      <ResponsiveContainer width={width} height={height}>
        <ComposedChart
          data={chartData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id='waterHoldingCapacity' x1='0' y1='-0.86' x2='0' y2='1'>
              <stop offset='0%' stopColor={chartColors.whc.gradient} stopOpacity={1} />
              <stop offset='100%' stopColor={chartColors.whc.gradient} stopOpacity={0} />
            </linearGradient>
            <linearGradient id='soilMoisture' x1='0' y1='0' x2='0' y2='1'>
              <stop offset='0%' stopColor={chartColors.sm.gradient.start} stopOpacity={1} />
              <stop offset='100%' stopColor={chartColors.sm.gradient.stop} stopOpacity={0.39} />
            </linearGradient>
          </defs>
          <Area
            isAnimationActive={false}
            type='monotone'
            dataKey='sm'
            stroke='none'
            fill='url(#soilMoisture)'
            fillOpacity={1}
            activeDot={(props) => ChartTooltipDot(props)}
            connectNulls
          />
          <Area
            isAnimationActive={false}
            type='monotone'
            dataKey='whc'
            stroke={chartColors.whc.stroke}
            strokeWidth={2}
            fill='url(#waterHoldingCapacity)'
            activeDot={(props) => ChartTooltipDot(props)}
            connectNulls
          />
          <XAxis
            dataKey='label'
            tickLine={false}
            tick={<ChartXTick formatter={printMonthYear} />}
            padding={{ right: 25 }}
            scale='time'
            type='number'
            tickFormatter={printMonthYear}
            domain={['dataMin', 'dataMax']}
            stroke={axisStrokeColor}
          />
          <YAxis
            dataKey='whc'
            tickLine={false}
            type='number'
            scale='sequential'
            domain={minMaxAxisDomain(0, 1.2)}
            tick={<ChartYTick />}
            padding={{ top: 25 }}
            width={40}
            stroke={axisStrokeColor}
          >
            <Label className='typography-caption text-text-secondary' value={unit} position={{ x: 32, y: 10 }} />
          </YAxis>
          {showTooltip && (
            <Tooltip
              isAnimationActive={false}
              cursor={false}
              content={(content) => <CustomTooltip {...content} unit={unit as UnitEnum} />}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
      <CustomLegendBar />
      {children}
    </div>
  );
};

const CustomLegendBar = () => {
  const { t } = useTranslation();

  const legendBarItems: ChartLegendBarItem[] = [
    {
      label: t('global.analysis.waterHoldingCapacity'),
      color: chartColors.whc.gradient,
    },
    {
      label: t('global.analysis.soilMoisture'),
      color: chartColors.sm.gradient.start,
    },
  ];

  return (
    <ChartLegendBar items={legendBarItems} className='ml-[28px] mt-8' data-testid='water-historical-chart-legend' />
  );
};

const CustomTooltip = ({ active, payload, label, unit }: TooltipProps<ValueType, NameType> & { unit: UnitEnum }) => {
  const { t } = useTranslation();

  /** sm/whc values are not stored in any particular order in the payload array when multiple graphs are rendered,
   *  the payload just carries non-null values, null values are omitted.
   *  If either whc/sm are null, they will not show up in the payload array
   *  Hence we search for the object for a dataKey match, instead of indexing the array
   *  if they exist, we extract the y value, if not, we assume its null
   */
  const smValue = payload?.find((data) => data.dataKey === 'sm');
  const whcValue = payload?.find((data) => data.dataKey === 'whc');
  const smDisplay = smValue?.value
    ? `${getDisplayNumber(smValue?.value as string, window.navigator.language)} ${unit}`
    : t('global.analysis.noData');
  const whcDisplay = whcValue?.value
    ? `${getDisplayNumber(whcValue?.value as string, window.navigator.language)} ${unit}`
    : t('global.analysis.noData');

  if (!active) return null;

  return (
    <ChartTooltip>
      <ChartTooltipTextPrimary>{`${t('global.analysis.soilMoisture')}: ${smDisplay}`}</ChartTooltipTextPrimary>
      <ChartTooltipTextPrimary>{`${t('global.analysis.waterHoldingCapacity')}: ${whcDisplay}`}</ChartTooltipTextPrimary>
      <ChartTooltipTextSecondary>{`${t('global.misc.measuredAt')}: ${printMonthYear(
        label,
      )}`}</ChartTooltipTextSecondary>
    </ChartTooltip>
  );
};
