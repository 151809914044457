import complianceEudrSrc from '@assets/images/compliance-eudr.png';
import complianceGreenhouseSrc from '@assets/images/compliance-greenhouse.png';
import complianceSaiSrc from '@assets/images/compliance-sai.png';
import complianceSbtiSrc from '@assets/images/compliance-sbti.png';
import i18next from 'i18next';
import React, { createContext, FC, forwardRef, HTMLAttributes, PropsWithChildren, useContext, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  ButtonLink,
  cn,
  Divider,
  IconHalo,
  InfoPopover,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
} from 'tw-component-library';

import { MembershipWithOrganizationTypeEnum } from '@/api/rest/resources/types/membership';
import { useScreenSize } from '@/hooks/useScreenSize';
import { useUser } from '@/lib/auth';
import { paths } from '@/routing';
import { buildPath } from '@/utils/buildPath';
import { exhaustivenessCheck } from '@/utils/exhaustivenessCheck';
import { getLatestDate } from '@/utils/formatting/date';

export type NCCardAnalysisType = 'total' | 'average';

type NCCardContextValue = {
  analysisType: NCCardAnalysisType;
  setAnalysisType: (anaylsisType: NCCardAnalysisType) => void;
};

export const NCCardContext = createContext<NCCardContextValue>({
  analysisType: 'total',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setAnalysisType: () => {},
});

export const useNCCardContext = () => useContext(NCCardContext);

const ExplainerContext = createContext<[HTMLDivElement | null, (element: HTMLDivElement | null) => void]>([
  null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
]);

const ExplainerPortal = ({ children }: PropsWithChildren) => {
  const [explainer] = React.useContext(ExplainerContext);

  if (!explainer || !children) return null;

  return createPortal(children, explainer);
};

const LargeScreenExplainerOutlet = () => {
  const [, setExplainer] = useContext(ExplainerContext);
  const isSmallScreen = useScreenSize() === 'small';

  if (isSmallScreen) return null;

  return <div ref={(ref) => setExplainer(ref)} />;
};

const SmallScreenExplainerOutlet = () => {
  const [, setExplainer] = useContext(ExplainerContext);
  const isSmallScreen = useScreenSize() === 'small';

  if (!isSmallScreen) return null;

  return <Stack className='px-6 pb-6' ref={(ref) => setExplainer(ref)} />;
};

export type NCCardProps = HTMLAttributes<HTMLDivElement> & {
  explainer?: React.ReactNode;
};

export const NCCard = forwardRef<HTMLDivElement, NCCardProps>(
  ({ className, explainer, children, ...delegated }, ref) => {
    const [analysisType, setAnalysisType] = useState<NCCardContextValue['analysisType']>('total');
    const explainerState = React.useState<HTMLDivElement | null>(null);

    return (
      <NCCardContext.Provider value={{ analysisType, setAnalysisType }}>
        <ExplainerContext.Provider value={explainerState}>
          <div ref={ref} className={cn('rounded-2xl bg-white-100', className)} {...delegated}>
            {children}
          </div>
          {explainer && <ExplainerPortal>{explainer}</ExplainerPortal>}
        </ExplainerContext.Provider>
      </NCCardContext.Provider>
    );
  },
);
NCCard.displayName = 'NCCard';

export type NCCardHeaderProps = HTMLAttributes<HTMLDivElement> & {
  icon?: React.ReactNode;
  title?: string;
};

export const NCCardHeader: FC<NCCardHeaderProps> = forwardRef<HTMLDivElement, NCCardHeaderProps>(
  ({ title, icon, children, className, ...delegated }, ref) => {
    return (
      <div ref={ref} className='full-bleed-x'>
        <div className={cn('p-6', className)} {...delegated}>
          <Stack direction='row' spacing={4} centerMain className='justify-between'>
            <Stack direction='row' spacing={4} className='items-center'>
              {icon && <IconHalo>{icon}</IconHalo>}
              <span className='typography-h3'>{title}</span>
            </Stack>
            <Stack direction='row' centerMain spacing={8}>
              {children}
              <LargeScreenExplainerOutlet />
            </Stack>
          </Stack>
        </div>
        <Divider />
      </div>
    );
  },
);
NCCardHeader.displayName = 'NCCardHeader';

export type NCCard2SectionLayoutProps = HTMLAttributes<HTMLDivElement>;

export const NCCard2SectionLayout = forwardRef<HTMLDivElement, NCCard2SectionLayoutProps>(
  ({ className, ...delegated }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'divide-y divide-divider [&>*]:px-6',
          'md:grid md:w-auto md:grid-cols-2 md:divide-x md:divide-y-0 md:[&>*]:px-8',
          className,
        )}
        {...delegated}
      />
    );
  },
);
NCCard2SectionLayout.displayName = 'NCCard2SectionLayout';

type NCCardSectionHeaderProps = {
  title: React.ReactNode;
  supplementaryInfo?: React.ReactNode;
  popover?: {
    title?: React.ReactNode;
    body?: React.ReactNode;
  };
};

export const NCCardSectionHeader: FC<NCCardSectionHeaderProps> = ({ title, supplementaryInfo, popover }) => {
  const isSmallScreen = useScreenSize() === 'small';

  return (
    <Stack direction='row' className='items-center justify-between'>
      <span className='typography-body1'>
        {title}
        {popover && !isSmallScreen && (
          <span className='mx-2 align-middle leading-[0.8]'>
            <InfoPopover position='right' title={popover.title} body={popover.body} />
          </span>
        )}
      </span>
      {supplementaryInfo && !isSmallScreen && (
        <span className='typography-caption flex-1 whitespace-nowrap text-right text-text-secondary'>
          {supplementaryInfo}
        </span>
      )}
    </Stack>
  );
};

export type NCCardAnalysisTypeToggleProps = Omit<ToggleButtonGroupProps<NCCardAnalysisType>, 'value'> & {
  value?: NCCardAnalysisType;
};

export const NCCardAnalysisTypeToggle: FC<NCCardAnalysisTypeToggleProps> = ({
  onChange,
  value,
  className,
  ...delegated
}) => {
  const { t } = useTranslation();
  const { analysisType, setAnalysisType } = useNCCardContext();

  const handleChange: NCCardAnalysisTypeToggleProps['onChange'] = (newValue) => {
    onChange?.(newValue);
    setAnalysisType(newValue);
  };

  return (
    <ToggleButtonGroup
      size='small'
      value={value ?? analysisType}
      onChange={handleChange}
      className={className}
      {...delegated}
    >
      <ToggleButton value='total'>{t('global.ui.toggle.total')}</ToggleButton>
      <ToggleButton value='average'>{t('global.ui.toggle.perHa')}</ToggleButton>
    </ToggleButtonGroup>
  );
};

const complianceLogos = ['science', 'greenhouse', 'sai', 'eudr'] as const;
type ComplianceFooterLogo = (typeof complianceLogos)[number];

type NCCardComplianceFooterProps = HTMLAttributes<HTMLDivElement> & {
  logos: ComplianceFooterLogo[];
  projectId?: string;
};

export const NCCardComplianceFooter: FC<NCCardComplianceFooterProps> = ({
  logos,
  projectId, // TODO: MVP-2559 / MVP-2557: prop can be removed as projectId can be retrieved via useParams()
  className,
  ...delegated
}) => {
  const { t } = useTranslation();

  return (
    <>
      <SmallScreenExplainerOutlet />
      <div
        className={cn(
          'flex flex-col items-center justify-between gap-3 border-t border-divider p-6 lg:flex-row',
          className,
        )}
        {...delegated}
      >
        <div className='flex w-full flex-col items-center gap-3 md:flex-row'>
          <span className='typography-body1 text-text-primary'>{t('global.nca.complianceLogos.logosLabel')}</span>
          <Stack direction='row' spacing={3} className='flex-wrap items-center justify-center'>
            {logos.map((logo) => (
              <ComplianceLogo key={logo} logo={logo} />
            ))}
          </Stack>
        </div>
        <FrameworksLink projectId={projectId} />
      </div>
    </>
  );
};

// TODO: MVP-2559 / MVP-2557: prop can be removed as projectId can be retrieved via useParams()
const FrameworksLink: FC<{ projectId?: string }> = ({ projectId }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const user = useUser();

  const isLandsteward = user.membership.type === MembershipWithOrganizationTypeEnum.land_steward;

  const path = isLandsteward
    ? paths.landSteward.projectsComplianceFrameworks
    : paths.buyer.projectsComplianceFrameworks;

  const to = buildPath(path, { queryParams: projectId ? { projectId } : undefined });

  return (
    <ButtonLink
      to={to}
      state={{
        previousPath: pathname,
      }}
      variant='text'
    >
      {t('global.nca.complianceLogos.buttonLabel')}
    </ButtonLink>
  );
};

const ComplianceLogo: FC<{ logo: ComplianceFooterLogo }> = ({ logo }) => {
  const getLogoDetails = () => {
    switch (logo) {
      case 'eudr':
        return {
          src: complianceEudrSrc,
          alt: 'EUDR',
          label: 'EUDR',
        };
      case 'greenhouse':
        return {
          src: complianceGreenhouseSrc,
          alt: 'Greenhouse Gas Protocol',
          label: (
            <span>
              Greenhouse <span className='whitespace-nowrap'>Gas Protocol</span>
            </span>
          ),
        };
      case 'sai':
        return {
          src: complianceSaiSrc,
          alt: 'SAI',
          label: 'SAI Platform',
        };
      case 'science':
        return {
          src: complianceSbtiSrc,
          alt: 'SBTi',
          label: (
            <span>
              <span className='whitespace-nowrap'>Science-Based</span> Targets
            </span>
          ),
        };
      default:
        return exhaustivenessCheck(logo);
    }
  };

  const logoDetails = getLogoDetails();

  return (
    <Stack direction='row' spacing={1} className='items-center rounded-lg border border-divider px-3 py-2'>
      <Stack center className='aspect-square w-8'>
        <img src={logoDetails.src} alt={logoDetails.alt} className='w-full' />
      </Stack>
      <span className='typography-body2 flex max-w-min whitespace-break-spaces uppercase leading-none text-text-secondary'>
        {logoDetails.label}
      </span>
    </Stack>
  );
};

/**
 * Formats the latest date. Returns 'N/A' if an empty list is provided, or if
 * one of the dates is invalid.
 */
export const printLatestDate = (dates: unknown[]) => {
  return getLatestDate(dates)?.toLocaleDateString(i18next.language) ?? 'N/A';
};
