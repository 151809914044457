import { R1Fact, R1FactType } from './fact';

export type PlotReport = {
  plot_id: string;
  facts: R1Fact[];
};

export type FactsByFactType = Record<R1FactType, R1Fact>;

/**
 * GET /api/v1/dashboard/plot-reports/{project_id}
 */
export const GET_DASHBOARD_PLOT_REPORTS_PATH = 'dashboard/plot-reports/{project_id}';
export type GetDashboardPlotReportsRequestPathVariables = { project_id: string };

export type GetDashboardPlotReportsRequestQueryParams = undefined;
export type GetDashboardPlotReportsSuccessDto = {
  plot_reports: PlotReport[];
};

export type GetDashboardPlotReportsErrorDto = Error;

/**
 * GET /api/v1/dashboard/plot-report/{plot_id}
 */
export const GET_DASHBOARD_PLOT_REPORT_PATH = 'dashboard/plot-report/{plot_id}';
export type GetDashboardPlotReportRequestPathVariables = { plot_id: string };

export type GetDashboardPlotReportRequestQueryParams = undefined;
export type GetDashboardPlotReportSuccessDto = PlotReport;

export type GetDashboardPlotReportErrorDto = Error;

/**
 * GET /api/v1/dashboard/buyer/plot-reports/{project_id}/
 */
export const GET_BUYER_DASHBOARD_PLOT_REPORTS_PATH = 'dashboard/buyer/plot-reports/{project_id}/';
export type GetBuyerDashboardPlotReportsRequestPathVariables = { project_id: string };

export type GetBuyerDashboardPlotReportsRequestQueryParams = undefined;
export type GetBuyerDashboardPlotReportsSuccessDto = {
  plot_reports: PlotReport[];
};

export type GetBuyerDashboardPlotReportsErrorDto = Error;

/**
 * GET /api/v1/dashboard/buyer/plot-report/{plot_id}/
 */
export const GET_BUYER_DASHBOARD_PLOT_REPORT_PATH = 'dashboard/buyer/plot-report/{plot_id}/';
export type GetBuyerDashboardPlotReportRequestPathVariables = { plot_id: string };

export type GetBuyerDashboardPlotReportRequestQueryParams = undefined;
export type GetBuyerDashboardPlotReportSuccessDto = PlotReport;

export type GetBuyerDashboardPlotReportErrorDto = Error;
